import React from 'react';
import { Container } from 'react-bootstrap';

function SVTFooterForIncomingOrderCard(props) {
  const {
    partnerName,
    isAcceptBtnActive,
    orderAcceptHandler,
    dismissAutoAcceptedOrder,
    viewAutoAcceptedOrderDetails,
    getOrderHelpDropDownForIncomingOrderFooter,
  } = props;

  const listOfVenderSupportPartialAcceptance = [''];
  const orderAcceptAndViewHandler = () => {
    if (
      listOfVenderSupportPartialAcceptance.includes(partnerName.toLowerCase())
    ) {
      orderAcceptHandler();
    } else {
      viewAutoAcceptedOrderDetails();
    }
  };
  return (
    <Container className='footer-in-incoming-order-card'>
      <main
        className={
          listOfVenderSupportPartialAcceptance.includes(
            partnerName.toLowerCase()
          )
            ? 'd-flex flex-sm-row flex-lg-column flex-xl-row justify-content-sm-between justify-content-lg-center justify-content-xl-between'
            : 'd-flex justify-content-end'
        }
      >
        {listOfVenderSupportPartialAcceptance.includes(
          partnerName.toLowerCase()
        ) ? (
          <section className='mt-20 d-flex justify-content-lg-center '>
            {getOrderHelpDropDownForIncomingOrderFooter()}
          </section>
        ) : (
          <section className='mt-20'>
            <button
              className='success-border-btn me-3'
              onClick={dismissAutoAcceptedOrder}
            >
              Dismiss
            </button>
          </section>
        )}

        <section className='mt-20 d-flex justify-content-lg-center'>
          <button
            className={
              isAcceptBtnActive ? 'success-btn' : 'partially-accept-btn'
            }
            disabled={isAcceptBtnActive === true ? false : true}
            onClick={orderAcceptAndViewHandler}
            data-test='acceptViewButton'
          >
            {listOfVenderSupportPartialAcceptance.includes(
              partnerName.toLowerCase()
            )
              ? 'Accept'
              : 'View'}
          </button>
        </section>
      </main>
    </Container>
  );
}

export default SVTFooterForIncomingOrderCard;
