import React from 'react';
import DummyMenuItemImg from '../../images/DummyMenuItemImg.png';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { menuManagementAction } from '../../action/SVTMenuManagementAction';
import collapseArrow from '../../images/collapseArrow.svg';
import expandArrow from '../../images/expandArrow.svg';
import SVTModifiersItems from './SVTModifiersItems';
import Loading from '../../images/Loading.png';
import { orderManagementActions } from '../../action/SVTOrderManagementAction';
/**
 *
 * @param {string} status
 * @returns {string} Availability Text
 */
export const getAvailabilityText = (availability) => {
  switch (availability) {
    case true:
      return 'AVAILABLE';
    case false:
      return 'UNAVAILABLE';
    default:
      console.log(
        'An unexpected variable is passed in getAvailabilityText function'
      );
      return '';
  }
};
/**
 *
 * @param {any} value
 * @returns {Boolean} "testing my new env"
 */
export const isFalsyValue = (value) => {
  if (value == null) {
    return true;
  } else if (typeof value === 'string') {
    return value.trim() === '';
  }
  return false;
};
/**
 *
 * @param {object} item
 * @returns {Array<object>} item
 */
export const getModifiers = (item) => {
  if (item.product_type === 'Bundled Product') {
    if (
      item?.product_variants?.attributes?.BundledProductAttributes?.bundles
        ?.length > 0
    ) {
      return item?.product_variants?.attributes?.BundledProductAttributes
        ?.bundles;
    }
  }
  if (
    item?.product_variants?.attributes?.ComplexProductAttributes?.modifiers
      ?.length > 0
  ) {
    return item?.product_variants?.attributes?.ComplexProductAttributes
      ?.modifiers;
  }
  return undefined;
};
function SVTProductItems(props) {
  const {
    updateProductStatus,
    updateModifierStatus,
    setShowLoaderForProducts,
    currentProductList,
    showLoaderForProducts,
    setShowLoaderForModifiers,
    showLoaderForModifiers,
    setShowTopMsg,
    itemsListHavingError,
    setItemHavingErrorPopupState,
  } = props;
  const expendProductList = React.useRef([]);
  /**
   *
   * @param {string} productKey
   * @param {boolean} availabilityFlag
   */
  const availabilityHandler = (productKey, availabilityFlag, productName) => {
    if (Object.keys(showLoaderForProducts).includes(productKey)) {
      itemHavingErrorPopupShowHandler(productName);
      return;
    }
    setShowLoaderForProducts(productKey, true);
    const payload = {
      product_key: productKey,
      status: String(availabilityFlag),
    };
    const setCurrentActiveProductPayload = {
      productKey,
      productName,
      isProductAvailable: availabilityFlag,
      shouldShow: true,
    };
    updateProductStatus(payload, setCurrentActiveProductPayload);
  };
  const [productItemList, setProductList] = React.useState([]);
  /**
   * setting shouldShowModifiers and index of each product item
   */
  React.useEffect(() => {
    // setShowProductLoader(Object.keys(showLoaderForProducts??{}))
    if (currentProductList?.length >= 0) {
      const tmpProductList = [];
      for (let index = 0; index < currentProductList.length; index++) {
        const tmpItem = Object.assign({}, currentProductList[index]);
        tmpItem['itemIndex'] = index;
        if (tmpItem?.product_type !== 'Regular Product') {
          if (expendProductList.current.includes(index)) {
            tmpItem['shouldShowModifiers'] = true;
          } else {
            tmpItem['shouldShowModifiers'] = false;
          }
        }

        tmpProductList.push(Object.assign({}, tmpItem));
      }
      setProductList([...tmpProductList]);
    }
  }, [currentProductList, showLoaderForProducts, showLoaderForModifiers]);
  /**
   * This function is responsible for setting shouldShowModifiers flag in productItemList state
   * @param {number} itemIndex
   * @param {boolean} value
   */
  const shouldShowModifiersHandler = (itemIndex, value) => {
    const tmpItemList = Object.assign([], productItemList);
    if (
      tmpItemList[itemIndex] &&
      tmpItemList[itemIndex].shouldShowModifiers !== undefined
    ) {
      tmpItemList[itemIndex].shouldShowModifiers = value;
    }
    if (expendProductList.current.includes(itemIndex)) {
      expendProductList.current = expendProductList.current.filter(
        (i) => i !== itemIndex
      );
    }
    if (value) {
      expendProductList.current.push(itemIndex);
    }
    setProductList(tmpItemList);
  };

  const modifierAvailabilityHandler = (
    skuCode,
    availabilityFlag,
    productKey,
    modifierName
  ) => {
    if (Object.keys(showLoaderForModifiers ?? {}).includes(skuCode)) {
      itemHavingErrorPopupShowHandler(modifierName);
      return;
    }
    setShowLoaderForModifiers(skuCode, true);
    const payload = {
      sku_code: skuCode,
      status: String(availabilityFlag),
      product_key: productKey,
      modifierName: modifierName,
    };
    const headerMsgPayload = {
      show: true,
      type: 'modifierBgcUpdating',
      msg: '',
    };
    setShowTopMsg(headerMsgPayload);
    updateModifierStatus(payload);
  };
  const itemHavingErrorPopupShowHandler = (productName) => {
    const itemHavingErrorPopupStatePayload = {
      shouldShow: true,
      itemName: productName,
    };
    setItemHavingErrorPopupState(itemHavingErrorPopupStatePayload);
  };
  const getSliderClassNameForModifier = (skuCode) => {
    if (itemsListHavingError?.includes(skuCode))
      return 'slider round disable-grey';
    if (Object.keys(showLoaderForModifiers).includes(skuCode))
      return 'slider round disable-grey context-menu-cursor';
    return 'slider round';
  };
  const getSliderClassNameForProduct = (productId) => {
    if (itemsListHavingError?.includes(productId)) {
      return 'slider round disable-grey';
    }
    if (Object.keys(showLoaderForProducts).includes(productId)) {
      return 'slider round disable-grey context-menu-cursor';
    }
    return 'slider round';
  };
  return (
    <>
      {productItemList?.map((item) => {
        if (item?.isModifier) {
          return <SVTModifiersItems currentModifiersList={[item]} />;
        }
        const modifiers = getModifiers(item);
        return (
          <React.Fragment key={item.id}>
            <div
              className='dairy-container-wrapper'
              data-test='productItemsMainContainer'
            >
              <main className='dairy-container'>
                <section className='d-flex align-items-center flex-gap-20 ml-20'>
                  {item.product_type !== 'Regular Product' &&
                    (item.shouldShowModifiers ? (
                      <img
                        src={expandArrow}
                        alt='expandArrow'
                        width='23.88px'
                        height='8.67px'
                        className='cursor-pointer'
                        onClick={() =>
                          shouldShowModifiersHandler(item?.itemIndex, false)
                        }
                        data-test={`expend-modifier-arrow ${item.id}`}
                      />
                    ) : (
                      <img
                        src={collapseArrow}
                        alt='collapseArrow'
                        width='23.88px'
                        height='8.67px'
                        onClick={() =>
                          shouldShowModifiersHandler(item?.itemIndex, true)
                        }
                        className='cursor-pointer'
                        data-test={`collapse-modifier-arrow ${item.id}`}
                      />
                    ))}
                  <img
                    src={
                      isFalsyValue(
                        item?.product_variants?.images?.mainImage?.url
                      ) === true
                        ? DummyMenuItemImg
                        : item?.product_variants?.images?.mainImage?.url
                    }
                    alt='ItemImg'
                    width='80px'
                    height='80px'
                    className={
                      item.product_type === 'Regular Product' ? 'ml-40' : ''
                    }
                  />
                  <div className=''>
                    <div className='small_text gray_text text-uppercase letter-spacing-15 font-size-12'>
                      {/* {currentActiveTab.name} */}
                    </div>
                    <div className='text-capitalize'>{item.alter_name}</div>
                  </div>
                </section>
                <section className='right-part flex-gap-20'>
                  <div className='loader-available'>
                    {Object.keys(showLoaderForProducts).includes(
                      item.product_key
                    ) && (
                      <img class='rotating-loader' src={Loading} alt='loader' />
                    )}
                    <div
                      className={
                        itemsListHavingError?.includes(item.product_key)
                          ? 'd-flex flex-column align-items-center pt-2'
                          : 'd-flex flex-column align-items-center'
                      }
                    >
                      <div className='small_text gray_text text-uppercase letter-spacing-15 text-end font-size-12 w-100'>
                        {getAvailabilityText(item.availability)}
                      </div>
                      {itemsListHavingError?.includes(item.product_key) && (
                        <p className='m-0 p-0 small_text gray_text text-uppercase letter-spacing-15 text-end font-size-12 w-100'>
                          {'FAILED TO UPDATE'}
                        </p>
                      )}
                    </div>
                  </div>
                  <label className='switch'>
                    <input
                      type='checkbox'
                      id='selectID'
                      onChange={(e) =>
                        availabilityHandler(
                          item.product_key,
                          e.target.checked,
                          item.alter_name
                        )
                      }
                      checked={item.availability === true}
                      disabled={Object.keys(showLoaderForProducts).includes(
                        item.product_key
                      )}
                      data-test={`${item.id}`}
                    />
                    <span
                      className={getSliderClassNameForProduct(
                        item?.product_key
                      )}
                    ></span>
                  </label>
                </section>
              </main>
              <main className='mb-20'>
                {modifiers &&
                  modifiers.length > 0 &&
                  item.shouldShowModifiers &&
                  modifiers.map((modifier) => {
                    return (
                      <React.Fragment key={modifier?.productKey}>
                        {modifier.productOptions?.length > 0 &&
                          modifier.productOptions?.map((modifierItems, idx) => {
                            return (
                              <React.Fragment key={`modifierItem${idx}`}>
                                <div className='modifier-container'>
                                  <section className='modifier-name-text text-capitalize'>
                                    {modifierItems?.optionName}
                                  </section>
                                  {item?.product_type !== 'Bundled Product' &&
                                    !modifierItems?.hideOptionInST && (
                                      <section className='right-part flex-gap-20'>
                                        <div className='loader-available'>
                                          {Object.keys(
                                            showLoaderForModifiers
                                          ).includes(
                                            modifierItems?.optionProduct
                                              ?.productVariants[0].sku
                                          ) && (
                                            <img
                                              class='rotating-loader'
                                              src={Loading}
                                              alt='loader'
                                            />
                                          )}
                                          <div
                                            className={
                                              itemsListHavingError?.includes(
                                                modifierItems?.optionProduct
                                                  ?.productVariants[0].sku
                                              )
                                                ? 'pt-2'
                                                : ''
                                            }
                                          >
                                            <div className='small_text gray_text text-uppercase letter-spacing-15 text-end font-size-12'>
                                              {getAvailabilityText(
                                                modifierItems.availability
                                              )}
                                            </div>
                                            {itemsListHavingError?.includes(
                                              modifierItems?.optionProduct
                                                ?.productVariants[0].sku
                                            ) && (
                                              <p className='m-0 p-0 small_text gray_text text-uppercase letter-spacing-15 text-end font-size-12 w-100'>
                                                {'FAILED TO UPDATE'}
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                        <label
                                          className='switch'
                                          data-test='modifierAvailabilitySlider'
                                        >
                                          <input
                                            type='checkbox'
                                            id='selectID'
                                            onChange={(e) =>
                                              modifierAvailabilityHandler(
                                                modifierItems?.optionProduct
                                                  ?.productVariants[0].sku,
                                                e.target.checked,
                                                item.product_key,
                                                modifierItems?.optionName
                                              )
                                            }
                                            checked={
                                              modifierItems.availability ===
                                              true
                                            }
                                            data-test={`modifierAvailabilitySlider ${modifierItems?.optionProduct?.productVariants[0].sku}`}
                                          />
                                          <span
                                            className={getSliderClassNameForModifier(
                                              modifierItems?.optionProduct
                                                ?.productVariants[0].sku
                                            )}
                                            data-test='modifierAvailabilitySlider'
                                          ></span>
                                        </label>
                                      </section>
                                    )}
                                </div>
                                <div className='margin-left-184'>
                                  <div className='w-100 modifier-border'></div>
                                </div>
                              </React.Fragment>
                            );
                          })}
                      </React.Fragment>
                    );
                  })}
              </main>
            </div>
          </React.Fragment>
        );
      })}
    </>
  );
}

export function mapState(state) {
  const { menuManagementRelated } = state;
  const {
    showLoaderForProducts,
    showLoaderForModifiers,
    itemsListHavingError,
  } = menuManagementRelated;
  return {
    showLoaderForProducts,
    showLoaderForModifiers,
    itemsListHavingError,
  };
}
const actionCreators = {
  updateProductStatus: menuManagementAction.updateProductStatus,
  updateModifierStatus: menuManagementAction.updateModifierStatus,
  setCurrentActiveProduct: menuManagementAction.setCurrentActiveProduct,
  setShowLoaderForProducts: menuManagementAction.setShowLoaderForProducts,
  setShowLoaderForModifiers: menuManagementAction.setShowLoaderForModifiers,
  setShowTopMsg: orderManagementActions.setShowTopMsg,
  setItemHavingErrorPopupState:
    menuManagementAction.setItemHavingErrorPopupState,
};

export default compose(connect(mapState, actionCreators))(SVTProductItems);
