import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { orderManagementActions } from '../../action/SVTOrderManagementAction';
export const formatAMPM = date => {
	let hours = date.getHours();
	let minutes = date.getMinutes();
	const amOrPm = hours >= 12 ? 'PM' : 'AM';
	hours = hours % 12;
	hours = hours ? hours : 12;
	hours = hours < 10 ? '0' + hours : hours;
	minutes = minutes < 10 ? '0' + minutes : minutes;
	const strTime = hours + ':' + minutes + ' ' + amOrPm;
	return strTime;
};
function SVTJustCancelledDetailsComponent(props) {
	const { image, id, ordersData, setShowTopMsg } = props;
	const showOrderDetails = async order => {
		//for muting the sound
		if (props?.cancelledOrderNotifications) {
			const cancelledOrderNotifications = [...props?.cancelledOrderNotifications];
			if (cancelledOrderNotifications.length > 0) {
				cancelledOrderNotifications.pop();
				props.saveCancelledOrderNewNotification(cancelledOrderNotifications);
			}
		}
		const headerMsgPayload = {
			show: false,
			type: '',
			msg: '',
			orderId: '',
			partnerId:'',
		};
		setShowTopMsg(headerMsgPayload);
		props.justCancelledSelectedOrderDetails(order);
		props.storeOrderDetails(order);
		props.setShouldShowEditOrderViewInOrderDetailsPage(false);
		props.history.push('/order-details/Cancelled');
		const dismissCancelledOrder = await props.dismissLiveOrders(order.id);
		if (dismissCancelledOrder?.status === 'SUCCESS'){
			props.getJustCancelledOrderList();
		}
	};
	const dismissOrder = async order => {
		//for muting the sound
		if (props?.cancelledOrderNotifications) {
			const cancelledOrderNotifications = [...props?.cancelledOrderNotifications];
			if (cancelledOrderNotifications.length > 0) {
				cancelledOrderNotifications.pop();
				props.saveCancelledOrderNewNotification(cancelledOrderNotifications);
			}
		}
		const headerMsgPayload = {
			show: false,
			type: '',
			msg: '',
			orderId: '',
			partnerId:'',
		};
		setShowTopMsg(headerMsgPayload);
		const disMissOrders = await props.dismissLiveOrders(order.id);
		if (disMissOrders?.status === 'SUCCESS') {
			props.getOrderReportCount();
			props.getJustCancelledOrderList();
		}
	};
	// const amOrPm = (time = '') => {
	// 	const hour = parseInt(time?.substring(0, 2));
	// 	if (hour > 11) return 'PM';
	// 	return 'AM';
	// };
	return (
		<>
			<div className='Accordion_Header' data-test="justCancelledDetailsContainer">
				<div>
					<img src={image} alt='aggregator_logo' />
				</div>
				<div className='ms-12'>
					<div className='id_style'>
						#{ordersData?.partner_id?.toUpperCase()}
					</div>
					<div className='small_text gray_text mb-2'>
						<span>
							Cancelled at {formatAMPM(new Date(ordersData?.cancelled_at ?? '2022-02-18T00:56:01.446804'))} by&nbsp;
							{ordersData?.cancelled_by ? ordersData?.cancelled_by : 'Customer'}
						</span>
					</div>
					<div>
						<div className='items_count_style text-decoration-line-through'>
							{ordersData?.count?.total_items} items
						</div>
					</div>
				</div>
			</div>

			<div
				className={
					'Accordion_Footer justify-content-end dismiss-row-padding-top'
				}
			>
				<button
					type='button'
					className='success-border-btn dismiss-btn-just-cancelled-margin-right'
					onClick={() => dismissOrder(ordersData)}
					data-test="dismissOrder"
				>
					Dismiss
				</button>
				<button
					type='button'
					className='success-btn'
					onClick={() => showOrderDetails(ordersData)}
					data-test="showOrderDetails"
				>
					View
				</button>
			</div>
		</>
	);
}
export function mapState(state) {
	const { orderManagementRelated } = state;
	const { cancelledOrderNotifications } = orderManagementRelated;
	return { cancelledOrderNotifications };
}
const actionCreators = {
	justCancelledSelectedOrderDetails:
		orderManagementActions.justCancelledSelectedOrderDetails,
	dismissLiveOrders: orderManagementActions.dismissLiveOrders,
	getJustCancelledOrderList: orderManagementActions.getJustCancelledOrderList,
	saveCancelledOrderNewNotification:
		orderManagementActions.saveCancelledOrderNewNotification,
	getOrderReportCount: orderManagementActions.getOrderReportCount,
	setShowTopMsg: orderManagementActions.setShowTopMsg,
	storeOrderDetails: orderManagementActions.storeOrderDetails,
	setShouldShowEditOrderViewInOrderDetailsPage: orderManagementActions.setShouldShowEditOrderViewInOrderDetailsPage,
};

export default compose(
	withRouter,
	connect(mapState, actionCreators),
)(SVTJustCancelledDetailsComponent);
