/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { Container, Navbar, Nav, Row, Col } from 'react-bootstrap';
import bp_logo from '../../images/bp_logo.svg';
import bp_text from '../../images/bp_text.svg';
import online_indicator from '../../images/online_indicator.svg';
import settings_button from '../../images/settings_button.svg';
import notification_button from '../../images/notification_button.svg';
import SVTNotificationComponent from './SVTNotificationComponent';
import offline_indicator from '../../images/offline_indicator.svg';
import { orderManagementActions } from '../../action/SVTOrderManagementAction';
import { compose } from 'redux';
import { connect } from 'react-redux';
import risk_icon from '../../images/Risk.svg';
import { orderManagementServices } from '../../services/SVTOrderManagementService';
import { withRouter } from 'react-router-dom';
import close_icon from '../../images/green_outline_close.svg';
import info_icon from '../../images/info.svg';
import bpIconTickCircleBlack from '../../images/bp-icon-tick-circle-black.svg';
import aggregator_offline_indicator from '../../images/aggregator_offline_indicator.svg';
import complete_check from '../../images/complete_check.png';
import moveToHistory from '../../images/move-to-history.svg';
import stopwatchIcon from '../../images/Stopwatch.svg';
import OfflinePopupIcon from '../../images/bp-icon-globe-web-bp-orange.svg';

import StoreOfflineTime from '../../services/StoreOfflineTime';

const activeHeaderLink = 'active-main-header-link-live-order';

function SVTHeaderComponent(props) {
  const {
    storeDetails: { store_status: storeStatus = 'Online' } = {},
    showTopMsg: {
      type: barNotificationType = '',
      show: isBarNotificationActive,
    } = {},
  } = props;
  useEffect(() => {
    props.getOrderReportCount();
    props.getStoreDetails();
  }, []);
  useEffect(() => {
    if (storeStatus.toLowerCase() === 'offline') {
      if (isBarNotificationActive && barNotificationType !== 'store') {
        return;
      }
      const headerMsgPayload = {
        show: true,
        type: 'store',
        msg: 'All incoming orders to the store are manually paused.',
        orderId: '',
        partnerId: '',
      };
      props.setShowTopMsg(headerMsgPayload);
    } else if (storeStatus.toLowerCase() === 'online') {
      if (isBarNotificationActive && barNotificationType !== 'store') {
        return;
      }
      const headerMsgPayload = {
        show: false,
        type: '',
        msg: '',
        orderId: '',
        partnerId: '',
      };
      props.setShowTopMsg(headerMsgPayload);
    }
  }, [storeStatus]);
  const [showNotification, setShowNotification] = useState(false);
  const handleCloseNotification = () => setShowNotification(false);
  const handleShowNotification = () => {
    setShowNotification(true);
  };
  const handleSettingPage = () => {
    //	props.getStoreDetails();
  };

  const storeOfflineTimePlaceHolder = StoreOfflineTime(
    props.storeDetails?.updated_on
  );
  const location = useLocation();
  const allNavLinks = {
    liveOrder: false,
    orderHistory: false,
    help: false,
  };
  let activeNavLink = {
    liveOrder: false,
    orderHistory: false,
    help: false,
    menu: false,
  };
  switch (location.pathname) {
    case '/order-details/Incoming':
      activeNavLink = { ...allNavLinks, liveOrder: true };
      break;
    case '/order-list':
      activeNavLink = { ...allNavLinks, liveOrder: true };
      break;
    case '/order-history-details':
      activeNavLink = { ...allNavLinks, orderHistory: true };
      break;
    case '/help':
      activeNavLink = { ...allNavLinks, help: true };
      break;
    case '/menu':
      activeNavLink = { ...allNavLinks, menu: true };
      break;
    default:
      console.log('Proper name for nav links should pass to main header');
      break;
  }
  return (
    <>
      <Navbar className='py-0' expand='md' sticky='top'>
        <Container
          fluid
          className='header-shadow padding-header white_background'
        >
          <Link to='/' className='ms-2 me-3 py-1'>
            <img src={bp_logo} alt='BP Logo' />
            <img className='ms-1' src={bp_text} alt='BP Text' />
          </Link>
          <Navbar.Toggle aria-controls='responsive-navbar-nav' />
          <Navbar.Collapse id='responsive-navbar-nav'>
            <Nav className='me-auto'>
              <NavLink
                exact
                activeClassName='active_class'
                className={
                  'header_text ' +
                  (activeNavLink.liveOrder ? activeHeaderLink : '')
                }
                to='/'
              >
                Live Order
              </NavLink>
              <NavLink
                exact
                activeClassName='active_class'
                className={
                  'header_text ' +
                  (activeNavLink.orderHistory ? activeHeaderLink : '')
                }
                to='/order-history'
              >
                Order History
              </NavLink>
              <div className='d-flex'>
                <NavLink
                  exact
                  activeClassName='active_class'
                  className={
                    'header_text ' +
                    (activeNavLink.menu ? activeHeaderLink : '')
                  }
                  to='/menu'
                >
                  Menu
                </NavLink>
                {props?.totalUnavailableCount > 0 && (
                  <div className='menu_count_header ms-2 gray_background_color'>
                    {props?.totalUnavailableCount}
                  </div>
                )}
              </div>
              <NavLink
                exact
                activeClassName='active_class'
                className={
                  'header_text ' + (activeNavLink.help ? activeHeaderLink : '')
                }
                to='/order-help'
              >
                Help
              </NavLink>
            </Nav>
            <Nav>
              <Nav.Link className='my-auto'>
                <div className='d-flex'>
                  <div className=''>
                    <img
                      src={
                        props.storeDetails?.store_status === 'Offline'
                          ? offline_indicator
                          : props.storeDetails?.store_status ===
                            'Aggregator Offline'
                          ? aggregator_offline_indicator
                          : online_indicator
                      }
                      alt='online'
                      className='pt-1'
                    />
                    <img
                      src={OfflinePopupIcon}
                      alt='offline'
                      width='0px'
                      height='0px'
                    />
                  </div>
                  <div className='ms-1 header_text' data-test='storeStatusText'>
                    {props.storeDetails?.store_status === 'Offline'
                      ? 'Offline'
                      : props.storeDetails?.store_status ===
                        'Aggregator Offline'
                      ? 'Aggregator(s) Offline'
                      : 'Online'}
                  </div>

                  {props.storeDetails?.store_status === 'Offline'
                    ? storeOfflineTimePlaceHolder
                    : ''}
                </div>
              </Nav.Link>

              <Nav.Link>
                <NavLink to='/order-setting'>
                  <img
                    src={settings_button}
                    alt='settings'
                    className='header-svg-border-radius'
                    onClick={() => handleSettingPage()}
                  />
                </NavLink>
              </Nav.Link>
              <Nav.Link className='position-relative'>
                <img
                  src={notification_button}
                  className='header-svg-border-radius'
                  alt='notification'
                  onClick={handleShowNotification}
                />
                {props.totalNotificationCount > 0 ? (
                  <div className='notification_style notification_position green_background-color'>
                    {props.totalNotificationCount}
                  </div>
                ) : (
                  ''
                )}
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
          <SVTNotificationComponent
            show={showNotification}
            onHide={handleCloseNotification}
          />
        </Container>
      </Navbar>
      {props?.showTopMsg?.show === true && (
        <div className='overlay-top-popup'>
          <div className='overlay-box-top-popup'>
            <div
              className={
                props?.showTopMsg?.type === 'store'
                  ? 'setting-store-notification'
                  : props?.showTopMsg?.type !== 'Cancelled'
                  ? 'accept-store-notification-header'
                  : 'cancel-store-notification-header'
              }
            >
              <OfflineStoreNotification {...props} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export function mapState(state) {
  const { orderManagementRelated, menuManagementRelated } = state;
  const { showTopMsg, storeDetails, totalNotificationCount, currentPath } =
    orderManagementRelated;
  const { totalUnavailableCount } = menuManagementRelated;
  return {
    showTopMsg,
    storeDetails,
    totalNotificationCount,
    currentPath,
    totalUnavailableCount,
  };
}
const actionCreators = {
  getOrderReportCount: orderManagementActions.getOrderReportCount,
  getStoreDetails: orderManagementActions.getStoreDetails,
  storeOrderDetails: orderManagementActions.storeOrderDetails,
  setShowTopMsg: orderManagementActions.setShowTopMsg,
  getAllNotificationsList: orderManagementActions.getAllNotificationsList,
  setShouldShowEditOrderViewInOrderDetailsPage:
    orderManagementActions.setShouldShowEditOrderViewInOrderDetailsPage,
};

export default compose(
  withRouter,
  connect(mapState, actionCreators)
)(SVTHeaderComponent);

export function OfflineStoreNotification(props) {
  const {
    currentPath,
    storeDetails: { store_status: storeStatus = 'Online' } = {},
  } = props;
  let {
    type = '',
    orderId = '',
    partnerId,
    isProductAvailable,
    msg = '',
    isModifier = false,
    numberOfProductsHavingThisModifier = 1,
  } = props.showTopMsg;
  if (partnerId === null || partnerId === undefined) {
    partnerId = '';
  }
  const viewOrder = async () => {
    if (type !== 'store') {
      if (
        (type === 'assigningdriver' ||
          type === 'driverarrived' ||
          type === 'Cancelled' ||
          type === 'Move To Order History' ||
          type === 'Completed') &&
        orderId !== ''
      ) {
        const OrderDetails = await orderManagementServices.getSingleItemDetails(
          `id=${orderId}`
        );
        props.storeOrderDetails(OrderDetails['data'][0]);
        props.setShouldShowEditOrderViewInOrderDetailsPage(false);
      }
      closeNotification();
      if (orderId === '') {
        return;
      }
      if (
        type === 'Incoming' ||
        type === 'assigningdriver' ||
        type === 'driverarrived'
      ) {
        props.history.push('/order-details/Incoming');
      } else if (type === 'Cancelled') {
        props.history.push('/order-details/Cancelled');
      }
    } else {
      props.history.push('/order-setting');
    }
  };

  function closeNotification() {
    let headerMsgPayload;
    if (storeStatus.toLowerCase() === 'offline') {
      headerMsgPayload = {
        show: true,
        type: 'store',
        msg: 'All incoming orders to the store are manually paused.',
        orderId: '',
      };
    } else {
      headerMsgPayload = {
        show: false,
        type: '',
        msg: ``,
        orderId: '',
        partnerId: '',
      };
    }
    props?.setShowTopMsg(headerMsgPayload);
  }
  return (
    <>
      <Container fluid>
        <Row>
          <Col md={10} className='px-4 cursor-pointer' onClick={viewOrder}>
            <div className='d-flex'>
              {type !== 'menu' && type !== 'modifierBgcUpdating' && (
                <img
                  src={
                    (type.toLowerCase() === 'cancelled' && risk_icon) ||
                    (type === 'store' && risk_icon) ||
                    (type.toLowerCase().includes('completed') &&
                      complete_check) ||
                    (type.toLowerCase() === 'move to order history' &&
                      moveToHistory) ||
                    info_icon
                  }
                  alt='info icon'
                />
              )}
              {type === 'menu' && (
                <img
                  src={isProductAvailable ? bpIconTickCircleBlack : info_icon}
                  alt='info icon'
                />
              )}
              {type === 'modifierBgcUpdating' && (
                <img src={stopwatchIcon} alt='icon' />
              )}
              {type === 'Cancelled' && (
                <span className='ps-2'>
                  Order
                  <strong> #{partnerId + ' '}</strong>
                  has been cancelled.
                </span>
              )}
              {type === 'Completed' && (
                <span className='ps-2'>
                  Order
                  <strong> #{partnerId + ' '}</strong>
                  has been marked as <strong>completed.</strong>
                </span>
              )}
              {type === 'multipleCompleted' && (
                <span className='ps-2'>
                  Multiple orders have been marked as&nbsp;
                  <strong>completed.</strong>
                </span>
              )}
              {type === 'Move To Order History' && (
                <span className='ps-2'>
                  Order
                  <strong> #{partnerId + ' '}</strong>
                  has been moved to Order History.
                </span>
              )}
              {type === 'Incoming' && (
                <span className='ps-2'>
                  Order <strong> #{partnerId + ' '}</strong>
                  has been accepted.
                </span>
              )}

              {type === 'assigningdriver' && (
                <span className='ps-2'>
                  Assigning driver to order <strong>#{partnerId}</strong>.
                </span>
              )}
              {type === 'driverarrived' && (
                <span className='ps-2'>
                  A driver has <strong>arrived</strong> to pick up order{' '}
                  <strong>#{partnerId}</strong>.
                </span>
              )}
              {type === 'partialAcceptedOrderAfterAccepted' && (
                <span className='ps-2'>
                  Your changes made to order <strong>#{partnerId}</strong> have
                  been updated.
                </span>
              )}
              {type === 'store' && (
                <span className='ps-2'>
                  All incoming orders to the store are manually paused.
                </span>
              )}
              {type === 'menu' && isProductAvailable === true && (
                <span className='ps-2'>
                  <strong>{msg}</strong> marked as
                  <strong> available</strong>
                  {isModifier && (
                    <span>
                      &nbsp;for{' '}
                      <strong>{numberOfProductsHavingThisModifier}</strong>{' '}
                      {numberOfProductsHavingThisModifier > 1
                        ? 'occurrences.'
                        : 'occurrence(s).'}
                    </span>
                  )}
                </span>
              )}
              {type === 'menu' && isProductAvailable === false && (
                <span className='ps-2'>
                  <strong>{msg}</strong> marked as
                  <strong> unavailable</strong>
                  {isModifier && (
                    <span>
                      &nbsp;for{' '}
                      <strong>{numberOfProductsHavingThisModifier}</strong>{' '}
                      {numberOfProductsHavingThisModifier > 1
                        ? 'occurrences.'
                        : 'occurrence(s).'}
                    </span>
                  )}
                </span>
              )}
              {type === 'modifierBgcUpdating' && (
                <span className='ps-2'>
                  Looks like It’s taking some time to update the item
                  availability, we’ll notify you when it's completed.
                </span>
              )}
              {(type === 'store' || (type !== 'menu' && orderId !== '')) && (
                <div
                  className={
                    currentPath === '/order-setting'
                      ? 'd-none'
                      : 'cursor-pointer green-link mx-2'
                  }
                  data-test='tapViewText'
                  onClick={viewOrder}
                >
                  Tap to view
                </div>
              )}
            </div>
          </Col>
          <Col md={2}>
            {type !== 'store' && (
              <Row>
                <Col md={9}></Col>
                <Col md={3}>
                  <img
                    src={close_icon}
                    alt='close icon'
                    onClick={closeNotification}
                    className='cursor-pointer'
                  />
                </Col>
              </Row>
            )}
            {type === 'store' && (
              <Row>
                <Col md={5}> </Col>
                <Col md={7}></Col>
              </Row>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}
