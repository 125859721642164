import React, { useState } from 'react';
import search from '../../images/search.svg';
import arrow_up from '../../images/arrow_up.svg';
import arrow_down from '../../images/arrow_down.svg';
import Xcross from '../../images/cross_mark.svg';
import { Col, Modal, Row, Dropdown } from 'react-bootstrap';
import SVTHeaderComponent from './SVTHeaderComponent';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { orderManagementActions } from '../../action/SVTOrderManagementAction';
import cross_icon from '../../images/cross_icon.svg';
import loading_svg from '../../images/loading_svg.svg';
import Risk_ticket from '../../images/Risk_ticket.svg';
import ticket_logged from '../../images/ticket_logged.svg';
import { data } from '../../helper/helpSectionData';

const allCategories = [
  'Order Support',
  'Managing Orders',
  'Technical Issues',
  'Support Summary',
];

const dataHeader = data.map((item) => item.header.toLowerCase());
var arrOfData = [];
function findData(q) {
  const arrOfData = [];
  for (let i = 0; i < dataHeader.length; i++) {
    if (dataHeader[i].includes(q.toLowerCase())) {
      data[i].show = false;
      arrOfData.push(data[i]);
    }
  }
  return arrOfData;
}
const BorderBelow = () => {
  return <div className='border-below-accordion'></div>;
};

const AccordionView = ({ props }) => {
  const [show, setShow] = useState(props.show);
  return (
    <>
      <div
        className='help-header-text cursor-pointer'
        onClick={() => setShow(!show)}
      >
        <img
          src={show ? arrow_up : arrow_down}
          alt='arUp'
          type='button'
          onClick={() => setShow(!show)}
          className='accordion-view-padding-right-10'
        ></img>
        {props.header}
      </div>
      {show && (
        <div className='help-para-margin'>
          <span>{props.data}</span>
        </div>
      )}
      <BorderBelow />
    </>
  );
};

const GetHelp = (results) => {
  return (
    results.results?.length > 0 && (
      <>
        {results.results.map((item) => (
          <>
            <div className='pos-top' key={item.id + item.show}>
              <AccordionView props={item} />
            </div>
          </>
        ))}
      </>
    )
  );
};

class SVTOrderHelpComponent extends React.Component {
  state = {
    query: '',
    searchedQuery: '',
    results: [],
    showScroll: false,
    showCross: false,
    showResultsFound: false,
    // showDataOff:"Ticket",
    showDataOff: allCategories[0],
  };
  componentDidMount() {
    this.props.setCurrentPath(this.props.location.pathname);
  }
  getResults = () => {
    this.setState({
      results: arrOfData,
    });
  };

  onSearchClick = () => {
    document.getElementById('searchQuery').innerHTML = '';
    this.setState({
      showCross: false,
      showScroll: false,
      showResultsFound: false,
      query: '',
      searchedQuery: '',
      showDataOff: allCategories[0],
    });
  };

  handleChange = (event) => {
    const querydata = event.target.value;
    if (querydata.length === 0) {
      this.onSearchClick();
    } else if (querydata.length < 3) {
      this.setState({
        query: querydata,
        showCross: true,
        showScroll: false,
      });
    } else {
      this.setState({
        query: querydata,
        searchedQuery: querydata,
        results: findData(querydata),
        showScroll: true,
        showCross: true,
      });
    }
  };
  handleKeyPress = (event) => {
    const keyPressed = event.key;
    if (keyPressed == 'Enter') {
      this.setState({
        showScroll: false,
        showResultsFound: true,
        showDataOff: '',
      });
    }
  };

  showThisAccordion = (r) => {
    this.setState({
      results: [r],
      showScroll: false,
      query: r.header,
      showResultsFound: true,
      searchedQuery: '',
      showDataOff: '',
    });
  };

  getShowData = () => {
    const fdata = data.filter(
      (item) => item.category == this.state.showDataOff
    );
    if (fdata.length > 0) {
      fdata[0].show = true;
      return fdata;
    }
  };
  FilterDataBasedOnCategory = (category) => {
    const ftrdata = data.filter((item) => item.category == category);
    this.setState({
      query: '',
      showCross: false,
      results: ftrdata,
      showScroll: false,
      showResultsFound: false,
      showDataOff: category,
    });
  };

  Suggestion = () => {
    const trimmedresults = [];
    let fresults = [];
    if (this.state.results.length > 0) {
      const queryL = this.state.query.length;
      for (let i = 0; i < this.state.results.length; i++) {
        const strt = this.state.results[i].header;
        const strtS = strt.toLowerCase();
        const idxOfQ = strtS.indexOf(this.state.query.toLowerCase());
        trimmedresults.push({
          id: this.state.results[i].id,
          header: this.state.results[i].header,
          header1: strt.slice(0, idxOfQ),
          header2: strt.slice(idxOfQ, idxOfQ + queryL),
          header3: strt.slice(idxOfQ + queryL, strt.length),
          data: this.state.results[i].data,
          show: false,
        });
      }
    }
    if (trimmedresults.length > 3) {
      fresults = trimmedresults.slice(0, 3);
    } else {
      fresults = trimmedresults;
    }
    const options = fresults.map((r, id) => {
      return r.header2 ? (
        <li
          key={r.id}
          className={
            id == fresults.length - 1
              ? 'search-li-text-no-border'
              : 'search-li-text'
          }
          onClick={() => this.showThisAccordion({ ...r, show: true })}
        >
          {r.header1}
          <b>{r.header2}</b>
          {r.header3}
        </li>
      ) : (
        <li
          key={r.id}
          className='search-li-text'
          onClick={() => this.showThisAccordion({ ...r, show: true })}
        >
          {r.header}
        </li>
      );
    });
    return <ul className='text-start help-list '>{options}</ul>;
  };

  render() {
    return (
      <>
        <SVTHeaderComponent />
        <div className='container-fluid padding-left-right-50'>
          <div className=' help-shadow font-help'>
            <div className=' text-center text-help-section  padding-bottom-30PX'>
              Help
            </div>
            <Row>
              <Col md={3} sm={2} lg={3} xl={3}></Col>
              <Col md={6} sm={6} lg={6} xl={6}>
                <div
                  className={
                    this.state.showScroll && this.state.query.length > 0
                      ? 'text-center help-input-box-shadow'
                      : 'text-center help-input-shadow '
                  }
                >
                  <div
                    className={
                      this.state.query.length > 0 &&
                      this.state.showScroll &&
                      this.state.results.length > 0
                        ? 'input-group search-bar-bottom-border input-group-help'
                        : 'input-group input-group-help'
                    }
                  >
                    <input
                      type='text'
                      autoComplete='off'
                      className='form-control input-help'
                      placeholder='Search help articles'
                      name='searchQuery'
                      id='searchQuery'
                      data-testid='searchQuery'
                      onChange={this.handleChange.bind(this)}
                      onKeyPress={this.handleKeyPress.bind(this)}
                      value={this.state.query ? this.state.query : ''}
                    />
                    <a
                      className='d-flex align-self-center help-image-margin-right'
                      type='button'
                      id='button-addon2'
                    >
                      <img
                        src={this.state.showCross ? Xcross : search}
                        alt='cross'
                        onClick={() => this.onSearchClick()}
                      ></img>
                    </a>
                  </div>
                  {this.state.results &&
                    this.state.query.length > 2 &&
                    this.state.showScroll &&
                    this.Suggestion()}
                </div>
              </Col>
            </Row>
            <div className='button-scrolling'>
              <div className='pt-5 pb-3 button-array'>
                {/* <button 
              className={this.state.showDataOff=="Ticket"?"category-buttons category-buttons-focus ":"category-buttons "}//"category-buttons" 
              type="button" 
              autoFocus="true"
              onClick={()=>this.setState({
                showDataOff:'Ticket',
				        query: ""
              })}
              >
                <span style={{marginRight:"5px"}}>
                  <img src={ticket} alt="ticket"/>
                </span>
                Create a ticket
              </button> */}
                {allCategories.map((category) => {
                  return (
                    <button
                      id='btn-1'
                      className={
                        this.state.showDataOff == category
                          ? 'category-buttons category-buttons-focus '
                          : 'category-buttons '
                      } //"category-buttons"
                      type='button'
                      autoFocus=''
                      onClick={() => this.FilterDataBasedOnCategory(category)}
                    >
                      {category}
                    </button>
                  );
                })}
              </div>
            </div>
            {
              // this.state.showDataOff=='Ticket'?
              //   <div className="help-search-result-text pos-div">
              //     <ShowTicketpage/>
              //   </div>
              //   :
              this.state.query.length > 0 ? (
                <>
                  {this.state.showResultsFound ? (
                    <>
                      {this.state.results.length > 0 ? (
                        <>
                          <div className='help-search-result-text pos-div'>
                            {this.state.searchedQuery.length > 0 && (
                              <>
                                Showing search results for "
                                {this.state.searchedQuery}" ...
                              </>
                            )}
                            <GetHelp results={this.state.results} />
                          </div>
                        </>
                      ) : (
                        <div className='help-search-result-text pos-div'>
                          No results found, try searching something different.
                          <BorderBelow />
                          {/* <ShowTicketpage from_show={true}/>
                  <BorderBelow/> */}
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <div className='help-search-result-text'>
                        <GetHelp
                          results={data.filter(
                            (item) => item.category == this.state.showDataOff
                          )}
                        />
                      </div>
                    </>
                  )}
                </>
              ) : (
                <div className='pos-div'>
                  <GetHelp results={this.getShowData()} />
                </div>
              )
            }
          </div>
        </div>
      </>
    );
  }
}

function mapState(state) {
  const { orderManagementRelated } = state;

  return { orderManagementRelated };
}
const actionCreators = {
  setCurrentPath: orderManagementActions.setCurrentPath,
};

const dropDownText = 'Ticket Category';
const padding1 = '20px 20px 0px 20px';
const padding2 = '0px 20px 0px 20px';
const padding3 = '15px 20px 20px 20px';
export class ShowTicketpage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: this.props.from_show ? false : true,
      dropDowntext: dropDownText,
      textAreatext: '',
      showTicketLogged: false,
      showTicketError: false,
      showTicketLogging: false,
    };
  }
  changeDropDownText(s) {
    this.setState({
      dropDowntext: s,
    });
  }
  handleChangeText = () => {
    const queryText = document.getElementById(
      'exampleFormControlTextarea11'
    ).value;
    this.setState({
      textAreatext: queryText,
    });
  };

  ticketGenerate = () => {
    this.setState({
      showTicketLogging: true,
      textAreatext: '',
      dropDowntext: dropDownText,
    });
  };

  render() {
    return (
      <>
        <div
          className='help-header-text cursor-pointer'
          onClick={() =>
            this.setState({
              show: !this.state.show,
            })
          }
        >
          <img
            src={this.state.show ? arrow_up : arrow_down}
            alt='arUp'
            type='button'
            onClick={() => this.setState({ show: false })}
            className='accordion-view-padding-right-10'
          ></img>
          Create a support ticket
        </div>
        {this.state.show && (
          <div className='help-para-margin'>
            <div className='help-ticket-help-text'>
              If you are unable to find a solution to your query, you can raise
              a support ticket by filling out the form below.
            </div>
            <div className='help-ticket-category-text'>TICKET CATEGORY</div>

            <Dropdown drop='up' className='help-dropdown'>
              <Dropdown.Toggle
                id='dropdown-basic-help'
                className='success-border-btn px-3 shadow-none help-category-dropdown help-dropdown-menu-text'
              >
                {this.state.dropDowntext}
              </Dropdown.Toggle>
              <Dropdown.Menu
                className='help-dropdown-menu '
                align='start'
                style={{ height: '200px', overflowY: 'scroll' }}
              >
                <Dropdown.Item
                  className='order-help-dropdown-menu-text ps-0 pt-2 pd-2'
                  disabled={false}
                  onClick={() => this.changeDropDownText('Orders not coming')}
                >
                  <div className='help-dropdown-header-text'>
                    Orders not coming
                  </div>
                  <div className='help-dropdown-content-text'>
                    Tablets not recieved any order
                  </div>
                </Dropdown.Item>
                <Dropdown.Divider />

                <Dropdown.Item
                  className='order-help-dropdown-menu-text ps-0 pt-2 pd-2'
                  disabled={false}
                  onClick={() =>
                    this.changeDropDownText('Incorrect Information displayed')
                  }
                >
                  <div className='help-dropdown-header-text'>
                    Incorrect Information displayed
                  </div>
                  <div className='help-dropdown-content-text'>
                    Order related information is incorrect
                  </div>
                </Dropdown.Item>
                <Dropdown.Divider />

                <Dropdown.Item
                  className='order-help-dropdown-menu-text ps-0 pt-2 pd-2'
                  disabled={false}
                  onClick={() => this.changeDropDownText('Order Acceptence')}
                >
                  <div className='help-dropdown-header-text'>
                    Order Acceptence
                  </div>
                  <div className='help-dropdown-content-text'>
                    Problems while accepting orders
                  </div>
                </Dropdown.Item>
                <Dropdown.Divider />

                <Dropdown.Item
                  className='order-help-dropdown-menu-text ps-0 pt-2 pd-2'
                  disabled={false}
                  onClick={() => this.changeDropDownText('Driver Updates')}
                >
                  <div className='help-dropdown-header-text'>
                    Driver Updates
                  </div>
                  <div className='help-dropdown-content-text'>
                    Problems with driver information and updates
                  </div>
                </Dropdown.Item>
                <Dropdown.Divider />
              </Dropdown.Menu>
            </Dropdown>

            <div className='help-ticket-description-text'>Description</div>

            <Row>
              <Col md={8}>
                <textarea
                  class='help-ticket-textarea'
                  id='exampleFormControlTextarea11'
                  rows='5'
                  placeholder='Enter description'
                  value={this.state.textAreatext}
                  onChange={() =>
                    this.handleChangeText() && console.log(this.state)
                  }
                ></textarea>
              </Col>
            </Row>

            <div className='help-ticket-submit-button'>
              <button
                onClick={() => this.ticketGenerate()}
                disabled={
                  this.state.dropDowntext !== dropDownText &&
                  this.state.textAreatext != ''
                    ? false
                    : true
                }
                className={
                  this.state.dropDowntext !== dropDownText &&
                  this.state.textAreatext != ''
                    ? 'shadow-none help-ticket-submit-button-success'
                    : ' shadow-none help-ticket-submit-button-muted'
                }
              >
                <span className='help-ticket-submit-button-text'>Submit</span>
              </button>
            </div>
          </div>
        )}

        {/* Modal for submitting ticket */}

        {/* Logging Ticket - submitting */}

        {this.state.showTicketLogging && (
          <Modal
            show={this.state.showTicketLogging}
            size='md'
            aria-labelledby='contained-modal-title-vcenter'
            centered
          >
            <Modal.Header style={{ padding: padding1 }}>
              <Modal.Title id='contained-modal-title-vcenter'>
                <div className='d-flex'>
                  <p className='m-0 px-0 normal-font fw-bold '>
                    Ticket creation
                  </p>
                </div>
              </Modal.Title>
              <img
                className='cursor-pointer'
                src={cross_icon}
                height='21.98px'
                width='21.98px'
                onClick={() =>
                  this.setState({
                    showTicketLogging: false,
                  })
                }
                alt='cross icon'
              />
            </Modal.Header>
            <Modal.Body className='m-0' style={{ padding: padding2 }}>
              <hr className='m-0 contact-supp-hr-setting-others' />
              <Row className='help-ticket-loading-svg'>
                <img src={loading_svg} alt='loading' className='spin' />
              </Row>
              <Row className='help-ticket-loading-svg-text'>
                <div className='text-center help-ticket-submitting-request'>
                  Submitting request...
                </div>
                <div className='text-center help-ticket-please-wait'>
                  Please wait, while we submit the ticket.
                </div>
              </Row>
            </Modal.Body>
          </Modal>
        )}

        {/* Logging ticket - Error */}

        {this.state.showTicketError && (
          <Modal
            show={this.state.showTicketError}
            size='md'
            aria-labelledby='contained-modal-title-vcenter'
            centered
          >
            <Modal.Header style={{ padding: padding1 }}>
              <Modal.Title id='contained-modal-title-vcenter'>
                <div className='d-flex'>
                  <p className='m-0 px-0 normal-font fw-bold '>
                    Ticket creation
                  </p>
                </div>
              </Modal.Title>
              <img
                className='cursor-pointer'
                src={cross_icon}
                height='21.98px'
                width='21.98px'
                onClick={() =>
                  this.setState({
                    showTicketError: false,
                  })
                }
                alt='cross icon'
              />
            </Modal.Header>
            <Modal.Body className='m-0' style={{ padding: padding2 }}>
              <hr className='m-0 contact-supp-hr-setting-others' />
              <Row className='help-ticket-error-svg'>
                <img src={Risk_ticket} alt='error' width='72px' height='64px' />
              </Row>
              <Row className='help-ticket-error-svg-text'>
                <div className='text-center help-ticket-submitting-request'>
                  Unable to create a ticket
                </div>
                <div className='text-start help-ticket-please-wait-error'>
                  Sorry! An error was encountered while creating the ticket.
                  <br />
                  Please retry or try contacting the support team
                </div>
              </Row>
            </Modal.Body>

            <Modal.Footer style={{ padding: padding3 }}>
              <button
                type='button'
                className='help-ticket-close-btn shadow-none help-ticket-btn-close-text'
                onClick={() =>
                  this.setState({
                    showTicketLogging: false,
                    showTicketError: false,
                    showTicketLogged: false,
                  })
                }
              >
                Close
              </button>
              <button
                type='button'
                onClick={() =>
                  this.setState({
                    showTicketError: false,
                    showTicketLogged: true,
                  })
                }
                className='help-ticket-retry-btn shadow-none help-ticket-btn-retry-text'
              >
                Retry
              </button>
            </Modal.Footer>
          </Modal>
        )}

        {/* Logging ticket - success */}

        {this.state.showTicketLogged && (
          <Modal
            show={this.state.showTicketLogged}
            size='md'
            aria-labelledby='contained-modal-title-vcenter'
            centered
          >
            <Modal.Header style={{ padding: padding1 }}>
              <Modal.Title id='contained-modal-title-vcenter'>
                <div className='d-flex'>
                  <p className='m-0 px-0 normal-font fw-bold '>
                    Ticket creation
                  </p>
                </div>
              </Modal.Title>
              <img
                className='cursor-pointer'
                src={cross_icon}
                height='21.98px'
                width='21.98px'
                onClick={() =>
                  this.setState({
                    showTicketLogged: false,
                  })
                }
                alt='cross icon'
              />
            </Modal.Header>
            <Modal.Body className='m-0' style={{ padding: padding2 }}>
              <hr className='m-0 contact-supp-hr-setting-others' />
              <Row className='help-ticket-logged-svg'>
                <img
                  src={ticket_logged}
                  alt='logged'
                  width='72px'
                  height='64px'
                />
              </Row>
              <Row className='help-ticket-error-svg-text'>
                <div className='text-center help-ticket-submitting-request'>
                  Ticket successfully created
                </div>
                <div className='text-start help-ticket-please-wait-error'>
                  <span className='help-ticket-your-reference'>
                    Your reference number is
                  </span>
                  <br />
                  <span className='help-ticket-ref-number'>
                    67TYGFBNMJKIO87
                  </span>
                </div>

                <div className='help-ticket-ack-text'>
                  An acknowledgement mail has been sent to ****abc@bp.com.
                </div>
              </Row>
            </Modal.Body>

            <Modal.Footer style={{ padding: padding3 }}>
              <button
                type='button'
                className='help-ticket-retry-btn shadow-none help-ticket-btn-retry-text'
                onClick={() =>
                  this.setState({
                    showTicketLogging: false,
                    showTicketError: false,
                    showTicketLogged: false,
                  })
                }
              >
                Close
              </button>
            </Modal.Footer>
          </Modal>
        )}
      </>
    );
  }
}

export default compose(
  withRouter,
  connect(mapState, actionCreators)
)(SVTOrderHelpComponent);
