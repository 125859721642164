import React from 'react';
import { Row, Col } from 'react-bootstrap';
import circlePlus from '../../images/circleplus.svg';
import circlePlusGrey from '../../images/circlePlusGrey.svg';
import circleMinusGreen from '../../images/circleMinusGreen.svg';
import circleMinus from '../../images/circleMinus.svg';
import CheckBox from '../../images/CheckBoxFaint.svg';
import emptyCheckBox from '../../images/emptyCheckBoxFaint.svg';

function SVTProductViewForEditOrder(props) {
  const { productItem, modifyOrderItemHandler, index, isMaximumLimitReached } =
    props;
  return (
    <>
      <Row>
        <Col
          sm={1}
          lg={2}
          xxl={1}
          className='d-flex align-items-center cursor-pointer'
        >
          {productItem?.quantity > 0 ? (
            <img
              src={CheckBox}
              alt='checkbox'
              className='cursor-pointer'
              onClick={() =>
                modifyOrderItemHandler('SET_QUANTITY_TO_ZERO', index)
              }
            />
          ) : (
            <img
              src={emptyCheckBox}
              alt='emptyCheckBox'
              className='cursor-pointer'
              onClick={() =>
                modifyOrderItemHandler('SET_QUANTITY_TO_MAXIMUM', index)
              }
            />
          )}
        </Col>
        <Col sm={11} lg={10} xxl={11} className='word-break'>
          <main
            className={
              productItem?.quantity === 0
                ? 'px-0 edit-order-line-through' //pt-3'
                : 'px-0' // pt-3'
            }
          >
            <section className='font-size-12 letter-spacing-15 text-uppercase dark-shade-gray word-break'>
              {productItem?.catetory}
            </section>
            <section className='word-break line-height-24 letter-spacing-O50'>
              {productItem?.name}
            </section>
          </main>
        </Col>
      </Row>
      <Row className='mt-2 mb-3'>
        <Col
          sm={1}
          lg={1}
          xxl={1}
          className='d-lg-none d-sm-block d-md-block d-xl-none d-xxl-block'
        ></Col>
        <Col sm={9} lg={9} xxl={9}>
          <main className='d-flex align-items-center flex-gap-10'>
            <section>
              {productItem?.quantity > 0 ? (
                <img
                  src={circleMinusGreen}
                  alt='reduce'
                  width={'24px'}
                  height={'24px'}
                  onClick={() => modifyOrderItemHandler('DEC_QUANTITY', index)}
                  className='cursor-pointer'
                />
              ) : (
                <img
                  src={circleMinus}
                  alt='disable'
                  width={'24px'}
                  height={'24px'}
                />
              )}
            </section>

            <section
              sm={3}
              className={
                productItem?.quantity === 0
                  ? 'text-decoration-line-through edit-order-view-quantity-for-incoming-order-card'
                  : 'edit-order-view-quantity-for-incoming-order-card'
              }
            >
              x{productItem?.quantity}
            </section>
            <section>
              {!isMaximumLimitReached(index, productItem?.quantity) ? (
                <img
                  src={circlePlus}
                  alt='increase'
                  width={'24px'}
                  height={'24px'}
                  onClick={() => modifyOrderItemHandler('INC_QUANTITY', index)}
                  className='cursor-pointer'
                />
              ) : (
                <img
                  src={circlePlusGrey}
                  alt='disable'
                  width={'24px'}
                  height={'24px'}
                />
              )}
            </section>
          </main>
        </Col>
        <Col
          sm={2}
          lg={3}
          xxl={2}
          className='d-flex flex-row-reverse font-size-14 align-items-center'
        >
          <section
            className={
              productItem?.quantity === 0 ? 'text-decoration-line-through' : ''
            }
          >
            $
            {(productItem?.unit_price
              ? productItem?.unit_price / 100
              : productItem?.price / productItem?.quantity / 100
            ).toFixed(2)}
          </section>
        </Col>
      </Row>
    </>
  );
}
export default SVTProductViewForEditOrder;
