import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import SVTIncomingOrderModifierView from './SVTIncomingOrderModifierView';
import SVTIncomingOrderProductView from './SVTIncomingOrderProductView';
import SVTTotalSectionOfIncomingOrderCard from './SVTTotalSectionOfIncomingOrderCard';

function SVTIncomingOrderItemsView(props) {
  const { orderItems, totalPrice, totalItems, partnerName } = props;

  return (
    <>
      <Container className='oder-items-view-in-incoming-order pt-3'>
        <Row>
          <Col sm={8} lg={6} xxl={8} className='word-break'>
            ITEM NAME
          </Col>
          <Col sm={2} lg={3} xxl={2} className='word-break'>
            QTY
          </Col>
          <Col
            sm={2}
            lg={3}
            xxl={2}
            className='d-flex flex-row-reverse word-break'
          >
            <section>PRICE</section>
          </Col>
        </Row>
        <Row className='grey-border mt-10 mb-3'></Row>
        {orderItems?.map((orderItem) => {
          return (
            <>
              <SVTIncomingOrderProductView productItem={orderItem} />
              <section className='mt-10 mb-10'>
                {orderItem?.children?.map((modifier) => {
                  return (
                    <>
                      <SVTIncomingOrderModifierView
                        modifierItem={modifier}
                        partnerName={partnerName}
                      />
                      {modifier?.children &&
                        modifier.children.map((subModifier) => {
                          return (
                            <SVTIncomingOrderModifierView
                              modifierItem={subModifier}
                              partnerName={partnerName}
                            />
                          );
                        })}
                    </>
                  );
                })}
              </section>
            </>
          );
        })}
        <SVTTotalSectionOfIncomingOrderCard
          totalItems={totalItems}
          totalPrice={totalPrice}
        />
      </Container>
    </>
  );
}

export default SVTIncomingOrderItemsView;
