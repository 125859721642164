import { combineReducers } from 'redux';
import { orderManagementRelated } from './orderManagementReducer';
import { loader } from './loader';
import {authInfo} from './authReducer';
import {menuManagementRelated} from './menuManagementRelated';
import {errorPageRelated} from './SVTErrorReducer';

const rootReducer = combineReducers({
  orderManagementRelated,
  loader,
  authInfo,
  menuManagementRelated,
  errorPageRelated
});

export default rootReducer;
