import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import SVTProductViewForEditOrder from './SVTProductViewForEditOrder';
import SVTTotalSectionOfIncomingOrderCard from './SVTTotalSectionOfIncomingOrderCard';
import SVTModifierForEditOrder from './SVTModifierForEditOrder';

function SVTEditOrderViewForIncomingOrderCard(props) {
  const {
    currentOrderItems,
    modifyOrderItemHandler,
    isMaximumLimitReached,
    totalItems,
    totalPrice,
  } = props;
  return (
    <>
      <Container className='oder-items-view-in-incoming-order pt-3'>
        <Row>
          <Col sm={1} lg={2} xxl={1}>
            AVL.
          </Col>
          <Col sm={7} lg={4} xxl={7} className='word-break'>
            ITEM NAME
          </Col>
          <Col sm={2} lg={3} xxl={2} className=''>
            QTY
          </Col>
          <Col sm={2} lg={3} xxl={2} className='d-flex flex-row-reverse'>
            <section>PRICE</section>
          </Col>
        </Row>
        <Row className='grey-border mt-10 mb-3'></Row>
        {currentOrderItems?.map((productItem, index) => {
          return (
            <>
              <SVTProductViewForEditOrder
                productItem={productItem}
                index={index}
                modifyOrderItemHandler={modifyOrderItemHandler}
                isMaximumLimitReached={isMaximumLimitReached}
              />
              <section className='mt-10 mb-10'>
                {productItem?.children?.map((modifier) => {
                  return (
                    <>
                      <SVTModifierForEditOrder
                        modifierItem={modifier}
                        isLineThrough={productItem?.quantity === 0}
                      />
                      {modifier?.children?.map((subModifier) => {
                        return (
                          <>
                            <SVTModifierForEditOrder
                              modifierItem={subModifier}
                              isLineThrough={productItem?.quantity === 0}
                            />
                          </>
                        );
                      })}
                    </>
                  );
                })}
              </section>
            </>
          );
        })}
        <SVTTotalSectionOfIncomingOrderCard
          totalItems={totalItems}
          totalPrice={totalPrice}
        />
      </Container>
    </>
  );
}

export default SVTEditOrderViewForIncomingOrderCard;
