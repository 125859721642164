import React from 'react';
import ubereats_receipt from '../../images/uberEatsStore.png';
import menulog_receipt from '../../images/MenuLogStore.png';
import doordash_receipt from '../../images/Doordash_receipt.svg';

class SVTComponentToPrint extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      totalItemsCost: 0,
      showPrice: false,
    };
  }

  formatAMPM = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const amOrPm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    const strTime = hours + ':' + minutes + ' ' + amOrPm;
    return strTime;
  };
  convertTZ = (date, tzString) => {
    const currentTime = new Date(
      (typeof date === 'string' ? new Date(date) : date).toLocaleString(
        'en-US',
        { timeZone: tzString }
      )
    );
    return new Date(currentTime.getTime() + 64000);
  };
  getAceeptedAt() {
    if (!this.props.details?.cancelled_at) {
      const acc_time = this.props.details?.accepted_at;
      const d_acc_time = acc_time.split('T')[1];
      const t_acc_time = d_acc_time.split(':');
      let output_msg = '';
      let am_pm = '';
      let hour_time = '';
      if (Number(t_acc_time[0]) > 12) {
        am_pm = ' PM';
        hour_time = String(Number(t_acc_time[0]) - 12);
      } else {
        am_pm = ' AM';
        hour_time = t_acc_time[0];
      }
      output_msg += hour_time;
      output_msg += ':';
      output_msg += t_acc_time[1];
      output_msg += am_pm;
      return output_msg;
    } else {
      const acc_time = this.props.details?.cancelled_at;
      const d_acc_time = acc_time.split('T')[1];
      const t_acc_time = d_acc_time.split(':');
      let output_msg = '';
      let am_pm = '';
      let hour_time = '';
      if (Number(t_acc_time[0]) > 12) {
        am_pm = ' PM';
        hour_time = String(Number(t_acc_time[0]) - 12);
      } else {
        am_pm = ' AM';
        hour_time = t_acc_time[0];
      }
      output_msg += hour_time;
      output_msg += ':';
      output_msg += t_acc_time[1];
      output_msg += am_pm;
      return output_msg;
    }
  }

  getPartnerSuppoortNumber() {
    let p_s_number =
      this.props?.partner && this.props?.partner[0]?.partner_support_phone;
    if (p_s_number) {
      if (p_s_number.startsWith('+')) {
        p_s_number = p_s_number.slice(1);
      }
      return (
        p_s_number.slice(0, 3) +
        ' ' +
        p_s_number.slice(3, 6) +
        ' ' +
        p_s_number.slice(6)
      );
    } else {
      return '';
    }
  }

  isPartnerNotMenulogDoordashUbereats() {
    return (
      this.props.details?.partner_name !== 'Menulog' &&
      this.props.details?.partner_name !== 'Doordash' &&
      this.props.details?.partner_name !== 'Uber Eats'
    );
  }

  render() {
    let totalCost = 0;
    let totalItems = 0;
    const receiptImgs = {
      'Uber Eats': ubereats_receipt,
      Menulog: menulog_receipt,
      Doordash: doordash_receipt,
    };
    console.log('print bug item', this.props.details.order_items);
    console.log('print bug order', this.props.details);
    return (
      <div className='receipt_style print-receipt-font'>
        <div className='text-center'>
          <img
            src={receiptImgs[this.props.details?.partner_name]}
            alt='Aggregator Receipt'
          />
        </div>
        <div className='extra_small_text receipt_address_style'>
          {this.props.storeDetails?.store_address}
        </div>
        <div className='gray_solid_border_top pt-2 text-center extra_large_text'>
          {this.props?.details?.cancelled_at ? 'Cancelled' : 'Delivery'}
        </div>
        <div className='mt-3'>
          <div className='extra_small_text'>Order Number</div>
          <div className='extra_large_text'>
            {this.props.details?.partner_id?.toUpperCase()}
          </div>
        </div>
        <div className='my-2'>
          <div className='extra_small_text'>Order for:</div>
          <div className='extra_large_text'>
            {this.props.details?.customer_name}
          </div>
        </div>
        <div className='gray_solid_border_top padding-top-bottom-print-receipt'>
          <div className='extra_small_text'>Special Instructions</div>
          <div className='mt-1 small_text'>
            {this.props.details?.delivery_instructions}
          </div>
        </div>
        <div className='gray_solid_border_top padding-top-bottom-print-receipt'>
          {this.props.details?.order_items?.map((item, index) => {
            totalItems += Number(item?.quantity ? item?.quantity : 1);
            totalCost +=
              parseFloat(
                item?.unit_price
                  ? item?.unit_price / 100
                  : item?.price / item?.quantity / 100
              ).toFixed(2) * (item?.quantity ? item?.quantity : 1);
            return (
              <>
                <div className='mt-1'>
                  <ProductItem item={item} showPrice={this.state.showPrice} />
                  {item?.children?.length > 0 &&
                    item?.children?.map((itm) => {
                      totalItems += Number(itm?.quantity ? itm?.quantity : 1);
                      totalCost += parseFloat(
                        itm?.price
                          ? itm?.price / 100
                          : (itm?.unit_price / 100).toFixed(2) *
                              ((itm?.quantity && itm?.quantity) || 1)
                      );
                      return (
                        <>
                          <ProductModifierItem
                            itm={itm}
                            partnerName={this.props.details?.partner_name}
                            showPrice={this.state.showPrice}
                          />
                          {itm?.children &&
                            itm.children.map((subModifier) => {
                              return (
                                <ProductModifierItem
                                  itm={subModifier}
                                  partnerName={this.props.details?.partner_name}
                                  showPrice={this.state.showPrice}
                                />
                              );
                            })}
                        </>
                      );
                    })}
                  {item?.special_instructions && (
                    <div className='row print-notes-text spacing-bottom-print'>
                      <div className='note-print-margin col col-1  ps-1 pe-0'>
                        Note:
                      </div>
                      <div className='col col-10 px-0 mx-0 print-text-special-instruction'>
                        {item?.special_instructions}
                      </div>
                    </div>
                  )}
                </div>
              </>
            );
          })}
        </div>
        {this.state.showPrice && (
          <div className='my-2 padding-top-bottom-print-receipt gray_dashed_border_top'>
            <div className='d-flex small_text'>
              <div className='print-component-total text-end'>Total</div>
              <div className='ms-auto ps-2'>${totalCost.toFixed(2)}</div>
            </div>
          </div>
        )}
        <div className='pt-2 padding-top-bottom-print-receipt gray_solid_border_top'>
          <div className='extra_small_text'>
            <span>{this.props.details?.partner_name}</span> Support Number:
          </div>
          <div className='extra_large_text'>
            {this.getPartnerSuppoortNumber()}
          </div>
          <div className='mt-2 extra_small_text'>
            <div className='d-flex'>
              <div>Order placed at:</div>
              <div className='ms-auto '>{this.props.details?.time}</div>
            </div>
            <div className='d-flex'>
              <div>
                {this.props.details?.cancelled_at
                  ? 'Order cancelled at:'
                  : null}
              </div>
              {this.props.details?.cancelled_at ? (
                <div className='ms-auto ps-2'>
                  {this.formatAMPM(new Date(this.props.details?.cancelled_at))}
                </div>
              ) : (
                <>
                  {this.props.details?.accepted_at &&
                    this.isPartnerNotMenulogDoordashUbereats() && (
                      <div className='ms-auto ps-2'>
                        {this.formatAMPM(
                          new Date(this.props.details?.accepted_at)
                        )}
                      </div>
                    )}
                  {this.props.details?.accepted_at === undefined &&
                    this.isPartnerNotMenulogDoordashUbereats() && (
                      <div className='ms-auto ps-2'>
                        {this.formatAMPM(new Date())}
                      </div>
                    )}
                </>
              )}
            </div>
          </div>
        </div>
        <div className='padding-top-bottom-print-receipt gray_solid_border_top extra_small_text text-center'>
          Thank you for ordering from us!
        </div>
      </div>
    );
  }
}
export default SVTComponentToPrint;

export function ProductItem(props) {
  const { item, showPrice } = props;
  return (
    <div
      className='d-flex w-100 extra_small_text spacing-bottom-print'
      key={item?.partner_id}
      data-test='productItemMainComponent'
    >
      <div
        className='font_bold fixed-width-count extra_small_text'
        style={{ textAlign: 'end' }}
      >
        {item.quantity ? item?.quantity : 1}x
      </div>
      <div
        className={
          showPrice ? 'fixed-width-text  ' : 'fixed-width-text-no-price  '
        }
      >
        {item?.name}
      </div>
      {showPrice && (
        <div className='fixed-width'>
          $
          {item?.unit_price
            ? (item?.unit_price / 100).toFixed(2)
            : (item?.price / item?.quantity / 100).toFixed(2)}
        </div>
      )}
    </div>
  );
}

export function ProductModifierItem(props) {
  const { itm, showPrice, partnerName } = props;
  return (
    <>
      <div
        className='d-flex w-100 extra_small_text spacing-bottom-print'
        key={itm?.plu}
        data-test='productModifierItemMainComponent'
      >
        <div className=' '></div>
        <div className='font_bold fixed-width-modifier-count extra_small_text'>
          {itm.quantity ? itm?.quantity : 1}x
        </div>
        <div
          className={
            showPrice
              ? 'fixed-width-modifier-text  '
              : 'fixed-width--modifier-text-no-price  '
          }
        >
          <div>{partnerName === 'Menulog' ? itm?.name : itm?.description}</div>{' '}
        </div>
        {showPrice && (
          <div className='fixed-width '>
            $
            {itm?.unit_price
              ? (itm?.unit_price / 100).toFixed(2)
              : (itm?.price / itm?.quantity / 100).toFixed(2)}
          </div>
        )}
      </div>
    </>
  );
}
