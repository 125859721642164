import React, { useState, useEffect } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SVTOrderHistoryFilterDates from './SVTOrderHistoryFilterDates';
import CheckBox from '../../images/CheckBox.svg';
import emptyCheckBox from '../../images/emptyCheckBox.svg';
import filter_cross from '../../images/filter_cross.svg';
function SVTOrderHistoryFilterPopup(props) {
  const [filterList, setFilterList] = useState(props.orderHistoryFilter);
  const [lastSevenDates, setLastSevenDates] = useState({
    datesList: [],
    fromDay: '',
    toDay: '',
    startFrom: undefined,
    endFrom: undefined,
  });
  const defaultSelectedHandler = () => {
    const defaultSelected = [
      'uberEats',
      'menuLog',
      'doordash',
      'completed',
      'cancelledAggregator',
      'lastSevenDays',
    ];
    setFilterList(defaultSelected);
    setLastSevenDates((prevState) => ({
      ...prevState,
      datesList: Last7Dates(),
      fromDay: Last7Dates()[0]['Date'],
      toDay: Last7Dates()[0]['Date'],
      startFrom: 0,
    }));
  };
  useEffect(() => {
    if (props.orderHistoryFilter?.length === 0) {
      defaultSelectedHandler();
    } else {
      setFilterList(props.orderHistoryFilter);
    }
  }, [props.orderHistoryFilter]);
  useEffect(() => {
    setLastSevenDates((prevState) => ({
      ...prevState,
      datesList: Last7Dates(),
      fromDay: Last7Dates()[0]['Date'],
      toDay: Last7Dates()[0]['Date'],
      startFrom: 0,
    }));
  }, []);
  const checkboxHandler = (id) => {
    let tmpFilterList = filterList.slice();
    if (tmpFilterList.includes(id)) {
      tmpFilterList = tmpFilterList.filter((item) => item !== id);
    } else {
      tmpFilterList.push(id);
    }
    setFilterList(tmpFilterList.slice());
  };
  const radioBtnHandler = (id) => {
    let tmpFilterList = filterList.slice();
    tmpFilterList = tmpFilterList.filter((item) => item !== 'today');
    tmpFilterList = tmpFilterList.filter((item) => item !== 'lastSevenDays');
    tmpFilterList = tmpFilterList.filter((item) => item.custom === undefined);
    if (id === 'custom') {
      tmpFilterList.push({
        custom: `${lastSevenDates.fromDay},${lastSevenDates.toDay}`,
      });
    } else {
      tmpFilterList.push(id);
    }
    setFilterList(tmpFilterList.slice());
  };
  const submitHandler = () => {
    const tmp = filterList.slice();
    if (isCustomRadioFilterActive()) {
      for (let index = 0; index < filterList.length; index++) {
        if (filterList[index].custom) {
          tmp[index] = {
            custom: `${lastSevenDates.fromDay},${lastSevenDates.toDay}`,
          };
        }
      }
    }
    props.checkboxSubmitHandler(tmp);
    props.onHide();
  };
  const formatDate = (date) => {
    const monthsList = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    return date.getDate() + '-' + monthsList[date.getMonth()];
  };
  const formatDay = (date) => {
    const days = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    return days[date.getDay()];
  };
  const Last7Dates = () => {
    let result = [];
    for (let i = 0; i < 7; i++) {
      let d = new Date();
      d.setDate(d.getDate() - i);
      result.push({ Date: formatDate(d), Day: formatDay(d) });
    }
    result[0]['Day'] = 'Today';
    return result;
  };
  const setDateFrom = (date) => {
    let indexOfDateFrom = -1;
    for (let index = 0; index < lastSevenDates.datesList.length; index++) {
      if (lastSevenDates.datesList[index].Date === date) {
        indexOfDateFrom = index;
        break;
      }
    }
    setLastSevenDates((prevState) => ({
      ...prevState,
      fromDay: date,
      startFrom: indexOfDateFrom,
    }));
  };
  const setDateTo = (date) => {
    let endFrom = undefined;
    for (let index = 0; index < lastSevenDates.datesList.length; index++) {
      if (lastSevenDates?.datesList[index]['Date'] === date) {
        endFrom = index;
        break;
      }
    }
    setLastSevenDates((prevState) => ({
      ...prevState,
      toDay: date,
      endFrom: endFrom,
    }));
  };
  const isCustomRadioFilterActive = () => {
    for (let index = 0; index < filterList.length; index++) {
      if (filterList[index].custom) {
        return true;
      }
    }
    return false;
  };
  return (
    <Modal
      {...props}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      dialogClassName='modal-custom-width'
    >
      <Modal.Header id='histoy_filter_popup'>
        <Modal.Title id='contained-modal-title-vcenter'>
          <p className='m-0 p-0 normal-font fw-bold'>Filter</p>
        </Modal.Title>
        <img
          src={filter_cross}
          onClick={props.onHide}
          alt='cross icon'
          className='edit-order-cursor-pointer'
        />
      </Modal.Header>
      <Modal.Body id='history-filter-body'>
        <div className='d-flex flex-column' style={{ rowGap: '30px' }}>
          <div className='filter-grid' id='partner-filter'>
            <div>Partner</div>
            <div className='row-grid'>
              <div className='form-group'>
                {filterList.includes('uberEats') ? (
                  <img
                    src={CheckBox}
                    alt='checked'
                    id='uberEats'
                    onClick={(e) => checkboxHandler(e.target.id)}
                  ></img>
                ) : (
                  <img
                    src={emptyCheckBox}
                    alt='un-checked'
                    id='uberEats'
                    onClick={(e) => checkboxHandler(e.target.id)}
                  ></img>
                )}
                <label htmlFor='uberEats' className='form-group-spacing-img'>
                  Uber Eats
                </label>
              </div>
              <div className='form-group'>
                {filterList.includes('doordash') ? (
                  <img
                    src={CheckBox}
                    alt='checked'
                    id='doordash'
                    onClick={(e) => checkboxHandler(e.target.id)}
                  ></img>
                ) : (
                  <img
                    src={emptyCheckBox}
                    alt='un-checked'
                    id='doordash'
                    onClick={(e) => checkboxHandler(e.target.id)}
                  ></img>
                )}
                <label htmlFor='doordash' className='form-group-spacing-img'>
                  {' '}
                  Doordash
                </label>
              </div>
            </div>
            <div className='row-grid'>
              <div className='form-group'>
                {/* <input
                  type='checkbox'
                  id='menuLog'
                  checked={filterList.includes('menuLog')}
                  onClick={(e) => checkboxHandler(e.target.id)}
                /> */}
                {filterList.includes('menuLog') ? (
                  <img
                    src={CheckBox}
                    alt='checked'
                    id='menuLog'
                    onClick={(e) => checkboxHandler(e.target.id)}
                  ></img>
                ) : (
                  <img
                    src={emptyCheckBox}
                    alt='un-checked'
                    id='menuLog'
                    onClick={(e) => checkboxHandler(e.target.id)}
                  ></img>
                )}
                <label htmlFor='menuLog' className='form-group-spacing-img'>
                  {' '}
                  MenuLog
                </label>
              </div>
            </div>
          </div>
          <div className='filter-grid'>
            <div>Order Status</div>
            <div className='row-grid'>
              <div className='form-group'>
                {/* <input
                  type='checkbox'
                  id='completed'
                  checked={filterList.includes('completed')}
                  onClick={(e) => checkboxHandler(e.target.id)}
                /> */}
                {filterList.includes('completed') ? (
                  <img
                    src={CheckBox}
                    alt='checked'
                    id='completed'
                    onClick={(e) => checkboxHandler(e.target.id)}
                  ></img>
                ) : (
                  <img
                    src={emptyCheckBox}
                    alt='un-checked'
                    id='completed'
                    onClick={(e) => checkboxHandler(e.target.id)}
                  ></img>
                )}
                <label htmlFor='completed' className='form-group-spacing-img'>
                  {' '}
                  Completed
                </label>
              </div>
            </div>
            <div className='row-grid'>
              <div className='form-group'>
                {/* <input
                  type='checkbox'
                  id='cancelledAggregator'
                  checked={filterList.includes('cancelledAggregator')}
                  onClick={(e) => checkboxHandler(e.target.id)}
                /> */}
                <Row>
                  <Col md={1}>
                    {filterList.includes('cancelledAggregator') ? (
                      <img
                        src={CheckBox}
                        alt='checked'
                        id='cancelledAggregator'
                        onClick={(e) => checkboxHandler(e.target.id)}
                      ></img>
                    ) : (
                      <img
                        src={emptyCheckBox}
                        alt='un-checked'
                        id='cancelledAggregator'
                        onClick={(e) => checkboxHandler(e.target.id)}
                      ></img>
                    )}
                  </Col>
                  <Col>
                    <label
                      htmlFor='cancelledAggregator'
                      className='form-group-spacing-img'
                    >
                      <div>Cancelled</div>
                    </label>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          <div className='filter-grid-dates '>
            <div>Dates</div>
            <div
              className='form-check d-flex flex-column'
              style={{ rowGap: '22px', marginBottom: '25px' }}
            >
              <div>
                <input
                  className='form-check-input shadow-none'
                  type='radio'
                  name='filterData'
                  id='today'
                  checked={filterList.includes('today')}
                  onChange={(e) => radioBtnHandler(e.target.id)}
                />
                <label className='form-check-label ms-2' htmlFor='today'>
                  Today
                </label>
              </div>
              <div>
                <input
                  className='form-check-input shadow-none'
                  type='radio'
                  name='filterData'
                  id='lastSevenDays'
                  checked={filterList.includes('lastSevenDays')}
                  onChange={(e) => radioBtnHandler(e.target.id)}
                />
                <label
                  className='form-check-label ms-2'
                  htmlFor='lastSevenDays'
                >
                  Last 7 Days
                </label>
              </div>
              <div className='test-grid'>
                <div>
                  <input
                    className='form-check-input shadow-none'
                    type='radio'
                    name='filterData'
                    id='custom'
                    checked={isCustomRadioFilterActive()}
                    onChange={(e) => radioBtnHandler(e.target.id)}
                  />
                </div>

                <div>
                  <label
                    className='form-check-label ms-2 me-3'
                    htmlFor='custom'
                  >
                    Custom
                  </label>
                </div>
                <div>
                  {/* <input type='date' className='date-btn' id='startDate' />
                  <span>to</span>
                  <input type='date' className='date-btn' id='startDate' /> */}
                  <div className='d-flex'>
                    <SVTOrderHistoryFilterDates
                      lastSevenDates={lastSevenDates.datesList}
                      selectDate={lastSevenDates?.fromDay}
                      setSelectDate={setDateFrom}
                      isCustomRadioFilterActive={isCustomRadioFilterActive}
                      endFrom={lastSevenDates?.endFrom}
                    />
                    <span className='ps-3 pe-3'>to</span>
                    <SVTOrderHistoryFilterDates
                      lastSevenDates={lastSevenDates.datesList}
                      selectDate={lastSevenDates?.toDay}
                      setSelectDate={setDateTo}
                      startFrom={lastSevenDates.startFrom}
                      isCustomRadioFilterActive={isCustomRadioFilterActive}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='filter-modal-footer'>
          <div className='filter-modal-footer-buttons'>
            <div>
              <button className='reset-btn' onClick={defaultSelectedHandler}>
                Reset
              </button>
            </div>
            <div>
              <button className='cancel-btn' onClick={props.onHide}>
                Cancel
              </button>
              <button className='apply-btn' onClick={submitHandler}>
                Apply
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function mapState(state) {
  const { orderManagementRelated } = state;
  const { orderHistoryFilter } = orderManagementRelated;
  return { orderHistoryFilter };
}
const actionCreators = {};

export default compose(
  withRouter,
  connect(mapState, actionCreators)
)(SVTOrderHistoryFilterPopup);
