import {menuManagementService} from '../services/SVTMenuManagementService'
import { startLoader, stopLoader, showErrorPage } from '../helper/common';
import { constants } from '../constant/reducerConstant';
export const menuManagementAction = {
    getAllProductsList,
	getCategoriesList,
	getAllProductsAndModifiersListFromSearchQuery,
	updateProductStatus,
	storeSearchQuery,
	saveCurrentActiveCategory,
	setUnavailableItemsCount,
	getTotalUnavailableCount,
	getTotalUnavailableItemList,
	setMenuModifiers,
	setMenuSubModifiers,
	setCurrentActiveProduct,
	updateModifierStatus,
	getRecentTabProductList,
	setModifierProductKey,
	setModifierLevel2ProductKey,
	setOptionName,
	setOPtionLevel2Name,
	clearAllMenuSelections,
	getAllModifiersList,
	setCurrentActiveModifier,
	setProductStatusToNull,
	setShowLoaderForProducts,
	setShowLoaderForModifiers,
	setShowLoaderForModifiersObj,
	updateCurrentToggledModifier,
	setItemListForWhichAPIFailing,
	setItemInListForWhichAPIFailing,
	setItemHavingErrorPopupState,
}

function getAllProductsAndModifiersListFromSearchQuery(searchQuery){
	return dispatch => {
		dispatch(startLoader());
		menuManagementService.getAllProductsListFromSearchQuery(searchQuery).then(
			data => {
				dispatch(stopLoader());
				dispatch(getAllProductsListFromSearchQuerySuccess(data?.data));
			},
			error => {
				dispatch(getAllProductsListFromSearchQueryFail());
				dispatch(stopLoader());
			},
		);
		menuManagementService.getAllModifiersListFromSearchQuery(searchQuery).then(
			data => {
				dispatch(stopLoader());
				dispatch(getAllModifiersListFromSearchQuerySuccess(data?.data));
			},
			error => {
				dispatch(getAllModifiersListFromSearchQueryFail());
				dispatch(stopLoader());
			},
		);
	};
	function getAllProductsListFromSearchQuerySuccess(data) {
		return { type: constants.SET_ALL_PRODUCTS_LIST_FROM_SEARCH_QUERY, data };
	}
	function getAllProductsListFromSearchQueryFail() {
		return { type: constants.SET_ALL_PRODUCTS_LIST_FROM_SEARCH_QUERY,data:[] };
	}
	function getAllModifiersListFromSearchQuerySuccess(data) {
		const formattedModifiersList = [];
		data.forEach((modifier) => {
			formattedModifiersList.push(formatModifierItem(modifier));
		});
		return { type: constants.SET_ALL_MODIFIERS_LIST_FROM_SEARCH_QUERY, data:formattedModifiersList };
	}
	function getAllModifiersListFromSearchQueryFail() {
		return { type: constants.SET_ALL_MODIFIERS_LIST_FROM_SEARCH_QUERY,data:[] };
	}
}
function updateProductStatus(payload,setCurrentActiveProductPayload){
	return dispatch => {
		dispatch(startLoader());
		menuManagementService.updateProductStatus(payload).then(
			data => {
				dispatch(stopLoader());
				dispatch(updateProductStatusSuccess(data));
				dispatch(setCurrentActiveProduct(setCurrentActiveProductPayload))
			},
			error => {
				dispatch(setItemInListForWhichAPIFailing(payload.product_key));
				const itemHavingErrorPopupStatePayload= {
					shouldShow:true,
					itemName:setCurrentActiveProductPayload.productName??''
				}
				dispatch(setItemHavingErrorPopupState(itemHavingErrorPopupStatePayload))
				dispatch(stopLoader());
				dispatch(setShowLoaderForProducts(payload.product_key, false))
			},
		);
	};
	function updateProductStatusSuccess(data) {
		return { type: constants.UPDATE_PRODUCT_STATUS_SUCCESS, data };
	}
	function updateProductStatusFail() {
		return { type: constants.UPDATE_PRODUCT_STATUS_FAIL };
	}
}
function getCategoriesList() {
	return dispatch => {
		dispatch(startLoader());
		menuManagementService.getCategoriesList().then(
			data => {
				dispatch(stopLoader());
				dispatch(getCategoriesListSuccess(data));
			},
			error => {
				dispatch(getCategoriesListFail());
				dispatch(stopLoader());
			},
		);
	};
	function getCategoriesListSuccess(data) {
		return { type: constants.GET_CATEGORIES_LIST_SUCCESS, data };
	}
	function getCategoriesListFail() {
		return { type: constants.GET_CATEGORIES_LIST_FAIL };
	}
}

function storeSearchQuery(data){
	return {type:constants.STORE_SEARCH_QUERY,data};
}
function saveCurrentActiveCategory(data){
	return {type:constants.SET_CURRENT_ACTIVE_CATEGORY,data};
}
function setUnavailableItemsCount(data){
	return {type:constants.SET_UNAVAILABLE_COUNT,data}
}
 function getTotalUnavailableCount() {
	return dispatch => {
		dispatch(startLoader());
		menuManagementService.getTotalUnavailableCount().then(
			data => {
				dispatch(stopLoader());
				dispatch(getTotalUnavailableCountSuccess(data));
			},
			error => {
				dispatch(getTotalUnavailableCountFail());
				dispatch(stopLoader());
			},
		);
	};
	function getTotalUnavailableCountSuccess(data) {
		return { type: constants.GET_TOTAL_UNAVAILABLE_COUNT_SUCCESS, data };
	}
	function getTotalUnavailableCountFail() {
		return { type: constants.GET_TOTAL_UNAVAILABLE_COUNT_FAIL };
	}
}
function getTotalUnavailableItemList() {
	return dispatch => {
		dispatch(startLoader());
		menuManagementService.getTotalUnavailableItemList().then(
			data => {
				dispatch(stopLoader());
				dispatch(
					getTotalUnavailableItemListSuccess(
						formatUnavailableProductAndModifier(data),
					),
				);
			},
			error => {
				dispatch(getTotalUnavailableItemListFail());
				dispatch(showErrorPage(error, 'Total UnavailableItem List'));
				dispatch(stopLoader());
			},
		);
	};
	function formatUnavailableProductAndModifier(data) {
		const {
			data: unavailableProductsList = [],
			modifiers_data: unavailableModifiersList = [],
		} = data;
		const allUnavailableProductsAndModifiersList = [
			...unavailableProductsList,
		];
		unavailableModifiersList.forEach(modifier => {
			const formattedModifier = formatModifierItem(modifier);
			formattedModifier['isModifier'] = true;
			allUnavailableProductsAndModifiersList.push(formattedModifier);
		});
		return allUnavailableProductsAndModifiersList;
	}
	function getTotalUnavailableItemListSuccess(data) {
		return {
			type: constants.GET_TOTAL_UNAVAILABLE_ITEM_LIST_SUCCESS,
			data,
		};
	}
	function getTotalUnavailableItemListFail() {
		return { type: constants.GET_TOTAL_UNAVAILABLE_ITEM_LIST_FAIL };
	}
}


function setMenuModifiers(data){
	return {type:constants.SET_MENU_MODIFIERS,data}
}
function setMenuSubModifiers(data){
	return {type:constants.SET_MENU_SUB_MODIFIERS,data}
}
function setCurrentActiveProduct(payload){
	return {type:constants.SET_CURRENT_ACTIVE_PRODUCT,payload}
}
function updateModifierStatus(payload){
	return dispatch => {
		dispatch(startLoader());
		menuManagementService.updateModifierStatus(payload).then(
			data => {
				dispatch(stopLoader());
			},
			error => {
				const itemHavingErrorPopupStatePayload= {
					shouldShow:true,
					itemName:payload?.modifierName??''
				}
				dispatch(setItemInListForWhichAPIFailing(payload.sku_code));
				dispatch(setItemHavingErrorPopupState(itemHavingErrorPopupStatePayload))
				dispatch(setShowLoaderForModifiers(payload.sku_code,false));
				dispatch(stopLoader());
			},
		);
	};

}
function updateCurrentToggledModifier(data){
	return setCurrentActiveModifier(data);
}
function setCurrentActiveModifier(data) {
	const currentActiveModifier={
		skuCode: '',
		modifierName:'',
		isModifierAvailable:false,
		shouldShow: false,
		numberOfProductsHavingThisModifier:1
	}
	if(!data){ 
		return {type:constants.SET_CURRENT_ACTIVE_MODIFIER,data:currentActiveModifier};
	}
	const {
		skuCode = '',
		availability=true,
		modifierName = '',
		numberOfProductsHavingThisModifier=1,
	} = data;
	currentActiveModifier.skuCode = skuCode;
	currentActiveModifier.isModifierAvailable = availability;
	currentActiveModifier.modifierName = modifierName;
	currentActiveModifier.shouldShow = true
	currentActiveModifier.numberOfProductsHavingThisModifier = numberOfProductsHavingThisModifier;
	return {type:constants.SET_CURRENT_ACTIVE_MODIFIER,data:currentActiveModifier};
}
function getRecentTabProductList() {
	return dispatch => {
		dispatch(startLoader());
		menuManagementService.getRecentTabProductList().then(
			data => {
				dispatch(stopLoader());
				dispatch(getProductListSuccess(data?.data));
			},
			error => {
				dispatch(getProductListFail());
				dispatch(stopLoader());
			},
		);
	};
	function getProductListSuccess(data) {
		return { type: constants.GET_PRODUCT_LIST_SUCCESS, data };
	}
	function getProductListFail() {
		return { type: constants.GET_PRODUCT_LIST_FAIL };
	}
}


function setModifierProductKey(data){
	console.log("from funct",data)
	return {type:constants.SET_MODIFIER_PRODUCT_KEY,data};
}
function setModifierLevel2ProductKey(data){
	return {type:constants.SET_MODIFIER_LEVEL2_PRODUCT_KEY,data};
}
function setOptionName(data){
	return {type:constants.SET_OPTION_NAME,data};
}
function setOPtionLevel2Name(data){
	return {type:constants.SET_OPTION_LEVEL2_NAME,data};
}

function clearAllMenuSelections() {
	return { type: constants.CLEAR_ALL_MENU_SELECTIONS };
}
function getAllProductsList() {
	return dispatch => {
		dispatch(startLoader());
		menuManagementService.getAllProductsList().then(
			data => {
				dispatch(stopLoader());
				dispatch(getProductListSuccess(data?.data));
			},
			error => {
				dispatch(getProductListFail());
				dispatch(stopLoader());
				dispatch(showErrorPage(error, 'Product List'));
			},
		);
	};
	function getProductListSuccess(data) {
		return { type: constants.SET_ALL_PRODUCTS_LIST, data };
	}
	function getProductListFail() {
		return { type: constants.SET_ALL_PRODUCTS_LIST,data:[] };
	}
}
function getAllModifiersList() {
	return dispatch => {
		dispatch(startLoader());
		menuManagementService.getAllModifiersList().then(
			data => {
				dispatch(stopLoader());
				dispatch(getProductListSuccess(data.data));
			},
			error => {
				dispatch(getProductListFail());
				dispatch(stopLoader());
				dispatch(showErrorPage(error, 'All Modifiers List'));
			},
		);
	};
	function getProductListSuccess(data=[]) {
		const formattedModifiersList = [];
		data.forEach((modifier) => {
			formattedModifiersList.push(formatModifierItem(modifier));
		});
		return { type: constants.SET_ALL_MODIFIERS_LIST, data:formattedModifiersList };
	}
	function getProductListFail() {
		return { type: constants.SET_ALL_MODIFIERS_LIST,data:[] };
	}
}
function formatModifierItem(item) {
	const { availability=true, sku_code='',product_keys=[],updated_on:lastUpdated } = item;
	const alter_name = item?.option_name?.toLowerCase();
	const numberOfProductsHavingThisModifier = product_keys?.length;
	return {
		availability,
		sku_code,
		alter_name,
		numberOfProductsHavingThisModifier,
		lastUpdated,
	};
}
function setProductStatusToNull(){
	return { type: constants.UPDATE_PRODUCT_STATUS_FAIL };
}

function setShowLoaderForProducts(productKey, showLoader){
	return {type: constants.SHOW_LOADER_FOR_PRODUCTS, data: productKey, add: showLoader}
}

function setShowLoaderForModifiers(skuCode, showLoader){
	return {type: constants.SHOW_LOADER_FOR_MODIFIERS, data: skuCode, add: showLoader}
}
function setShowLoaderForModifiersObj(skuCodesObj){
	return {type: constants.SET_SHOW_LOADER_FOR_MODIFIERS_OBJ, data:skuCodesObj }
}

function setItemInListForWhichAPIFailing(item){
	return {type:constants.ADD_ITEM_IN_LIST_FOR_WHICH_API_IS_FAILING,data:item}
}
function setItemListForWhichAPIFailing(itemList){
	return{type:constants.SET_ITEMS_LIST_FOR_WHICH_API_IS_FAILING,data:itemList}
}
function setItemHavingErrorPopupState(data){
	return {type:constants.SET_ITEM_HAVING_ERROR_POPUP_STATE,data:data}
}