import React from 'react';
import { Row, Col, Modal } from 'react-bootstrap';
import cross_icon from '../../images/cross_icon.svg';

function SVTPartialAcceptancePopup(props) {
  const {
    show,
    onHide,
    isAllItemsMarkedToZero,
    partialAcceptHandler,
    partnerId,
  } = props;
  return (
    <Modal
      show={show}
      onHide={onHide}
      size='md'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header>
        <Modal.Title id='contained-modal-title-vcenter'>
          <div className='d-flex'>
            <p className='m-0 p-0 normal-font fw-bold'>
              {isAllItemsMarkedToZero() ? 'Cancel Order' : 'Confirm Changes'}
            </p>
          </div>
        </Modal.Title>
        <img
          src={cross_icon}
          height='21.98px'
          width='21.98px'
          onClick={() => onHide(false)}
          alt='cross icon'
          className='cursor-pointer'
        />
      </Modal.Header>
      <Modal.Body className='p-0 m-0 ps-3 pe-3'>
        <Row>
          <Col sm={12} className='m-0 pb-3'>
            <hr className='m-0 contact-supp-hr' />
          </Col>
          <Col md={12}>
            {isAllItemsMarkedToZero() && (
              <span>
                {' '}
                Are you sure you want to cancel order{' '}
                <strong>#{partnerId?.toUpperCase()} </strong>?
              </span>
            )}
            {!isAllItemsMarkedToZero() && (
              <span>
                {' '}
                Are you sure you want to save the changes made to order{' '}
                <strong>#{partnerId?.toUpperCase()} </strong>?
              </span>
            )}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <button
          type='button'
          className='green-outline-btn shadow-none'
          onClick={() => onHide(false)}
        >
          Cancel
        </button>
        <button
          type='button'
          className={' shadow-none ms-3 success-btn-modal'}
          onClick={partialAcceptHandler}
        >
          {isAllItemsMarkedToZero() ? 'Cancel Order' : 'P. Accept'}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
export default SVTPartialAcceptancePopup;
