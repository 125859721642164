import React from 'react';
import { Row, Col } from 'react-bootstrap';

function SVTIncomingOrderModifierView(props) {
  const { modifierItem, partnerName } = props;
  const listOfVenderSupportPartialAcceptance = ['uber eats', 'doordash'];

  return (
    <Row className='mt-1'>
      <Col
        sm={8}
        lg={6}
        xxl={8}
        className='word-break line-height-24 letter-spacing-O50'
      >
        <section className='pl-20' data-test='modifierName'>
          {listOfVenderSupportPartialAcceptance.includes(
            partnerName.toLowerCase()
          )
            ? modifierItem?.description
            : modifierItem?.name}
        </section>
      </Col>
      <Col
        sm={2}
        lg={3}
        xxl={2}
        className='word-break font-size-14 letter-spacing-025'
      >
        x{modifierItem?.quantity}
      </Col>
      <Col
        sm={2}
        lg={3}
        xxl={2}
        className='d-flex flex-row-reverse  font-size-14 letter-spacing-025'
      >
        <section data-test='modifierPrice'>
          $
          {(modifierItem?.unit_price
            ? modifierItem?.unit_price / 100
            : modifierItem?.price / modifierItem?.quantity / 100
          ).toFixed(2)}
        </section>
      </Col>
    </Row>
  );
}

export default SVTIncomingOrderModifierView;
