import React from 'react';
import { Row, Col, Container, Modal } from 'react-bootstrap';
import UberEatsLogo from '../../images/UberEats.svg';
import menulogLogo from '../../images/menuLog.svg';
import doordash from '../../images/Doordash.svg';
import collapseArrow from '../../images/collapseArrow.svg';
import expandArrow from '../../images/expandArrow.svg';
import phoneIcon from '../../images/phone.svg';
import SVTOrderHelpDropdown from './SVTOrderHelpDropdown';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { orderManagementActions } from '../../action/SVTOrderManagementAction';
import SVTPrintReceipt from './SVTPrintReceipt';
import { useHistory } from 'react-router-dom';
import circlePlus from '../../images/circleplus.svg';
import circlePlusGrey from '../../images/circlePlusGrey.svg';
import circleMinusGreen from '../../images/circleMinusGreen.svg';
import circleMinus from '../../images/circleMinus.svg';
import CheckBox from '../../images/CheckBoxFaint.svg';
import emptyCheckBox from '../../images/emptyCheckBoxFaint.svg';
import closeButtonGreen from '../../images/closeButtonGreen.svg';
import cross_icon from '../../images/cross_icon.svg';
import { orderManagementServices } from '../../services/SVTOrderManagementService';
import SVTFooterForIncomingOrderCard from './SVTFooterForIncomingOrderCard';
import SVTIncomingOrderCardTopView from './SVTIncomingOrderCardTopView';
import SVTIncomingOrderCardCustomerInfoView from './SVTIncomingOrderCardCustomerInfoView';
import SVTEditOrderFooter from './SVTEditOrderFooter';
import SVTIncomingOrderItemsView from './SVTIncomingOrderItemsView';
import SVTEditOrderViewForIncomingOrderCard from './SVTEditOrderViewForIncomingOrderCard';
import SVTPartialAcceptancePopup from './SVTPartialAcceptancePopup';

// const listOfVenderSupportPartialAcceptance = ['uber eats'];
// const imageList = {
// 	'uber eats': UberEatsLogo,
// 	'menulog': menulogLogo,
// }

function IncomingOrderCard(props) {
  const [currentOrder, setCurrentOrder] = React.useState();
  const [isExpanded, setIsExpanded] = React.useState(false);
  const [isAcceptBtnActive, setIsAcceptBtnActive] = React.useState(true);
  const [shouldOpenPartialOrderAccept, setShouldOpenPartialOrderAccept] =
    React.useState(false);
  const history = useHistory();
  React.useEffect(() => {
    if (props?.currentOrder?.isOpen) {
      setIsExpanded(true);
    }
    const tmpCurrentOrder = JSON.parse(
      JSON.stringify(props?.currentOrder) ?? {}
    );
    setCurrentOrder(tmpCurrentOrder);
  }, [props.currentOrder]);
  const {
    partner_name: partnerName = '',
    customer_phone: customerPhone = '',
    customer_phone_code: customerPhoneCode = '',
    customer_name: customerName = '',
    partner_id: partnerId = '',
    created_on: orderReceiveOn = '',
    time: orderReceiveOnTime = '',
    order_items: orderItems = [],
  } = currentOrder ?? {};
  const removeOrderFromIncomingOrderNotification = (orderId) => {
    if (props?.incomingOrderNotifications) {
      let incomingOrderNotifications = [...props?.incomingOrderNotifications];
      if (incomingOrderNotifications.length > 0) {
        incomingOrderNotifications = incomingOrderNotifications.filter(
          (notification) => notification.id !== orderId
        );
        props.saveIncomingOrderNewNotification(incomingOrderNotifications);
      }
    }
  };
  const orderAcceptHandler = async () => {
    setIsAcceptBtnActive(false);
    const payload = {
      id: currentOrder.id,
      status: 'Accepted',
    };

    props.storeOrderDetails(currentOrder);
    props.setShouldShowEditOrderViewInOrderDetailsPage(false);
    const updateOrder = await props.updateOrderStatus(payload);
    if (updateOrder?.status === 'SUCCESS') {
      props.setAutoPrintOption(true);
      removeOrderFromIncomingOrderNotification(currentOrder?.id);
      const headerMsgPayload = {
        show: true,
        type: 'Incoming',
        msg: 'Not Required',
        orderId: currentOrder?.id,
        partnerId: currentOrder?.partner_id?.toUpperCase(),
      };
      props?.setShowTopMsg(headerMsgPayload);
      props.getOrderReportCount();
      props.getIncomingOrders();
      props.getOrderList();
    } else if (updateOrder?.status === 'Failed') {
      props.getOrderReportCount();
      props.getIncomingOrders();
      props.getOrderList();
    }
    setIsAcceptBtnActive(true);
  };
  const dismissAutoAcceptedOrder = async () => {
    const disMissOrders = await props.dismissLiveOrders(currentOrder?.id);
    if (disMissOrders?.status === 'SUCCESS') {
      removeOrderFromIncomingOrderNotification(currentOrder?.id);
      props.getOrderReportCount();
      props.getIncomingOrders();
    }
  };
  const viewAutoAcceptedOrderDetails = async () => {
    props.storeOrderDetails(currentOrder);
    props.setShouldShowEditOrderViewInOrderDetailsPage(false);
    history.push('/order-details/Incoming');
    const disMissOrders = await props.dismissLiveOrders(currentOrder?.id);
    if (disMissOrders?.status === 'SUCCESS') {
      removeOrderFromIncomingOrderNotification(currentOrder?.id);
      props.getOrderReportCount();
      props.getIncomingOrders();
      props.setAutoPrintOption(true);
    }
  };
  const getOrderHelpDropDownForIncomingOrderFooter = () => {
    return (
      <>
        <SVTOrderHelpDropdown
          btnClass={'success-border-btn me-2'}
          order={currentOrder}
          type={'IncomingOrders'}
          setShouldShowEditOrder={props.setShouldShowEditOrder}
          id={props.id}
          partnerName={currentOrder.partner_name}
          orderType={'Incoming'}
          dropDirection='up'
          isFromIncomingOrderCard={true}
        />
      </>
    );
  };
  const getInitialQuantityOfAProduct = (index) => {
    if (Array.isArray(props?.currentOrder?.order_items)) {
      return props.currentOrder.order_items[index]['quantity'];
    }
  };
  const isMaximumLimitReached = (index, currentQuantity) => {
    if (Array.isArray(props?.currentOrder?.order_items)) {
      return (
        props.currentOrder.order_items[index]['quantity'] <= currentQuantity
      );
    }
    return true;
  };
  const isAllItemsMarkedToZero = () => {
    if (Array.isArray(currentOrder?.order_items)) {
      return currentOrder.order_items.every(
        (orderItem) => orderItem.quantity === 0
      );
    }
    return false;
  };
  const resetOrderItems = () => {
    setCurrentOrder(JSON.parse(JSON.stringify(props?.currentOrder) ?? {}));
  };
  const isProductItemsInInitialState = () => {
    return isBothObjectAreEqual(props?.currentOrder, currentOrder);
  };
  const modifyOrderItemHandler = (action, index) => {
    const flag = 0;
    const currentOrderItems = JSON.parse(
      JSON.stringify(currentOrder?.order_items ?? [])
    );
    switch (action) {
      case 'INC_QUANTITY':
        if (Array.isArray(currentOrderItems)) {
          if (
            isMaximumLimitReached(index, currentOrderItems[index]['quantity'])
          ) {
            return;
          }
          currentOrderItems[index]['quantity'] += 1;
        }
        break;
      case 'DEC_QUANTITY':
        if (currentOrderItems[index] <= 0) {
          return;
        }
        if (Array.isArray(currentOrderItems)) {
          currentOrderItems[index]['quantity'] -= 1;
        }
        break;
      case 'SET_QUANTITY_TO_ZERO':
        if (Array.isArray(currentOrderItems)) {
          currentOrderItems[index]['quantity'] = 0;
        }
        break;
      case 'SET_QUANTITY_TO_MAXIMUM':
        if (Array.isArray(currentOrderItems)) {
          currentOrderItems[index]['quantity'] =
            getInitialQuantityOfAProduct(index);
        }
        break;
      case 'RESET_All_ORDER_ITEMS':
        resetOrderItems();
        return;
      default:
        return;
    }
    if (flag !== 0) {
    }
    setCurrentOrder((prev) => ({
      ...prev,
      order_items: currentOrderItems,
    }));
  };
  const hideEditOrderViewHandler = () => {
    if (!currentOrder?.accepted_at) {
      props.trigerEditOrders(true);
      const id = currentOrder.id;
      let currentIncomingList = JSON.parse(
        JSON.stringify(props?.incomingOrders)
      );
      currentIncomingList = currentIncomingList?.map((order) => {
        if (order.id === id) {
          order.isEditedViewOpen = false;
        }
        return { ...order };
      });
      props.setIncomingOrderList(currentIncomingList);
    } else {
      props.setShouldShowEditOrderViewInOrderDetailsPage(true);
    }
  };
  const partialAcceptHandler = async () => {
    setShouldOpenPartialOrderAccept(false);
    props.setAutoPrintOption(false);
    props.trigerEditOrders(false);
    const currentOrderItems = [];
    if (Array.isArray(currentOrder?.order_items)) {
      currentOrder.order_items.forEach((orderItem, index) => {
        if (getInitialQuantityOfAProduct(index) != orderItem?.quantity) {
          currentOrderItems.push({
            item_id: orderItem?.plu,
            quantity: orderItem?.quantity,
            reason:
              orderItem?.quantity > 0 ? 'PARTIAL_AVAILABILITY' : 'OUT_OF_ITEM',
          });
        }
      });
    } else {
      // Something went wrong. It should be handle properly
      return;
    }
    const payload = {
      id: currentOrder.id,
      status: isAllItemsMarkedToZero() ? 'Rejected' : 'Accepted',
      order_items: currentOrderItems,
    };
    try {
      const updatedOrder = await orderManagementServices.updateEditedOrders(
        payload
      );
      console.log('pant is upadted', updatedOrder);
      if (updatedOrder?.status !== 'SUCCESS') {
        // Something wrong with api.
        console.log('pant is fail');
        hideEditOrderViewHandler();
        return;
      }
      removeOrderFromIncomingOrderNotification(currentOrder?.id);
      const headerMsgPayload = {
        show: true,
        type: 'Incoming',
        msg: 'Not Required',
        orderId: currentOrder.id,
        partnerId: currentOrder?.partner_id?.toUpperCase(),
      };
      const queryString = `id=${currentOrder.id}&status=Accepted`;
      const itemDetails = await props.getSingleItemDetails(queryString);
      if (isAllItemsMarkedToZero()) {
        props.setAutoPrintOption(false);
        headerMsgPayload.type = 'Cancelled';
      } else {
        props.setAutoPrintOption(true);
      }
      props?.setShowTopMsg(headerMsgPayload);
      props.getOrderReportCount();
      props.getIncomingOrders();
      props.getOrderList();
      props.storeOrderDetails(itemDetails.data[0]);
      props.setShouldShowEditOrderViewInOrderDetailsPage(false);
    } catch (error) {
      hideEditOrderViewHandler();
      props.getOrderReportCount();
      props.getIncomingOrders();
      props.getOrderList();
      props.setShouldShowEditOrderViewInOrderDetailsPage(false);
      return;
    }
  };
  const priceAndQuantityCalculator = () => {
    let totalCost = 0;
    let totalItems = 0;
    const currentOrderItems = currentOrder?.order_items;
    if (Array.isArray(currentOrderItems)) {
      currentOrderItems.forEach((product) => {
        totalCost += product?.unit_price * product?.quantity;
        totalItems += product?.quantity;
      });
    }

    return [totalCost, totalItems];
  };
  const [totalPrice, totalItems] = priceAndQuantityCalculator();
  return (
    <>
      <SVTIncomingOrderCardTopView
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        partnerName={partnerName}
        partnerId={partnerId}
        orderReceiveOn={orderReceiveOn}
        totalItems={totalItems}
        orderReceiveOnTime={orderReceiveOnTime}
      />
      {isExpanded && (
        <section>
          <SVTIncomingOrderCardCustomerInfoView
            customerName={customerName}
            customerPhoneNumber={customerPhone}
            customerPhoneCode={customerPhoneCode}
          />
          {currentOrder?.isEditedViewOpen === false ? (
            <SVTIncomingOrderItemsView
              orderItems={orderItems}
              totalPrice={totalPrice}
              totalItems={totalItems}
              partnerName={partnerName}
            />
          ) : (
            <SVTEditOrderViewForIncomingOrderCard
              currentOrderItems={currentOrder?.order_items ?? []}
              totalPrice={totalPrice}
              totalItems={totalItems}
              partnerName={partnerName}
              modifyOrderItemHandler={modifyOrderItemHandler}
              isMaximumLimitReached={isMaximumLimitReached}
            />
          )}
          {currentOrder?.isEditedViewOpen === false ? (
            <SVTFooterForIncomingOrderCard
              partnerName={partnerName}
              isAcceptBtnActive={isAcceptBtnActive}
              orderAcceptHandler={orderAcceptHandler}
              dismissAutoAcceptedOrder={dismissAutoAcceptedOrder}
              viewAutoAcceptedOrderDetails={viewAutoAcceptedOrderDetails}
              getOrderHelpDropDownForIncomingOrderFooter={
                getOrderHelpDropDownForIncomingOrderFooter
              }
            />
          ) : (
            <SVTEditOrderFooter
              isProductItemsInInitialState={isProductItemsInInitialState}
              resetOrderItems={resetOrderItems}
              hideEditOrderViewHandler={hideEditOrderViewHandler}
              setShouldOpenPartialOrderAccept={setShouldOpenPartialOrderAccept}
              isAllItemsMarkedToZero={isAllItemsMarkedToZero}
            />
          )}
        </section>
      )}
      <SVTPrintReceipt details={currentOrder} show={false} />
      <SVTPartialAcceptancePopup
        show={shouldOpenPartialOrderAccept}
        onHide={setShouldOpenPartialOrderAccept}
        partialAcceptHandler={partialAcceptHandler}
        isAllItemsMarkedToZero={isAllItemsMarkedToZero}
        partnerId={partnerId}
      />
    </>
  );
}
function mapState(state) {
  const { orderManagementRelated } = state;
  const {
    storePartiallyAcceptedItems,
    incomingOrderNotifications,
    backupIncomingOrder,
    incomingOrders,
  } = orderManagementRelated;
  return {
    storePartiallyAcceptedItems,
    incomingOrderNotifications,
    backupIncomingOrder,
    incomingOrders,
  };
}
const actionCreators = {
  getIncomingOrders: orderManagementActions.getIncomingOrders,
  dismissLiveOrders: orderManagementActions.dismissLiveOrders,
  updateOrderStatus: orderManagementActions.updateOrderStatus,
  storeOrderDetails: orderManagementActions.storeOrderDetails,
  saveIncomingOrderNewNotification:
    orderManagementActions.saveIncomingOrderNewNotification,
  getOrderReportCount: orderManagementActions.getOrderReportCount,
  setAutoPrintOption: orderManagementActions.setAutoPrintOption,
  showEditedOrders: orderManagementActions.showEditedOrders,
  updateEditedOrders: orderManagementActions.updateEditedOrders,
  setShowTopMsg: orderManagementActions.setShowTopMsg,
  getSingleItemDetails: orderManagementActions.getSingleItemDetails,
  setIncomingOrderList: orderManagementActions.setIncomingOrderList,
  storePartiallyAcceptedItem: orderManagementActions.storePartiallyAcceptedItem,
  getIncomingOrdersBackup: orderManagementActions.getIncomingOrdersBackup,
  getOrderList: orderManagementActions.getOrderList,
  trigerEditOrders: orderManagementActions.trigerEditOrders,
  setShouldShowEditOrderViewInOrderDetailsPage:
    orderManagementActions.setShouldShowEditOrderViewInOrderDetailsPage,
};
export default compose(connect(mapState, actionCreators))(IncomingOrderCard);

// Normal view of order

// function FooterForIncomingOrderCard(props) {
// 	const {
// 		partnerName,
// 		isAcceptBtnActive,
// 		orderAcceptHandler,
// 		dismissAutoAcceptedOrder,
// 		viewAutoAcceptedOrderDetails,
// 		getOrderHelpDropDownForIncomingOrderFooter,
// 	} = props;
// 	const orderAcceptAndViewHandler = () => {
// 		if (
// 			listOfVenderSupportPartialAcceptance.includes(
// 				partnerName.toLowerCase(),
// 			)
// 		) {
// 			orderAcceptHandler();
// 		} else {
// 			viewAutoAcceptedOrderDetails();
// 		}
// 	};
// 	return (
// 		<>
// 			<Container className='footer-in-incoming-order-card'>
// 				<main
// 					className={
// 						listOfVenderSupportPartialAcceptance.includes(
// 							partnerName.toLowerCase(),
// 						)
// 							? 'd-flex flex-sm-row flex-lg-column flex-xl-row justify-content-sm-between justify-content-lg-center justify-content-xl-between'
// 							: 'd-flex justify-content-end'
// 					}
// 				>
// 					{listOfVenderSupportPartialAcceptance.includes(
// 						partnerName.toLowerCase(),
// 					) ? (
// 						<section className='mt-20 d-flex justify-content-lg-center '>
// 							{getOrderHelpDropDownForIncomingOrderFooter()}
// 						</section>
// 					) : (
// 						<section className='mt-20'>
// 							<button
// 								className='success-border-btn me-3'
// 								onClick={dismissAutoAcceptedOrder}
// 							>
// 								Dismiss
// 							</button>
// 						</section>
// 					)}

// 					<section className='mt-20 d-flex justify-content-lg-center'>
// 						<button
// 							className={
// 								isAcceptBtnActive
// 									? 'success-btn'
// 									: 'partially-accept-btn'
// 							}
// 							disabled={isAcceptBtnActive === true ? false : true}
// 							onClick={orderAcceptAndViewHandler}
// 						>
// 							{listOfVenderSupportPartialAcceptance.includes(
// 								partnerName.toLowerCase(),
// 							)
// 								? 'Accept'
// 								: 'View'}
// 						</button>
// 					</section>
// 				</main>
// 			</Container>
// 		</>
// 	);
// }
// function OrderItemsView(props) {
// 	const { orderItems, totalPrice, totalItems, partnerName } = props;

// 	return (
// 		<>
// 			<Container className='oder-items-view-in-incoming-order pt-3'>
// 				<Row>
// 					<Col sm={8} lg={6} xxl={8} className='word-break'>
// 						ITEM NAME
// 					</Col>
// 					<Col sm={2} lg={3} xxl={2} className='word-break'>
// 						QTY
// 					</Col>
// 					<Col
// 						sm={2}
// 						lg={3}
// 						xxl={2}
// 						className='d-flex flex-row-reverse word-break'
// 					>
// 						<section>PRICE</section>
// 					</Col>
// 				</Row>
// 				<Row className='grey-border mt-10 mb-3'></Row>
// 				{orderItems?.map(orderItem => {
// 					return (
// 						<>
// 							<ProductView productItem={orderItem} />
// 							<section className='mt-10 mb-10'>
// 								{orderItem?.children?.map(modifier => {
// 									return (
// 										<>
// 											<ModifierView
// 												modifierItem={modifier}
// 												partnerName={partnerName}
// 											/>
// 											{modifier?.children && modifier.children.map(subModifier=>{
// 											return (
// 												<ModifierView
// 												modifierItem={subModifier}
// 												partnerName={partnerName}
// 											/>
// 											)
// 										})}
// 										</>
// 									);
// 								})}
// 							</section>
// 						</>
// 					);
// 				})}
// 				<TotalSectionOfIncomingOrderCard
// 					totalItems={totalItems}
// 					totalPrice={totalPrice}
// 				/>
// 			</Container>
// 		</>
// 	);
// }

// function ProductView(props) {
// 	const { productItem } = props;
// 	return (
// 		<>
// 			<Row>
// 				<Col
// 					sm={12}
// 					className='font-size-12 letter-spacing-15 text-uppercase dark-shade-gray word-break'
// 				>
// 					{productItem?.catetory}
// 				</Col>
// 				<Col
// 					sm={8}
// 					lg={6}
// 					xxl={8}
// 					className='word-break line-height-24 letter-spacing-O50'
// 				>
// 					<section> {productItem?.name}</section>
// 				</Col>
// 				<Col
// 					sm={2}
// 					lg={3}
// 					xxl={2}
// 					className='word-break font-size-14 letter-spacing-025'
// 				>
// 					x{productItem?.quantity}
// 				</Col>
// 				<Col
// 					sm={2}
// 					lg={3}
// 					xxl={2}
// 					className='d-flex flex-row-reverse font-size-14'
// 				>
// 					<section>
// 						$
// 						{(productItem?.unit_price
// 							? productItem?.unit_price / 100
// 							: productItem?.price / productItem?.quantity / 100
// 						).toFixed(2)}
// 					</section>
// 				</Col>
// 			</Row>
// 		</>
// 	);
// }
// function ModifierView(props) {
// 	const { modifierItem, partnerName } = props;
// 	return (
// 		<>
// 			<Row className='mt-1'>
// 				<Col
// 					sm={8}
// 					lg={6}
// 					xxl={8}
// 					className='word-break line-height-24 letter-spacing-O50'
// 				>
// 					<section className='pl-20'>
// 						{listOfVenderSupportPartialAcceptance.includes(
// 							partnerName.toLowerCase(),
// 						)
// 							? modifierItem?.description
// 							: modifierItem?.name}
// 					</section>
// 				</Col>
// 				<Col
// 					sm={2}
// 					lg={3}
// 					xxl={2}
// 					className='word-break font-size-14 letter-spacing-025'
// 				>
// 					x{modifierItem?.quantity}
// 				</Col>
// 				<Col
// 					sm={2}
// 					lg={3}
// 					xxl={2}
// 					className='d-flex flex-row-reverse  font-size-14 letter-spacing-025'
// 				>
// 					<section>
// 						$
// 						{(modifierItem?.unit_price
// 							? modifierItem?.unit_price / 100
// 							: modifierItem?.price / modifierItem?.quantity / 100
// 						).toFixed(2)}
// 					</section>
// 				</Col>
// 			</Row>
// 		</>
// 	);
// }

// Custom Component for Edit Order Flow

// function EditOrderViewForIncomingOrderCard(props) {
// 	const {
// 		currentOrderItems,
// 		modifyOrderItemHandler,
// 		isMaximumLimitReached,
// 		totalItems,
// 		totalPrice,
// 	} = props;
// 	return (
// 		<>
// 			<Container className='oder-items-view-in-incoming-order pt-3'>
// 				<Row>
// 					<Col sm={1} lg={2} xxl={1}>
// 						AVL.
// 					</Col>
// 					<Col sm={7} lg={4} xxl={7} className='word-break'>
// 						ITEM NAME
// 					</Col>
// 					<Col sm={2} lg={3} xxl={2} className=''>
// 						QTY
// 					</Col>
// 					<Col
// 						sm={2}
// 						lg={3}
// 						xxl={2}
// 						className='d-flex flex-row-reverse'
// 					>
// 						<section>PRICE</section>
// 					</Col>
// 				</Row>
// 				<Row className='grey-border mt-10 mb-3'></Row>
// 				{currentOrderItems?.map((productItem, index) => {
// 					return (
// 						<>
// 							<ProductViewForEditOrder
// 								productItem={productItem}
// 								index={index}
// 								modifyOrderItemHandler={modifyOrderItemHandler}
// 								isMaximumLimitReached={isMaximumLimitReached}
// 							/>
// 							<section className='mt-10 mb-10'>
// 								{productItem?.children?.map(modifier => {
// 									return (
// 										<>
// 											<ModifierForEditOrder
// 												modifierItem={modifier}
// 												isLineThrough = {productItem?.quantity===0}
// 											/>
// 											{modifier?.children?.map(
// 												subModifier => {
// 													return (
// 														<>
// 															<ModifierForEditOrder
// 																modifierItem={
// 																	subModifier
// 																}
// 																isLineThrough = {productItem?.quantity===0}
// 															/>
// 														</>
// 													);
// 												},
// 											)}
// 										</>
// 									);
// 								})}
// 							</section>
// 						</>
// 					);
// 				})}
// 				<SVTTotalSectionOfIncomingOrderCard
// 					totalItems={totalItems}
// 					totalPrice={totalPrice}
// 				/>
// 			</Container>
// 		</>
// 	);
// }

// function EditOrderFooter(props) {
// 	const {
// 		hideEditOrderViewHandler,
// 		setShouldOpenPartialOrderAccept,
// 		resetOrderItems,
// 		isProductItemsInInitialState,
// 		isAllItemsMarkedToZero,
// 	} = props;
// 	return (
// 		<>
// 			<Container className='footer-in-incoming-order-card'>
// 				<main
// 					className={
// 						'd-flex flex-sm-row flex-lg-column flex-xxl-row justify-content-sm-between justify-content-lg-center justify-content-xxl-between'
// 					}
// 				>
// 					<section className='mt-20 d-flex justify-content-lg-center'>
// 						<img
// 							src={closeButtonGreen}
// 							alt='close'
// 							className='cursor-pointer'
// 							width={'44px'}
// 							height={'44px'}
// 							onClick={hideEditOrderViewHandler}
// 						/>
// 					</section>

// 					<section className='mt-20 d-flex flex-row-reverse justify-content-lg-center flex-gap-20 '>
// 						<button
// 							className={
// 								isProductItemsInInitialState()
// 									? 'partially-accept-btn'
// 									: 'partially-accept-btn darkgreen-background-color'
// 							}
// 							disabled={
// 								isProductItemsInInitialState() ? true : false
// 							}
// 							onClick={() =>
// 								setShouldOpenPartialOrderAccept(true)
// 							}
// 						>
// 							{isAllItemsMarkedToZero() ? 'Cancel' : 'P. Accept'}
// 						</button>
// 						{!isProductItemsInInitialState() && (
// 							<button
// 								className={
// 									true
// 										? 'success-border-btn'
// 										: 'partially-accept-btn'
// 								}
// 								onClick={resetOrderItems}
// 							>
// 								Reset
// 							</button>
// 						)}
// 					</section>
// 				</main>
// 			</Container>
// 		</>
// 	);
// }
// function ProductViewForEditOrder(props) {
// 	const {
// 		productItem,
// 		modifyOrderItemHandler,
// 		index,
// 		isMaximumLimitReached,
// 	} = props;
// 	return (
// 		<>
// 			<Row>
// 				<Col
// 					sm={1}
// 					lg={2}
// 					xxl={1}
// 					className='d-flex align-items-center cursor-pointer'
// 				>
// 					{productItem?.quantity > 0 ? (
// 						<img
// 							src={CheckBox}
// 							alt='checkbox'
// 							className='cursor-pointer'
// 							onClick={() =>
// 								modifyOrderItemHandler(
// 									'SET_QUANTITY_TO_ZERO',
// 									index,
// 								)
// 							}
// 						/>
// 					) : (
// 						<img
// 							src={emptyCheckBox}
// 							alt='emptyCheckBox'
// 							className='cursor-pointer'
// 							onClick={() =>
// 								modifyOrderItemHandler(
// 									'SET_QUANTITY_TO_MAXIMUM',
// 									index,
// 								)
// 							}
// 						/>
// 					)}
// 				</Col>
// 				<Col sm={11} lg={10} xxl={11} className='word-break'>
// 					<main className={
// 								productItem?.quantity === 0
// 									? 'px-0 edit-order-line-through' //pt-3'
// 									: 'px-0'// pt-3'
// 							}>
// 						<section className='font-size-12 letter-spacing-15 text-uppercase dark-shade-gray word-break'>
// 							{productItem?.catetory}
// 						</section>
// 						<section className='word-break line-height-24 letter-spacing-O50'>
// 							{productItem?.name}
// 						</section>
// 					</main>
// 				</Col>
// 			</Row>
// 			<Row className='mt-2 mb-3'>
// 				<Col
// 					sm={1}
// 					lg={1}
// 					xxl={1}
// 					className='d-lg-none d-sm-block d-md-block d-xl-none d-xxl-block'
// 				></Col>
// 				<Col sm={9} lg={9} xxl={9}>
// 					<main className='d-flex align-items-center flex-gap-10'>
// 						<section>
// 							{productItem?.quantity > 0 ? (
// 								<img
// 									src={circleMinusGreen}
// 									alt='reduce'
// 									width={'24px'}
// 									height={'24px'}
// 									onClick={() =>
// 										modifyOrderItemHandler(
// 											'DEC_QUANTITY',
// 											index,
// 										)
// 									}
// 									className='cursor-pointer'
// 								/>
// 							) : (
// 								<img
// 									src={circleMinus}
// 									alt='disable'
// 									width={'24px'}
// 									height={'24px'}
// 								/>
// 							)}
// 						</section>

// 						<section
// 							sm={3}
// 							className={
// 								productItem?.quantity === 0
// 									? 'text-decoration-line-through edit-order-view-quantity-for-incoming-order-card'
// 									: 'edit-order-view-quantity-for-incoming-order-card'
// 							}
// 						>
// 							x{productItem?.quantity}
// 						</section>
// 						<section>
// 							{!isMaximumLimitReached(
// 								index,
// 								productItem?.quantity,
// 							) ? (
// 								<img
// 									src={circlePlus}
// 									alt='increase'
// 									width={'24px'}
// 									height={'24px'}
// 									onClick={() =>
// 										modifyOrderItemHandler(
// 											'INC_QUANTITY',
// 											index,
// 										)
// 									}
// 									className='cursor-pointer'
// 								/>
// 							) : (
// 								<img
// 									src={circlePlusGrey}
// 									alt='disable'
// 									width={'24px'}
// 									height={'24px'}
// 								/>
// 							)}
// 						</section>
// 					</main>
// 				</Col>
// 				<Col
// 					sm={2}
// 					lg={3}
// 					xxl={2}
// 					className='d-flex flex-row-reverse font-size-14 align-items-center'
// 				>
// 					<section
// 						className={
// 							productItem?.quantity === 0
// 								? 'text-decoration-line-through'
// 								: ''
// 						}
// 					>
// 						$
// 						{(productItem?.unit_price
// 							? productItem?.unit_price / 100
// 							: productItem?.price / productItem?.quantity / 100
// 						).toFixed(2)}
// 					</section>
// 				</Col>
// 			</Row>
// 		</>
// 	);
// }
// function ModifierForEditOrder(props) {
// 	const { modifierItem, isLineThrough } = props;
// 	return (
// 		<>
// 			<Row className='mt-2 mb-3' style={{ paddingLeft: '45px' }}>
// 				<Col
// 					sm={1}
// 					lg={1}
// 					xxl={1}
// 					className='d-lg-none d-sm-block d-md-block d-xl-none d-xxl-block'
// 				></Col>
// 				<Col sm={11} lg={11} xxl={11} className={isLineThrough? 'word-break text-decoration-line-through':'word-break'}>
// 					{modifierItem?.description}
// 				</Col>
// 			</Row>
// 		</>
// 	);
// }

// Common Component
// function IncomingOrderCardTopView(props) {
// 	const {
// 		isExpanded,
// 		setIsExpanded,
// 		partnerName = '',
// 		partnerId,
// 		totalItems,
// 		orderReceiveOnTime,
// 	} = props;
// 	return (
// 		<>
// 			<Container style={{ backgroundColor: '#EBEBEB' }} className='pb-20 cursor-pointer' onClick={() => setIsExpanded(!isExpanded)}>
// 				<Row>
// 					<Col sm={1} className='mt-20'>
// 						<img
// 							src={
// 								 imageList[partnerName.toLowerCase()]
// 							}
// 							alt='Aggregator Logo'
// 							width={'34px'}
// 							height={'34px'}
// 						/>
// 					</Col>
// 					<Col sm={10} className='mt-20'>
// 						<main className='ml-12'>
// 							<section className='id_style'>#{partnerId}</section>
// 							<section className='small_text gray_text'>
// 								{listOfVenderSupportPartialAcceptance.includes(
// 									partnerName.toLowerCase(),
// 								) ? null : (
// 									<span>Auto-Accepted at </span>
// 								)}
// 								{orderReceiveOnTime}
// 								{listOfVenderSupportPartialAcceptance.includes(
// 									partnerName.toLowerCase()) ? <span>&nbsp;<SVTOrderCountDown date={orderReceiveOnTime} /></span> : null}
// 							</section>

// 							<section className='total-item-count-in-incoming-order-card mt-2'>
// 								{totalItems} {totalItems > 1 ? 'items' : 'item'}
// 							</section>
// 						</main>
// 					</Col>
// 					<Col sm={1} className='mt-20'>
// 						<img
// 							src={isExpanded ? expandArrow : collapseArrow}
// 							alt='Expand Arrow'
// 							width={'24px'}
// 							height={'24px'}
// 							className='cursor-pointer'
// 						/>
// 					</Col>
// 				</Row>
// 			</Container>
// 		</>
// 	);
// }

// function IncomingOrderCardCustomerInfoView(props) {
// 	const { customerName, customerPhoneNumber, customerPhoneCode } = props;
// 	return (
// 		<>
// 			<Container className='customer-info-in-incoming-order-card'>
// 				<Row>
// 					<Col
// 						sm={3}
// 						lg={4}
// 						xl={3}
// 						className='font-size-14 letter-spacing-O25 word-break'
// 					>
// 						{customerName}
// 					</Col>
// 					<Col
// 						sm={5}
// 						lg={8}
// 						xl={5}
// 						className='d-flex align-items-center'
// 					>
// 						<main className='d-flex flex-gap-10'>
// 							<section>
// 								<img
// 									src={phoneIcon}
// 									alt='Phone Icon'
// 									width={'24px'}
// 									height={'24px'}
// 								/>
// 							</section>
// 							<section className='font-size-12 letter-spacing-O50'>
// 								{customerPhoneNumber}
// 							</section>
// 						</main>
// 					</Col>
// 					<Col
// 						sm={4}
// 						lg={12}
// 						xl={4}
// 						className='d-flex flex-lg-row-reverse align-items-center flex-xl-row-reverse flex-sm-row-reverse '
// 					>
// 						<main className='d-flex'>
// 							<section className='customer-phone-code-text'>
// 								Code:&nbsp;
// 							</section>
// 							<section className='customer-phone-code-number'>
// 								{customerPhoneCode}
// 							</section>
// 						</main>
// 					</Col>
// 				</Row>
// 			</Container>
// 		</>
// 	);
// }

// function TotalSectionOfIncomingOrderCard(props) {
// 	const { totalItems, totalPrice } = props;
// 	return (
// 		<>
// 			<Container className="p-0">
// 				<Row className='grey-border mt-10 mb-3'></Row>
// 				<Row className='my-3'>
// 					<Col sm={8} lg={6} xxl={8} className='word-break'>
// 						<section
// 							className='font-size-14 letter-spacing-025'
// 							style={{ paddingLeft: '30%' }}
// 						>
// 							Total
// 						</section>
// 					</Col>
// 					<Col
// 						sm={2}
// 						lg={3}
// 						xxl={2}
// 						className='word-break font-size-14 letter-spacing-025'
// 					>
// 						{' '}
// 						x{totalItems}
// 					</Col>
// 					<Col
// 						sm={2}
// 						lg={3}
// 						xxl={2}
// 						className='d-flex flex-row-reverse'
// 					>
// 						<section className='font-size-14 letter-spacing-025'>
// 							${(totalPrice / 100).toFixed(2)}
// 						</section>
// 					</Col>
// 				</Row>
// 			</Container>
// 		</>
// 	);
// }
// function PartialAcceptancePopup(props) {
// 	const {
// 		show,
// 		onHide,
// 		isAllItemsMarkedToZero,
// 		partialAcceptHandler,
// 		partnerId,
// 	} = props;
// 	return (
// 		<Modal
// 			show={show}
// 			onHide={onHide}
// 			size='md'
// 			aria-labelledby='contained-modal-title-vcenter'
// 			centered
// 		>
// 			<Modal.Header>
// 				<Modal.Title id='contained-modal-title-vcenter'>
// 					<div className='d-flex'>
// 						<p className='m-0 p-0 normal-font fw-bold'>
// 							{isAllItemsMarkedToZero()
// 								? 'Cancel Order'
// 								: 'Confirm Changes'}
// 						</p>
// 					</div>
// 				</Modal.Title>
// 				<img
// 					src={cross_icon}
// 					height='21.98px'
// 					width='21.98px'
// 					onClick={() => onHide(false)}
// 					alt='cross icon'
// 					className='cursor-pointer'
// 				/>
// 			</Modal.Header>
// 			<Modal.Body className='p-0 m-0 ps-3 pe-3'>
// 				<Row>
// 					<Col sm={12} className='m-0 pb-3'>
// 						<hr className='m-0 contact-supp-hr' />
// 					</Col>
// 					<Col md={12}>
// 						{isAllItemsMarkedToZero() && (
// 							<span>
// 								{' '}
// 								Are you sure you want to cancel order{' '}
// 								<strong>#{partnerId?.toUpperCase()} </strong>?
// 							</span>
// 						)}
// 						{!isAllItemsMarkedToZero() && (
// 							<span>
// 								{' '}
// 								Are you sure you want to save the changes made
// 								to order{' '}
// 								<strong>#{partnerId?.toUpperCase()} </strong>?
// 							</span>
// 						)}
// 					</Col>
// 				</Row>
// 			</Modal.Body>
// 			<Modal.Footer>
// 				<button
// 					type='button'
// 					className='green-outline-btn shadow-none'
// 					onClick={() => onHide(false)}
// 				>
// 					Cancel
// 				</button>
// 				<button
// 					type='button'
// 					className={' shadow-none ms-3 success-btn-modal'}
// 					onClick={partialAcceptHandler}
// 				>
// 					{isAllItemsMarkedToZero() ? 'Cancel Order' : 'P. Accept'}
// 				</button>
// 			</Modal.Footer>
// 		</Modal>
// 	);
// }

// Utility Functions
function isBothObjectAreEqual(obj1 = {}, obj2 = {}) {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}
