import { constants } from '../constant/reducerConstant';

const initialState = {
	categoriesList: [],
	menuSearchResults: [],
	showMenuPopUpModifier: false,
	productList: [],
	productName: [],
	productStatus: null,
	searchQuery: null,
	currentActiveCategory: 'marked-as-unavailable',
	unavailableItemsCount: 0,
	totalUnavailableItemList: [],
	totalUnavailableCount: 0,
	selectedMenuModifier: [],
	selectedMenuSubModifier: [],
	currentActiveProduct: {
		productKey: '',
		productName: '',
		isProductAvailable: false,
		shouldShow: false,
	},
	currentActiveModifier: {
		skuCode: '',
		modifierName: '',
		isModifierAvailable: false,
		shouldShow: false,
		numberOfProductsHavingThisModifier: 1,
	},
	modifierProductKey: '',
	modifierLevel2ProductKey: '',
	optionName: '',
	optionLevel2Name: '',
	totalProductsCount: 0,
	totalModifiersCount: 0,
	allProductsList: [],
	allUnavailableProductsList: [],
	allModifiersList: [],
	allProductsListFromSearchQuery: [],
	allModifiersListFromSearchQuery: [],
	showLoaderForProducts: {},
	showLoaderForModifiers: {},
	itemsListHavingError:[],
	itemHavingErrorPopupState:{
		shouldShow:false,
		itemName:''
	}

};
export function menuManagementRelated(state = initialState, action) {
	switch (action.type) {
		case constants.GET_CATEGORIES_LIST_SUCCESS:
			return { ...state, categoriesList: action.data.data };
		case constants.GET_CATEGORIES_LIST_FAIL:
			return { ...state, categoriesList: [] };
		case constants.GET_PRODUCT_LIST_SUCCESS:
			return {
				...state,
				productList: action.data,
			};
		case constants.GET_PRODUCT_LIST_FAIL:
			return {
				...state,
				productList: [],
			};
		case constants.SET_ALL_PRODUCTS_LIST_FROM_SEARCH_QUERY:
			return {
				...state,
				allProductsListFromSearchQuery: action.data,
			};
		case constants.SET_ALL_MODIFIERS_LIST_FROM_SEARCH_QUERY:
			return {
				...state,
				allModifiersListFromSearchQuery: action.data,
			};
		case constants.GET_PRODUCT_NAME_FAIL:
			return {
				...state,
				productList: [],
			};
		case constants.UPDATE_PRODUCT_STATUS_SUCCESS:
			return {
				...state,
				productStatus: action.data?.data,
			};
		case constants.UPDATE_PRODUCT_STATUS_FAIL:
			return {
				...state,
				productStatus: null,
			};
		case constants.STORE_SEARCH_QUERY:
			return {
				...state,
				searchQuery: action.data,
			};
		case constants.SET_CURRENT_ACTIVE_CATEGORY:
			return {
				...state,
				currentActiveCategory: action.data,
			};
		case constants.SET_UNAVAILABLE_COUNT:
			return {
				...state,
				unavailableItemsCount: action.data,
			};
		case constants.GET_TOTAL_UNAVAILABLE_COUNT_SUCCESS:
			return {
				...state,
				totalUnavailableCount: action.data?.count ?? 0,
				totalProductsCount: action.data?.all_products_count ?? 0,
				totalModifiersCount: action.data?.all_modifier_count ?? 0,
			};
		case constants.GET_TOTAL_UNAVAILABLE_COUNT_FAIL:
			return {
				...state,
				totalUnavailableCount: 0,
			};
		case constants.GET_TOTAL_UNAVAILABLE_ITEM_LIST_SUCCESS:
			return {
				...state,
				allUnavailableProductsList: action.data,
			};
		case constants.GET_TOTAL_UNAVAILABLE_ITEM_LIST_FAIL:
			return {
				...state,
				productList: [],
			};
		case constants.SET_MENU_MODIFIERS:
			return {
				...state,
				selectedMenuModifier: action.data,
			};
		case constants.SET_MENU_SUB_MODIFIERS:
			return {
				...state,
				selectedMenuSubModifier: action.data,
			};
		case constants.SET_CURRENT_ACTIVE_PRODUCT:
			return {
				...state,
				currentActiveProduct: action.payload,
			};
		case constants.SET_CURRENT_ACTIVE_MODIFIER:
			return {
				...state,
				currentActiveModifier: action.data,
			};
		case constants.SET_MODIFIER_PRODUCT_KEY:
			return {
				...state,
				modifierProductKey: action.data,
			};
		case constants.SET_MODIFIER_LEVEL2_PRODUCT_KEY:
			return {
				...state,
				modifierLevel2ProductKey: action.data,
			};
		case constants.SET_OPTION_NAME:
			return {
				...state,
				optionName: action.data,
			};
		case constants.SET_OPTION_LEVEL2_NAME:
			return {
				...state,
				optionLevel2Name: action.data,
			};
		case constants.CLEAR_ALL_MENU_SELECTIONS:
			return initialState;
		case constants.SET_ALL_PRODUCTS_LIST:
			return {
				...state,
				allProductsList: action.data,
			};
		case constants.SET_ALL_MODIFIERS_LIST:
			return {
				...state,
				allModifiersList: action.data,
			};
		case constants.SHOW_LOADER_FOR_PRODUCTS:
			if (action.add) {
				return {
					...state,
					showLoaderForProducts: {
						...state.showLoaderForProducts,
						[action.data]: 1,
					},
				};
			} else {
				delete state.showLoaderForProducts[action.data];
				const tmpShowLoaderForProducts = JSON.parse(JSON.stringify(state.showLoaderForProducts))
				return {
					...state,
					showLoaderForProducts: tmpShowLoaderForProducts,
				};
			}
		case constants.SHOW_LOADER_FOR_MODIFIERS:
			if (action.add) {
				return {
					...state,
					showLoaderForModifiers: {
						...state.showLoaderForModifiers,
						[action.data]: new Date(),
					},
				};
			} else {
				delete state.showLoaderForModifiers[action.data];
				const tmpShowLoaderForModifiers = JSON.parse(JSON.stringify(state.showLoaderForModifiers))
				return {
					...state,
					showLoaderForModifiers: tmpShowLoaderForModifiers,
				};
			}
		case constants.ADD_ITEM_IN_LIST_FOR_WHICH_API_IS_FAILING:
			return {...state,itemsListHavingError:[...state.itemsListHavingError,action.data]}
		case constants.SET_ITEMS_LIST_FOR_WHICH_API_IS_FAILING:
			return {...state,itemsListHavingError:action.data}
		case constants.SET_SHOW_LOADER_FOR_MODIFIERS_OBJ:
			return { ...state, showLoaderForModifiers: action.data };
		case constants.SET_ITEM_HAVING_ERROR_POPUP_STATE:
			return {...state,itemHavingErrorPopupState:action.data}
		default:
			return state;
	}
}
