import { Card, Button } from 'react-bootstrap';

const alphabets = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']

function SVTAlphabetList(props) {

    const { handleAlphabetClick, enabledLetters, activeLetter } = props

    return (
        <Card className='alphabet-card' data-test="alphabetCard">
            <Card.Body className='alphabet-card-body'>
                {alphabets.map((alphabet,index) => <Button data-test={`alphabetButton${index}`} key={alphabet} disabled={!enabledLetters.includes(alphabet)} className={(alphabet === activeLetter)
                    ? 'alphabet-button alphabet-button-active'
                    : (!enabledLetters.includes(alphabet)) ? 'alphabet-button disabled-alphabet' : 'alphabet-button'} onClick={() => handleAlphabetClick(alphabet)}>{alphabet}</Button>)}
            </Card.Body>
        </Card>
    )
}

export default SVTAlphabetList