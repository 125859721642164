
function SVTNoOrderScreen({path}){
    return(
        path=='order-list'?
        <div className='text-center no-order-dark-text' style={{position:'relative',top:'48px',wordWrap:'break-word'}}>
            <div className='no-order-gray-text' style={{marginTop:'10px'}}>No live orders.</div>
        </div>
        :
        <div className='text-center no-order-dark-text'>
            No orders yet :)
                <br/>
            <div className='no-order-gray-text' style={{marginTop:'10px'}}>New orders will be <br/>automatically added.</div>
        </div>

    );
}

export default SVTNoOrderScreen;