import React, { Component } from 'react';
import { orderManagementActions } from '../action/SVTOrderManagementAction';
import { compose } from 'redux';
import { connect } from 'react-redux';
import SVTLiveOrderComponents from './aggregators/SVTLiveOrderComponents';
import SVTHeaderComponent from './aggregators/SVTHeaderComponent';
import SVTMainComponent from '../components/aggregators/SVTMainComponent';
class SVTLandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: null,
    };
  }

  render() {
    if (
      this.props?.orderManagementRelated?.deviceTokenDetails !== undefined &&
      this.props?.orderManagementRelated?.deviceTokenDetails !== [] &&
      this.props?.authInfo?.accessToken &&
      this.props?.orderManagementRelated?.storeDeviceToken?.device_token &&
      this.props?.errorPageRelated?.showErrorPage === false
    ) {
      return (
        <>
          <SVTHeaderComponent />
          <div className='container-fluid parent_block'>
            <SVTLiveOrderComponents />
          </div>
        </>
      );
    } else if (
      sessionStorage.getItem('accessToken') &&
      this.props?.orderManagementRelated.deviceTokenDetails === undefined
    ) {
      return <h1>Loading...</h1>;
    }
    return <SVTMainComponent />;
  }
}
function mapState(state) {
  const { orderManagementRelated, authInfo, errorPageRelated } = state;
  return { orderManagementRelated, authInfo, errorPageRelated };
}
const actionCreators = {
  addDeviceToken: orderManagementActions.addDeviceToken,
  getLoginUserDetails: orderManagementActions.getLoginUserDetails,
  storeAccessToken: orderManagementActions.storeAccessToken,
  getOrderReportCount: orderManagementActions.getOrderReportCount,
  storeDeviceToken: orderManagementActions.storeDeviceToken,
};

export default compose(connect(mapState, actionCreators))(SVTLandingPage);
