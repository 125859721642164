import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import crossIcon from '../../images/cross_icon.svg';
import riskIcon from '../../images/Risk.svg';
import { orderManagementActions } from '../../action/SVTOrderManagementAction';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

function ErrorPopupForUpdateItemAPIFailure(props) {
	const { show, onHide, showTopMsg, productName = 'Product Name' } = props;

	React.useEffect(() => {
		if (showTopMsg?.type === 'modifierBgcUpdating' && show) {
			const headerMsgPayload = {
				show: false,
				type: '',
				msg: '',
				orderId: '',
				partnerId: '',
			};
			props.setShowTopMsg(headerMsgPayload)
		}
	}, [show]);
	return (
		<>
			<Modal
				show={show}
				onHide={onHide}
				centered
				dialogClassName='offline-popup'
				data-test="errorAPIPopUp"
			>
				<Modal.Header>
					<Modal.Title className='w-100'>
						<div
							className='d-flex justify-content-between'
							data-test='offlinePopupTitle'
						>
							<p className='font-size-16 fw-bold letter-spacing-O50 m-0 p-0 me-2'>
								{'Status Update Failure'}
							</p>
							<img
								src={crossIcon}
								alt='close Icon'
								className='cursor-pointer'
								onClick={onHide}
							/>
						</div>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className='p-0 m-0 ps-3 pe-3'>
					<Row>
						<Col sm={12} className='m-0 pb-3 pl-20'>
							<hr className='m-0 contact-supp-hr' />
						</Col>
						<Col sm={2}>
							<img
								src={riskIcon}
								alt='Risk Icon'
								width={'63.68px'}
								height={'56.08px'}
							/>
						</Col>
						<Col sm={10}>
							<div className='letter-spacing-O50 mb-2'>
								<span>
									We were unable to update{' '}
									<b className='text-capitalize'>
										{productName}
									</b>
								</span>
							</div>
							<div className='letter-spacing-O50 dark-shade-gray'>
								Due to some technical error, updating the status
								for this item is not possible. We’re working on
								getting this fixed.
							</div>
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer>
					<button className='success-btn-modal' onClick={onHide}>
						{'Okay'}
					</button>
				</Modal.Footer>
			</Modal>
		</>
	);
}

export function mapState(state) {
	const { orderManagementRelated } = state;
	const { showTopMsg } =
		orderManagementRelated;
	return {
		showTopMsg
	};
}

const actionCreators = {
	setShowTopMsg: orderManagementActions.setShowTopMsg
};

export default compose(
	withRouter,
	connect(mapState, actionCreators),
)(ErrorPopupForUpdateItemAPIFailure);