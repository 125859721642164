import React, { useEffect, useState } from 'react';
import SVTAccordionTabComponent from '../common/SVTAccordionTabComponent';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { orderManagementActions } from '../../action/SVTOrderManagementAction';
import SVTIncomingOrderNewView from './SVTIncomingOrderNewView';

function SVTIncomingOrderComponent(props) {
  const [shouldShowEditOrder, setShouldShowEditOrder] = useState({});
  const [foodQuantity, setFoodQuantity] = useState({});
  useEffect(() => {
    props.getIncomingOrdersBackup();
    props.getIncomingOrders();
  }, []);
  useEffect(() => {
    if (props.incomingOrders) {
      const tmp = {};
      for (let index = 0; index < props.incomingOrders.length; index++) {
        const orderId = props.incomingOrders[index]['id'];
        tmp[orderId] = false;
      }
      setShouldShowEditOrder(tmp);
      const incomingOrdersList = props.incomingOrders;
      const currentIncomingNotificationsList = [];
      if (incomingOrdersList) {
        incomingOrdersList.forEach((order) => {
          const tmpNotification = {
            id: order.id,
            body: 'This notification  is not come from firebase',
          };
          currentIncomingNotificationsList.push(tmpNotification);
        });
      }
      props.saveIncomingOrderNewNotification(currentIncomingNotificationsList);
    }
  }, [props?.incomingOrders]);

  useEffect(() => {
    if (props.incomingOrders) {
      const tmp = {};
      for (let i = 0; i < props.incomingOrders.length; i++) {
        const id = props.incomingOrders[i]['id'];
        tmp[id] = props.incomingOrders[i]['order_items'];
      }
      setFoodQuantity(tmp);
    }
  }, [props?.incomingOrders]);
  const reset = (currentId) => {
    props.getIncomingOrdersBackup();
    const tmpArray = [...props.backupIncomingOrder];
    const tmp = {};
    let res;
    for (let i = 0; i < tmpArray.length; i++) {
      const id = tmpArray[i]['id'];
      const orderItem = tmpArray[i]['order_items'];
      const order = tmpArray[i];
      tmp[id] = [...orderItem];
      if (id === currentId) {
        res = { ...order };
      }
    }
    setFoodQuantity(tmp);
    return res;
  };
  const resetById = (id, currentList) => {
    props.getIncomingOrdersBackup();
    const tmpArray = [...props.backupIncomingOrder];
    const tmp = {};
    for (let i = 0; i < tmpArray.length; i++) {
      const orderItem = tmpArray[i]['order_items'];
      const id = tmpArray[i]['id'];
      tmp[id] = [...orderItem];
    }
    const currentOrderList = { ...currentList };
    currentOrderList[id] = tmp[id];
    setFoodQuantity(currentOrderList);
  };
  return (
    <div className='incoming-order-accordion-margin fixed_block'>
      {props?.incomingOrders?.map((order, index) => {
        return (
          <SVTAccordionTabComponent
            key={order.id}
            //----- Start  Redesign edit order Flow
            OrderDetails={SVTIncomingOrderNewView}
            // OrderDetails={SVTIncomingOrderDetailsComponent}
            //----- end  Redesign edit order Flow
            order={order}
            index={index}
            setShouldShowEditOrder={setShouldShowEditOrder}
            shouldShowEditOrder={shouldShowEditOrder}
            id={order.id}
            foodQuantity={foodQuantity}
            reset={reset}
            resetById={resetById}
          />
        );
      })}
    </div>
  );
}

export function mapState(state) {
  const { orderManagementRelated } = state;
  const { incomingOrders, backupIncomingOrder } = orderManagementRelated;
  return { incomingOrders, backupIncomingOrder };
}
const actionCreators = {
  getIncomingOrders: orderManagementActions.getIncomingOrders,
  getIncomingOrdersBackup: orderManagementActions.getIncomingOrdersBackup,
  saveIncomingOrderNewNotification:
    orderManagementActions.saveIncomingOrderNewNotification,
};

export default compose(
  withRouter,
  connect(mapState, actionCreators)
)(SVTIncomingOrderComponent);
