import React, { Component } from 'react';
import uberEats from '../../images/uberEats.png';
import menuLog from '../../images/menuLog.png';
import search from '../../images/search.png';
import Xcross from '../../images/cross_mark.svg';
import cross_mark from '../../images/chip_cross_mark.svg';
import SVTOrderHistorySummaryComponent from './SVTOrderHistorySummaryComponent';
import SVTOrderHistoryFilterPopup from './SVTOrderHistoryFilterPopup';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { orderManagementActions } from '../../action/SVTOrderManagementAction';
import { ListGroup, Button } from 'react-bootstrap';
import { sortingConstants } from '../../constant/sortingConstant';
import SVTHeaderComponent from './SVTHeaderComponent';
import chevronUp from '../../images/up.svg';
import chevronDown from '../../images/down.svg';
import SVTMainComponent from './SVTMainComponent';
import doordash from '../../images/Doordash.svg';
class SVTOrderHistoryComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalShow: false,
      showCross: false,
      selectedFilter: {},
      historySearchInput: undefined,
      query: '',
      viewport: {
        currentHeight: 0,
        currentWidth: 0,
      },

      ORDER_ID: true,
      PARTNER: true,
      DATE: true,
      ITEMS: true,
      STATUS: true,
    };
  }
  componentDidMount() {
    this.props.getOrderHistoryList();
    this.props.setCurrentPath(this.props.location.pathname);
    // This is for resetting the filter option while doing browser refresh
    this.setState({
      selectedFilter: {},
      historySearchInput: '',
      showCross: false,
    });
    this.props.filterOrderHistory([], true);
  }
  componentWillUnmount() {
    this.setState({
      selectedFilter: {},
      historySearchInput: '',
      showCross: false,
    });
    this.props.filterOrderHistory([], true);
  }
  filterDataInAscendingOrder = (sortBy) => {
    this.props?.sortOrderHistoryList(
      this.props?.orderHistoryList,
      sortBy,
      sortingConstants.ASCENDING
    );
  };

  filterDataInDescendingOrder = (sortBy) => {
    this.props?.sortOrderHistoryList(
      this.props?.orderHistoryList,
      sortBy,
      sortingConstants.DESCENDING
    );
  };

  // filterOnText = sortBy => {
  // 	if (this.state[sortBy] == true) {
  // 		this.filterDataInDescendingOrder(sortBy);
  // 	} else {
  // 		this.filterDataInAscendingOrder(sortBy);
  // 	}
  // };

  setModalShow = (showPopUp) => {
    this.setState({ modalShow: showPopUp });
  };
  // addPmOrAm = data => {
  // 	const val = data !== undefined ? data.split(':')[0] : data;
  // 	if (val >= 12) {
  // 		return data + ' PM';
  // 	} else if (val < 12) {
  // 		return data + ' AM';
  // 	} else {
  // 		return data;
  // 	}
  // };
  formatDateToDateMonth = (newDate) => {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sept',
      'Oct',
      'Nov',
      'Dec',
    ];
    const dateDate = new Date(newDate);
    const dateVal = dateDate?.getDate().toString().padStart(2, '0');
    const month = months[dateDate.getMonth()];
    const createdTime = dateVal + ' ' + month;
    return createdTime;
  };
  showHistoryDetails = (order) => {
    this.props.storeOrderDetails(order);
    this.props.storeHistoryOrderDetails(order);
    this.props.getPartnersList();
    this.props.history.push('/order-history-details');
  };
  searchForOrder = (event) => {
    const queryId = event.target.value?.toLowerCase();
    this.setState({
      query: queryId,
    });
    if (queryId.startsWith('#') && queryId.length > 3) {
      this.props.searchForOrder(
        queryId.substring(1),
        this.state.selectedFilter
      );
      this.setState({
        showCross: true,
        historySearchInput: queryId,
      });
    } else if (!queryId.startsWith('#') && queryId.length > 2) {
      this.props.searchForOrder(queryId, this.state.selectedFilter);
      this.setState({
        showCross: true,
        historySearchInput: queryId,
      });
    } else if (queryId.length === 0) {
      this.props.searchForOrder(queryId, this.state.selectedFilter);
      this.setState({
        showCross: false,
        historySearchInput: queryId,
      });
    } else {
      this.setState({
        showCross: false,
        historySearchInput: queryId,
      });
    }
  };
  onClosePress = () => {
    this.setState({
      showCross: false,
      historySearchInput: '',
    });
    this.props.searchForOrder('', this.state.selectedFilter);
  };
  checkboxSubmitHandler = (data) => {
    this.setState({
      selectedFilter: data,
      historySearchInput: '',
      showCross: false,
    });
    this.props.filterOrderHistory(data);
  };
  dismissFilterBtn = (id) => {
    if (id === 'custom') {
      const updatedTmpFilterList = [];
      for (
        let index = 0;
        index < this.props.orderHistoryFilter.length;
        index++
      ) {
        if (!this.props.orderHistoryFilter[index].custom) {
          updatedTmpFilterList.push(this.props.orderHistoryFilter[index]);
        }
      }
      this.setState({
        selectedFilter: updatedTmpFilterList,
        historySearchInput: '',
        showCross: false,
      });
      this.props.filterOrderHistory(updatedTmpFilterList);
    } else {
      let tmpFilterList = this.props.orderHistoryFilter.slice();
      if (tmpFilterList.includes(id)) {
        tmpFilterList = tmpFilterList.filter((item) => item !== id);
      }
      this.setState({
        selectedFilter: tmpFilterList,
        historySearchInput: '',
        showCross: false,
      });
      this.props.filterOrderHistory(tmpFilterList);
    }
  };
  areTwoArrayEqual = (arrOne, arrTwo) => {
    const result =
      arrOne.length === arrTwo.length &&
      arrOne.every(function (element) {
        return arrTwo.includes(element);
      });
    return result;
  };
  defaultFilterChecker = () => {
    const defaultSelected = [
      'uberEats',
      'menuLog',
      'doordash',
      'completed',
      'cancelledAggregator',
      'lastSevenDays',
    ];
    return this.areTwoArrayEqual(
      defaultSelected,
      this.props?.orderHistoryFilter
    );
  };
  defaultPartnerFilterChecker = () => {
    if (
      this.props?.orderHistoryFilter?.includes('uberEats') &&
      this.props?.orderHistoryFilter?.includes('menuLog') &&
      this.props?.orderHistoryFilter?.includes('doordash')
    ) {
      return true;
    }
    if (
      !this.props?.orderHistoryFilter?.includes('uberEats') &&
      !this.props?.orderHistoryFilter?.includes('menuLog') &&
      !this.props?.orderHistoryFilter?.includes('doordash')
    ) {
      return true;
    }
    return false;
  };
  defaultStatusFilterChecker = () => {
    if (
      this.props?.orderHistoryFilter?.includes('completed') &&
      this.props?.orderHistoryFilter?.includes('cancelledAggregator')
    ) {
      return true;
    }
    if (
      !this.props?.orderHistoryFilter?.includes('completed') &&
      !this.props?.orderHistoryFilter?.includes('cancelledAggregator')
    ) {
      return true;
    }
    return false;
  };
  isCustom = () => {
    if (this.props.orderHistoryFilter) {
      for (
        let index = 0;
        index < this.props.orderHistoryFilter.length;
        index++
      ) {
        if (this.props.orderHistoryFilter[index]['custom']) {
          return [true, this.props.orderHistoryFilter[index]['custom']];
        }
      }
    }
    return [false];
  };
  showTimeline = () => {
    if (this.props.orderHistoryFilter) {
      if (this.props.orderHistoryFilter.includes('today')) {
        return 'Today';
      }
    }
    const tmp = this.isCustom();
    if (tmp[0] === true) {
      const customDate = tmp[1].split(',');
      let firstDate = customDate[0].split('-');
      firstDate = firstDate[0] + ' - ' + firstDate[1];
      let secondDate = customDate[1].split('-');
      secondDate = secondDate[0] + ' - ' + secondDate[1];
      return `${firstDate} to ${secondDate}`;
    }
    if (
      this.state.historySearchInput !== undefined &&
      this.state.historySearchInput !== ''
    ) {
      return null;
    }
    return 'Last 7 Days';
  };

  render() {
    const imageObj = {
      'Uber Eats': uberEats,
      Menulog: menuLog,
      Doordash: doordash,
    };
    let orderHistoryFilterArray = this.props?.orderHistoryFilter || [];
    if (this.defaultFilterChecker()) {
      orderHistoryFilterArray = [];
    } else {
      if (this.defaultPartnerFilterChecker()) {
        orderHistoryFilterArray = orderHistoryFilterArray.filter(
          (item) => item !== 'menuLog'
        );
        orderHistoryFilterArray = orderHistoryFilterArray.filter(
          (item) => item !== 'uberEats'
        );
        orderHistoryFilterArray = orderHistoryFilterArray.filter(
          (item) => item !== 'doordash'
        );
      }
      if (this.defaultStatusFilterChecker()) {
        orderHistoryFilterArray = orderHistoryFilterArray.filter(
          (item) => item !== 'completed'
        );
        orderHistoryFilterArray = orderHistoryFilterArray.filter(
          (item) => item !== 'cancelledAggregator'
        );
      }
      if (orderHistoryFilterArray.includes('lastSevenDays')) {
        orderHistoryFilterArray = orderHistoryFilterArray.filter(
          (item) => item !== 'lastSevenDays'
        );
      }
    }
    const filterList = orderHistoryFilterArray.map((item, index) => {
      if (item.custom) {
        const tmpDate = item.custom.split(',');
        const fromDate = tmpDate[0];
        const toDate = tmpDate[1];
        let firstDate = fromDate.split('-');
        firstDate = firstDate[0] + ' - ' + firstDate[1];
        let secondDate = toDate.split('-');
        secondDate = secondDate[0] + ' - ' + secondDate[1];
        return (
          <div
            className='pe-3'
            key={item.custom}
            onClick={() => this.dismissFilterBtn('custom')}
            data-test='filterRoundedCustomBtn'
          >
            <button class='round-button' data-test='btn'>
              <span id='but_ride' className='pt-1'>
                {firstDate} {'to'} {secondDate}&nbsp; &nbsp;
              </span>
              <span className='pe-2 mt-1 mb-1'>
                <img src={cross_mark} alt='close'></img>
              </span>
            </button>
          </div>
        );
      }
      return (
        <div className='pe-3' key={item}>
          {item === 'cancelledAggregator' && (
            <button
              class='round-button'
              onClick={() => this.dismissFilterBtn(item)}
              data-test={`filterRoundedBtn${index}`}
            >
              <span id='but_ride' className='pt-1'>
                {'Cancelled'}&nbsp; &nbsp;
              </span>
              <span className='pe-2 mt-1 mb-1'>
                <img src={cross_mark} alt='close'></img>
              </span>
            </button>
          )}
          {item !== 'cancelledAggregator' && (
            <button
              class='round-button'
              onClick={() => this.dismissFilterBtn(item)}
              data-test={`filterRoundedBtn${index}`}
            >
              <span id='but_ride' className='pt-1'>
                {item.split(/(?=[A-Z])/).join(' ')}&nbsp; &nbsp;
              </span>
              <span className='pe-2 mt-1 mb-1'>
                <img src={cross_mark} alt='close'></img>
              </span>
            </button>
          )}
        </div>
      );
    });
    const renderOrders = this.props.orderHistoryList?.data?.map(
      (order, index) => {
        const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
          <Button
            href=''
            ref={ref}
            onClick={(e) => {
              e.preventDefault();
              onClick(e);
            }}
          >
            {children}
          </Button>
        ));
        return (
          <ListGroup.Item
            key={index}
            ref={(node) => (this['item-' + index] = node)}
            as={CustomToggle}
            onClick={this.showHistoryDetails.bind(this, order)}
          >
            <div
              className='d-flex mt-1 pt-2 mb-2 pl-2 pb-2 align-items-center text-alignment'
              id={order?.partner_order_id}
            >
              <div className='col-md-2 col-sm-2 col-2 ps-3 underline'>
                <b>#{order.partner_id?.toUpperCase()}</b>
              </div>
              <div className='col-md-2 col-sm-2 col-2 '>
                <img
                  className='img-responsive'
                  alt={order.partner_name}
                  src={imageObj[order.partner_name]}
                />
              </div>
              <div className='col-md-2 col-sm-2 col-2 '>
                <div className='histoy-table-time menu-header'>
                  {this.formatDateToDateMonth(order?.created_on)}
                </div>
                <div>{order.time}</div>
              </div>
              <div className='col-md-2 col-sm-2 col-2 ms-1'>
                {order.count?.total_items}
              </div>
              <div className='col-md-2 col-sm-2 col-2 ms-1 order-history-price-col'>
                ${(order.total_price / 100).toFixed(2)}
              </div>
              <div className=' col-md-2 col-sm-2 col-2 ms-1 text-capitalize'>
                {order.partner_status}
              </div>
            </div>
          </ListGroup.Item>
        );
      }
    );
    if (this.props?.showErrorPage) {
      return <SVTMainComponent />;
    }
    return (
      <>
        <SVTOrderHistoryFilterPopup
          show={this.state.modalShow}
          onHide={() => this.setModalShow(false)}
          checkboxSubmitHandler={this.checkboxSubmitHandler}
          selectedFilter={this.state.selectedFilter}
          data-test='orderHistoryFilterPopup'
        />
        <SVTHeaderComponent />
        <div
          className='container-fluid parent_block'
          data-test='orderHistoryMainContainer'
        >
          <SVTOrderHistorySummaryComponent
            historyDetails={this.props.orderHistoryList}
            orderHistoryFilter={this.props.orderHistoryFilter}
            shouldShowSummary={this.props.shouldShowSummary}
          />
          <div className='order-history-table col-md-12 col-sm-12 col-12'>
            <div className='d-flex'>
              <div className='history-table-header-Order pt-4 mb-3'>
                <span className='history-table-header'>Order History</span>
                <span className='histoy-table-margin-last7 last-7-days'>
                  {this.props.orderHistoryFilter && (
                    <> {this.showTimeline()} </>
                  )}
                </span>
              </div>
              <div className='d-flex history-btn-alignment'>
                <div
                  className={
                    this.state.historySearchInput
                      ? 'border-highlight input-group'
                      : 'search-by-orderID input-group'
                  }
                >
                  <input
                    type='text'
                    name='searchID'
                    className='form-control history-search '
                    autoComplete='false'
                    placeholder={'Search by Order Id'}
                    aria-label='Search'
                    aria-describedby='search-addon'
                    value={
                      this.state.historySearchInput
                        ? this.state.historySearchInput
                        : ''
                    }
                    onChange={this.searchForOrder.bind(this)}
                  />
                  {this.state.showCross ? (
                    <a type='button'>
                      <img
                        src={Xcross}
                        alt='close'
                        className='search-padding pt-2'
                        onClick={() => this.onClosePress()}
                        data-test='searchCrossIcon'
                      ></img>
                    </a>
                  ) : (
                    <a type='button'>
                      <img
                        src={search}
                        alt='search'
                        className='search-padding align-items-center pt-2'
                      ></img>
                    </a>
                  )}
                </div>
                <div>
                  <Button
                    className='filter-button content-display'
                    disabled={false}
                    onClick={this.setModalShow.bind(this, true)}
                  >
                    {'Filter'}
                  </Button>
                </div>
              </div>
            </div>

            {/* display oval shape button here */}
            {/* <div>
							<button class='round-button'>
								<span id='but_ride'>Button</span>
							</button>
						</div> */}
            {filterList.length !== 0 && (
              <div className='filter-rounded-btn-container ms-4'>
                <div className='d-flex order-history-filter-spacing'>
                  {filterList}
                </div>
              </div>
            )}
            {/* <button class="round-button"><span id='but_ride'>Button</span></button>
        </div> 
         <div className="d-flex">{filterList}</div> */}

            <div className='history-table-content'>
              <div className=' d-flex mt-3 mb-1 pl-2 pb-3 border-bottom align-items-center'>
                <div className='col-md-2 col-sm-2 col-2  d-flex olivegreen-color-text svt-bold-text'>
                  <span
                    className='cursor-pointer ps-3'
                    // onClick={() =>
                    // 	this.state.ORDER_ID == true
                    // 		? this.filterDataInAscendingOrder.bind(this)(
                    // 				sortingConstants.ORDER_ID,
                    // 		  ) ||
                    // 		  this.setState({
                    // 				ORDER_ID: !this.state.ORDER_ID,
                    // 		  })
                    // 		: this.filterDataInDescendingOrder.bind(this)(
                    // 				sortingConstants.ORDER_ID,
                    // 		  ) ||
                    // 		  this.setState({
                    // 				ORDER_ID: !this.state.ORDER_ID,
                    // 		  })
                    // }
                  >
                    {'Order Id'}
                  </span>
                  {/* <div className='sorting-button-margin d-grid'>
										<img
											className='img-responsive cursor-pointer '
											alt=''
											src={chevronUp}
											onClick={() =>
												this.filterDataInAscendingOrder.bind(this)(
													sortingConstants.ORDER_ID,
												)
											}
											data-test='orderIdUpIconForSortingOfOrderHistory'
										/>
										<img
											className='img-responsive cursor-pointer '
											alt=''
											src={chevronDown}
											onClick={() =>
												this.filterDataInDescendingOrder.bind(this)(
													sortingConstants.ORDER_ID,
												)
											}
											data-test='orderIdDownIconForSortingOfOrderHistory'
										/>
									</div> */}
                </div>
                <div className='col-md-2 col-sm-2 col-2 d-flex olivegreen-color-text svt-bold-text'>
                  <span
                    className='cursor-pointer'
                    onClick={() =>
                      this.state.PARTNER == true
                        ? this.filterDataInAscendingOrder.bind(this)(
                            sortingConstants.PARTNER
                          ) ||
                          this.setState({
                            PARTNER: !this.state.PARTNER,
                          })
                        : this.filterDataInDescendingOrder.bind(this)(
                            sortingConstants.PARTNER
                          ) ||
                          this.setState({
                            PARTNER: !this.state.PARTNER,
                          })
                    }
                    data-test='partnerSpanOfOrderHistory'
                  >
                    {'Partner'}
                  </span>
                  <div className='sorting-button-margin d-grid'>
                    <img
                      className='img-responsive cursor-pointer '
                      alt=''
                      src={chevronUp}
                      onClick={() =>
                        this.filterDataInAscendingOrder.bind(this)(
                          sortingConstants.PARTNER
                        )
                      }
                      data-test='partnerUpIconForSortingOfOrderHistory'
                    />
                    <img
                      className='img-responsive cursor-pointer '
                      alt=''
                      src={chevronDown}
                      onClick={() =>
                        this.filterDataInDescendingOrder.bind(this)(
                          sortingConstants.PARTNER
                        )
                      }
                      data-test='partnerDownIconForSortingOfOrderHistory'
                    />
                  </div>
                </div>
                <div className='col-md-2 col-sm-2 col-2 d-flex olivegreen-color-text svt-bold-text'>
                  <span
                    className='cursor-pointer'
                    onClick={() =>
                      this.state.DATE == true
                        ? this.filterDataInAscendingOrder.bind(this)(
                            sortingConstants.DATE
                          ) ||
                          this.setState({
                            DATE: !this.state.DATE,
                          })
                        : this.filterDataInDescendingOrder.bind(this)(
                            sortingConstants.DATE
                          ) ||
                          this.setState({
                            DATE: !this.state.DATE,
                          })
                    }
                    data-test='dateSpanOfOrderHistory'
                  >
                    {'Date'}
                  </span>
                  <div className='d-grid sorting-button-margin'>
                    <img
                      className='img-responsive cursor-pointer '
                      alt=''
                      src={chevronUp}
                      onClick={() =>
                        this.filterDataInAscendingOrder.bind(this)(
                          sortingConstants.DATE
                        )
                      }
                      data-test='dateUpIconForSortingOfOrderHistory'
                    />
                    <img
                      className='img-responsive cursor-pointer '
                      alt=''
                      src={chevronDown}
                      onClick={() =>
                        this.filterDataInDescendingOrder.bind(this)(
                          sortingConstants.DATE
                        )
                      }
                      data-test='dateDownIconForSortingOfOrderHistory'
                    />
                  </div>
                </div>
                <div className='col-md-2 col-sm-2 col-1 d-flex olivegreen-color-text svt-bold-text'>
                  <span
                    className='cursor-pointer'
                    onClick={() =>
                      this.state.ITEMS == true
                        ? this.filterDataInAscendingOrder.bind(this)(
                            sortingConstants.ITEMS
                          ) ||
                          this.setState({
                            ITEMS: !this.state.ITEMS,
                          })
                        : this.filterDataInDescendingOrder.bind(this)(
                            sortingConstants.ITEMS
                          ) ||
                          this.setState({
                            ITEMS: !this.state.ITEMS,
                          })
                    }
                    data-test='itemsSpanOfOrderHistory'
                  >
                    {'Items'}
                  </span>
                  <div className='sorting-button-margin d-grid'>
                    <img
                      className='img-responsive cursor-pointer '
                      alt=''
                      src={chevronUp}
                      onClick={() =>
                        this.filterDataInAscendingOrder.bind(this)(
                          sortingConstants.ITEMS
                        )
                      }
                      data-test='itemsUpIconForSortingOfOrderHistory'
                    />
                    <img
                      className='img-responsive cursor-pointer '
                      alt=''
                      src={chevronDown}
                      onClick={() =>
                        this.filterDataInDescendingOrder.bind(this)(
                          sortingConstants.ITEMS
                        )
                      }
                      data-test='itemsDownIconForSortingOfOrderHistory'
                    />
                  </div>
                </div>
                <div className='col-md-2 col-sm-2 col-2 d-flex olivegreen-color-text svt-bold-text order-history-price-col'>
                  <span
                    className='cursor-pointer'
                    onClick={() =>
                      this.state.PRICE == true
                        ? this.filterDataInAscendingOrder.bind(this)(
                            sortingConstants.PRICE
                          ) ||
                          this.setState({
                            PRICE: !this.state.PRICE,
                          })
                        : this.filterDataInDescendingOrder.bind(this)(
                            sortingConstants.PRICE
                          ) ||
                          this.setState({
                            PRICE: !this.state.PRICE,
                          })
                    }
                    data-test='priceSpanOfOrderHistory'
                  >
                    {'Price'}
                  </span>
                  <div className='sorting-button-margin d-grid'>
                    <img
                      className='img-responsive cursor-pointer cursor-pointer '
                      alt=''
                      src={chevronUp}
                      onClick={() =>
                        this.filterDataInAscendingOrder.bind(this)(
                          sortingConstants.PRICE
                        )
                      }
                      data-test='priceUpIconForSortingOfOrderHistory'
                    />
                    <img
                      className='img-responsive cursor-pointer '
                      alt=''
                      src={chevronDown}
                      onClick={() =>
                        this.filterDataInDescendingOrder.bind(this)(
                          sortingConstants.PRICE
                        )
                      }
                      data-test='priceDownIconForSortingOfOrderHistory'
                    />
                  </div>
                </div>
                <div className=' col-md-2 col-sm-2 col-2 d-flex olivegreen-color-text svt-bold-text'>
                  <span
                    className='cursor-pointer'
                    onClick={() =>
                      this.state.STATUS == true
                        ? this.filterDataInAscendingOrder.bind(this)(
                            sortingConstants.STATUS
                          ) ||
                          this.setState({
                            STATUS: !this.state.STATUS,
                          })
                        : this.filterDataInDescendingOrder.bind(this)(
                            sortingConstants.STATUS
                          ) ||
                          this.setState({
                            STATUS: !this.state.STATUS,
                          })
                    }
                    data-test='statusSpanOfOrderHistory'
                  >
                    {'Status'}
                  </span>
                  <div className='sorting-button-margin d-grid'>
                    <img
                      className='img-responsive cursor-pointer '
                      alt=''
                      src={chevronUp}
                      onClick={() =>
                        this.filterDataInAscendingOrder.bind(this)(
                          sortingConstants.STATUS
                        )
                      }
                      data-test='statusUpIconForSortingOfOrderHistory'
                    />
                    <img
                      className='img-responsive cursor-pointer '
                      alt=''
                      src={chevronDown}
                      onClick={() =>
                        this.filterDataInDescendingOrder.bind(this)(
                          sortingConstants.STATUS
                        )
                      }
                      data-test='statusDownIconForSortingOfOrderHistory'
                    />
                  </div>
                </div>
              </div>
              <div
                className={
                  this.props.shouldShowSummary
                    ? 'histoy-table-height'
                    : 'no-summary-height'
                }
              >
                {' '}
                {this.state.query.length != 0 ? (
                  <>
                    {this.props.orderHistoryList.count.total_orders != 0 ? (
                      <ListGroup variant='flush'>{renderOrders}</ListGroup>
                    ) : (
                      <div className='mt-5 text-center'>
                        <img src={search} alt='close'></img>
                        <span className='no-search-results ps-2'>
                          No Results Found
                        </span>
                      </div>
                    )}
                  </>
                ) : (
                  <ListGroup variant='flush'>{renderOrders}</ListGroup>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapState(state) {
  const { orderManagementRelated, errorPageRelated } = state;
  const { orderHistoryList, orderHistoryFilter, shouldShowSummary } =
    orderManagementRelated;
  const { showErrorPage } = errorPageRelated;
  return {
    orderHistoryList,
    orderHistoryFilter,
    showErrorPage,
    shouldShowSummary,
  };
}
const actionCreators = {
  getOrderHistoryList: orderManagementActions.getOrderHistoryList,
  storeHistoryOrderDetails: orderManagementActions.storeHistoryOrderDetails,
  filterOrderHistory: orderManagementActions.filterOrderHistory,
  sortOrderHistoryList: orderManagementActions.sortOrderHistoryList,
  searchForOrder: orderManagementActions.searchForOrder,
  getPartnersList: orderManagementActions.getPartnersList,
  setCurrentPath: orderManagementActions.setCurrentPath,
  storeOrderDetails: orderManagementActions.storeOrderDetails,
  toggleShowOrderSummary: orderManagementActions.toggleShowOrderSummary,
};

export default compose(
  withRouter,
  connect(mapState, actionCreators)
)(SVTOrderHistoryComponent);
