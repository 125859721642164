import React from 'react';
import SVTHistoryItemList from './SVTHistoryItemList';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SVTDriverStatusComponent from '../common/SVTDriverStatusComponent';
import SVTHeaderComponent from './SVTHeaderComponent';
import { orderManagementActions } from '../../action/SVTOrderManagementAction';
function SVTOrderHistoryDetails(props) {
	React.useEffect(() => {
		props.setCurrentPath(props.location.pathname);
	});
	return (
		<>
			<SVTHeaderComponent />
			<div className='container-fluid parent_block'>
				<div className='order-history-order-details-container'>
					<SVTDriverStatusComponent
						driverStatus={props.selectedHistoryDetails}
						path={'/order-history'}
					/>
					<SVTHistoryItemList
						orderDetails={props?.selectedHistoryDetails}
						cancelled={props?.selectedHistoryDetails?.partner_status}
					/>
				</div>
			</div>
		</>
	);
}

function mapState(state) {
	const { orderManagementRelated } = state;
	const { selectedHistoryDetails } = orderManagementRelated;
	return { selectedHistoryDetails };
}
const actionCreators = {
	setCurrentPath: orderManagementActions.setCurrentPath,
};

export default compose(
	withRouter,
	connect(mapState, actionCreators),
)(SVTOrderHistoryDetails);
