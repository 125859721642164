import React, { useState } from 'react';
import SVTCustomerSupportPopup from './SVTCustomerSupportPopup';
import { Dropdown } from 'react-bootstrap';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { orderManagementActions } from '../../action/SVTOrderManagementAction';
import CrossMarkCircle from '../../images/Crossmark_circle.svg';
import moveToHistory from '../../images/move-to-history.svg';
import customerSupportIcon from '../../images/bp-icon-telephone-black.svg';
import SVTCompleteSingleOrderPopUp from './SVTCompleteSingleOrderPopUp';

const listOfVenderSupportAutoAccept = ['menulog'];
const listOfVenderSupportPartialAcceptance = ['uber eats'];
const listOfVendorSupportAfterAcceptChanges = ['uber eats', 'doordash'];

function STVOrderHelpDropdown(props) {
  const [modalShow, setModalShow] = useState(false);
  const [actionModal, setActionModal] = useState({
    action: null,
    showModal: false,
  });
  const partner = props.partnerList?.partners?.filter((partener) => {
    return partener?.partner_name === props?.order?.partner_name;
  });
  const {
    partnerName,
    orderType,
    afterAcceptance,
    setShouldShowEditOrderViewInOrderDetailsPage,
    order: {
      partner_status: partnerStatus = '',
      accepted_at: orderAcceptedAt = '',
    } = {},
    dropDirection = 'down',
    isFromIncomingOrderCard = false,
  } = props;
  const showOrderHelpModel = () => {
    setModalShow(true);
    props.getPartnersList();
  };
  // -----Redesign edit order Flow   Uncomment this for old flow
  // const showOrderInEditMode = () => {
  // 	if (orderAcceptedAt === '') {
  // 		props.trigerEditOrders(true);
  // 		let id = props.id;
  // 		props.setShouldShowEditOrder(prev => ({ ...prev, [id]: true }));
  // 	} else {
  // 		setShouldShowEditOrderViewInOrderDetailsPage(true);
  // 	}
  // };

  // -----Redesign edit order Flow   Comment this for old flow
  const showOrderInEditMode = () => {
    if (orderAcceptedAt === '' && isFromIncomingOrderCard) {
      props.trigerEditOrders(true);
      const id = props.id;
      let currentIncomingList = JSON.parse(
        JSON.stringify(props?.incomingOrders)
      );
      currentIncomingList = currentIncomingList?.map((order) => {
        if (order.id === id) {
          order.isEditedViewOpen = true;
        }
        return { ...order };
      });
      props.setIncomingOrderList(currentIncomingList);
    } else {
      setShouldShowEditOrderViewInOrderDetailsPage(true);
    }
  };

  return (
    <>
      <Dropdown drop={dropDirection} className='order-help-dropdown'>
        <Dropdown.Toggle
          id='dropdown-basic'
          className={
            props?.type === 'IncomingOrders'
              ? 'success-border-btn me-2 shadow-none incoming-order-dropdown'
              : 'success-border-btn me-2 shadow-none'
          }
        >
          Order Help
        </Dropdown.Toggle>
        <Dropdown.Menu
          className='order-help-dropdown-menu'
          align={props.path ? 'end' : ''}
        >
          {shouldShowEditOrderOption(
            partnerName,
            orderType,
            partnerStatus,
            afterAcceptance
          ) && (
            <>
              <Dropdown.Item
                className='order-help-dropdown-menu-text ps-0 pt-2 pd-2'
                onClick={() => showOrderInEditMode()}
              >
                Edit Order
              </Dropdown.Item>
              <Dropdown.Divider />
            </>
          )}
          <Dropdown.Item
            onClick={() => showOrderHelpModel()}
            className='order-help-dropdown-menu-text ps-0 pt-2 pd-3'
          >
            <img
              src={customerSupportIcon}
              alt='customerSupportIcon'
              className='me-2'
            />
            Contact Support
          </Dropdown.Item>
          {orderType !== 'Cancelled' &&
            listOfVenderSupportAutoAccept.includes(
              partnerName?.trim('')?.toLowerCase()
            ) &&
            partnerStatus.toLowerCase() === 'driver on the way' && (
              <>
                <Dropdown.Divider />
                <Dropdown.Item
                  className='order-help-dropdown-menu-text ps-0 pt-2 pd-2'
                  disabled={false}
                  onClick={() =>
                    setActionModal({
                      action: 'Move to Order History',
                      showModal: true,
                    })
                  }
                >
                  <img
                    src={moveToHistory}
                    alt='moveToHistory'
                    className='me-2'
                  />{' '}
                  Move to Order History
                </Dropdown.Item>
              </>
            )}
          {shouldShowCancelOrderOption(
            partnerName,
            afterAcceptance,
            orderType,
            partnerStatus
          ) && (
            <>
              <Dropdown.Divider />
              <Dropdown.Item
                className='order-help-dropdown-menu-text ps-0 pt-2 pd-2'
                disabled={false}
                onClick={() =>
                  setActionModal({
                    action: 'Cancelled',
                    showModal: true,
                  })
                }
              >
                <img
                  src={CrossMarkCircle}
                  alt='CrossMarkCircle'
                  className='me-2'
                />
                Cancel Order
              </Dropdown.Item>
            </>
          )}
        </Dropdown.Menu>
      </Dropdown>
      <SVTCustomerSupportPopup
        show={modalShow}
        onHide={() => setModalShow(false)}
        venderName={props.order?.partner_name}
        supportNumber={partner && partner[0]?.partner_support_phone}
        verificationCode={partner && partner[0]?.verification_code}
      />
      <SVTCompleteSingleOrderPopUp
        show={actionModal['showModal']}
        onHide={() => setActionModal({ showModal: false })}
        action={actionModal['action']}
      />
    </>
  );
}

export function mapState(state) {
  const { orderManagementRelated } = state;
  const { partnerList, incomingOrders } = orderManagementRelated;
  return { partnerList, incomingOrders };
}
const actionCreators = {
  getPartnersList: orderManagementActions.getPartnersList,
  showEditedOrders: orderManagementActions.showEditedOrders,
  trigerEditOrders: orderManagementActions.trigerEditOrders,
  setIncomingOrderList: orderManagementActions.setIncomingOrderList,
  setShouldShowEditOrderViewInOrderDetailsPage:
    orderManagementActions.setShouldShowEditOrderViewInOrderDetailsPage,
};

export default compose(
  withRouter,
  connect(mapState, actionCreators)
)(STVOrderHelpDropdown);

// Custom Functions

function shouldShowEditOrderOption(
  partnerName,
  orderType,
  partnerStatus,
  afterAcceptance
) {
  if (afterAcceptance) {
    return (
      listOfVendorSupportAfterAcceptChanges.includes(
        partnerName?.trim('')?.toLowerCase()
      ) &&
      orderType !== 'Cancelled' &&
      partnerStatus.toLowerCase() !== 'completed' &&
      partnerStatus.toLowerCase() !== 'cancelled'
    );
  } else {
    return (
      listOfVenderSupportPartialAcceptance.includes(
        partnerName?.trim('')?.toLowerCase()
      ) &&
      orderType !== 'Cancelled' &&
      partnerStatus.toLowerCase() !== 'completed' &&
      partnerStatus.toLowerCase() !== 'cancelled'
    );
  }
}

function shouldShowCancelOrderOption(
  partnerName,
  afterAcceptance,
  orderType,
  partnerStatus
) {
  return (
    listOfVendorSupportAfterAcceptChanges.includes(
      partnerName?.trim('')?.toLowerCase()
    ) &&
    afterAcceptance &&
    orderType !== 'Cancelled' &&
    partnerStatus.toLowerCase() !== 'completed' &&
    partnerStatus.toLowerCase() !== 'cancelled'
  );
}
