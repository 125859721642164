import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import webIcon from '../../images/bp-icon-globe-web-bp-orange.svg';
import Loading from '../../images/Loading.png';
import LoadingOverlay from 'react-loading-overlay';
export default function OfflinePopup(props) {
	const { show, isLoading, retryHandler } = props;
	return (
		<>
			<LoadingOverlay>
				<Modal
					show={show}
					backdrop='static'
					keyboard={false}
					animation={false}
					centered
					dialogClassName='offline-popup'
				>
					<Modal.Header>
						<Modal.Title>
							<div className='d-flex' data-test="offlinePopupTitle">
								<p className='font-size-16 fw-bold letter-spacing-O50 m-0 p-0 me-2'>
									Internet Connection Lost
								</p>
								{isLoading && (
									<img
										class='rotating-loader'
										src={Loading}
										alt='loader'
										data-test="offlinePopupLoader"
									/>
								)}
							</div>
						</Modal.Title>
					</Modal.Header>
					<Modal.Body className='p-0 m-0 ps-3 pe-3'>
						<Row>
							<Col sm={12} className='m-0 pb-3 pl-20'>
								<hr className='m-0 contact-supp-hr' />
							</Col>
							<Col sm={2}>
								<img src={webIcon} alt='icon' />
							</Col>
							<Col sm={10}>
								<div className='letter-spacing-O50 mb-2'>
									You are offline
								</div>
								<div className='letter-spacing-O50 dark-shade-gray'>
									Try checking your internet connection. The
									session will automatically resume once the
									internet is back.
								</div>
							</Col>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<button
							className='success-btn-modal'
							onClick={retryHandler}
						>
							Retry
						</button>
					</Modal.Footer>
				</Modal>
			</LoadingOverlay>
		</>
	);
}
