import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SVTOrderDetailsHeader from './SVTOrderDetailsHeader';
import SVTPrintReceipt from './SVTPrintReceipt';
import note_arrow from '../../images/note_arrow.svg';
import SVTCompleteSingleOrderPopUp from './SVTCompleteSingleOrderPopUp';
import { Row, Col, Container } from 'react-bootstrap';
import contact from '../../images/contact.png';
import circlePlus from '../../images/circleplus.svg';
import circlePlusGrey from '../../images/circlePlusGrey.svg';
import circleMinusGreen from '../../images/circleMinusGreen.svg';
import circleMinus from '../../images/circleMinus.svg';
import CheckBox from '../../images/CheckBoxFaint.svg';
import emptyCheckBox from '../../images/emptyCheckBoxFaint.svg';
import closeButtonGreen from '../../images/closeButtonGreen.svg';
import { orderManagementActions } from '../../action/SVTOrderManagementAction';

const listOfVenderSupportProductCategoryName = ['uber eats', 'doordash'];
const listOfVenderSupportModifierNameAsDescription = ['uber eats', 'doordash'];

class SVTViewOrderDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCompleteOrderPopUp: false,
      currentOrderList: [],
    };
  }
  componentDidMount() {
    const currentOrderList = JSON.parse(
      JSON.stringify(this.props?.selectedOrderDetails?.order_items ?? '')
    );
    if (Array.isArray(currentOrderList)) {
      this.setState((prev) => ({ ...prev, currentOrderList }));
    } else {
      this.setState((prev) => ({ ...prev, currentOrderList: [] }));
    }
  }
  componentWillUnmount() {
    this.props.setShouldShowEditOrderViewInOrderDetailsPage(false);
  }
  showOrderList = () => {
    this.props.history.push('/order-list');
  };
  markAsCompletedBtnHandler = (action) => {
    this.setState((prevState) => ({
      ...prevState,
      showCompleteOrderPopUp: action,
    }));
  };
  isProductListInInitialCondition = () => {
    return (
      JSON.stringify(this.state.currentOrderList) ===
      JSON.stringify(this.props?.selectedOrderDetails?.order_items)
    );
  };

  orderListViewHandler = (itm, partnerName, parentItemQuantity) => {
    return (
      <React.Fragment key={itm?.plu}>
        <Row
          sm={12}
          className={
            parentItemQuantity === 0
              ? 'text-decoration-line-through mb-10'
              : 'mb-10'
          }
        >
          <Col sm={1}></Col>
          <Col sm={7}>
            {listOfVenderSupportModifierNameAsDescription.includes(partnerName)
              ? itm?.description
              : itm?.name}
          </Col>
          <Col sm={2} className=''>
            <Row sm={12}>
              <Col sm={8}></Col>
              <Col sm={4}>x{itm?.quantity ? itm?.quantity : 1}</Col>
            </Row>
          </Col>
          <Col sm={2} className='pr-20 text-end'>
            $
            {(itm?.unit_price
              ? itm?.unit_price / 100
              : itm?.price / itm?.quantity / 100
            ).toFixed(2)}
          </Col>
        </Row>
        {itm?.children?.length > 0 &&
          itm?.children?.map((childItem) => {
            return this.orderListViewHandler(
              childItem,
              partnerName,
              parentItemQuantity
            );
          })}
      </React.Fragment>
    );
  };
  isMaximumLimitReached = (index, value) => {
    return (
      this.props?.selectedOrderDetails?.order_items[index]['quantity'] === value
    );
  };
  getMaximumQuantityOfAProduct = (index) => {
    return this.props?.selectedOrderDetails?.order_items[index]['quantity'];
  };
  modifyQuantityOfAProduct = (index, action) => {
    const currentOrderList = JSON.parse(
      JSON.stringify(this.state.currentOrderList)
    );
    switch (action) {
      case 'INC':
        const currentQuantity = currentOrderList[index]['quantity'];
        currentOrderList[index]['quantity'] = this.isMaximumLimitReached(
          index,
          currentQuantity
        )
          ? this.getMaximumQuantityOfAProduct(index)
          : currentQuantity + 1;
        break;
      case 'DEC':
        currentOrderList[index]['quantity'] =
          currentOrderList[index]?.quantity > 0
            ? currentOrderList[index]?.quantity - 1
            : 0;
        break;
      case 'MAKE_IT_MAXIMUM':
        currentOrderList[index]['quantity'] =
          this.getMaximumQuantityOfAProduct(index);
        break;
      case 'MAKE_IT_ZERO':
        currentOrderList[index]['quantity'] = 0;
        break;
      default:
        break;
    }
    this.setState((prev) => ({ ...prev, currentOrderList }));
  };
  isAllItemsMarkedAsUnavailable = () => {
    return !this.state.currentOrderList.some((productItem) =>
      isMoreThanZero(productItem.quantity)
    );
  };
  resetQuantityOfProduct = () => {
    const initialOrderList = JSON.parse(
      JSON.stringify(this.props?.selectedOrderDetails?.order_items)
    );
    if (Array.isArray(initialOrderList)) {
      this.setState((prev) => ({
        ...prev,
        currentOrderList: initialOrderList,
      }));
    } else {
      this.setState((prev) => ({ ...prev, currentOrderList: [] }));
    }
  };
  totalPriceAndProductsCalculatorForEditView = () => {
    let totalCost = 0;
    let totalProducts = 0;
    this.state.currentOrderList.forEach((product) => {
      totalCost += product?.unit_price * product?.quantity;
      totalProducts += product?.quantity;
    });
    return [totalCost, totalProducts];
  };
  getCurrentOrderDetails = () => {
    const currentOrderDetails = JSON.parse(
      JSON.stringify(this.props?.selectedOrderDetails) ?? '{}'
    );
    currentOrderDetails['order_items'] = this.state.currentOrderList;
    return currentOrderDetails;
  };
  render() {
    const orderItemsList = this.props?.selectedOrderDetails?.order_items;
    const orderDetails = this.props?.selectedOrderDetails;
    const shouldShowEditOrderViewInOrderDetailsPage =
      this.props?.shouldShowEditOrderViewInOrderDetailsPage;
    const [totalPriceForEditView, totalNumberProductsForEditView] =
      this.totalPriceAndProductsCalculatorForEditView();
    const orderItems = orderItemsList?.map((item) => {
      return (
        <Container key={item.plu}>
          <Row sm={12}>
            <Col sm={8} className='my-2'>
              {listOfVenderSupportProductCategoryName.includes(
                orderDetails?.partner_name?.toLowerCase()
              ) && (
                <div className='light-grey-txt font-size-12 letter-spacing-15 text-break text-uppercase'>
                  {item?.catetory}
                </div>
              )}
              <div className='letter-spacing-O50'>{item?.name}</div>
            </Col>
            <Col sm={2} className='my-2'>
              <Row sm={12}>
                <Col sm={8}></Col>
                <Col sm={4}>x{item?.quantity ? item?.quantity : 1}</Col>
              </Row>
            </Col>
            <Col sm={2} className='my-2 pr-20 text-end'>
              $
              {(item?.unit_price
                ? item?.unit_price / 100
                : item?.price / item?.quantity / 100
              ).toFixed(2)}
            </Col>
          </Row>
          {item?.children?.length > 0 &&
            item?.children?.map((itm) => {
              return this.orderListViewHandler(
                itm,
                orderDetails?.partner_name?.toLowerCase() ?? ''
              );
            })}
          {/* If any special_instruction for order-items then it will show */}
          {item?.special_instructions && (
            <div className='order-specific-instructions-row-style'>
              <span className='order-details-special-instruction-note'>
                <span>
                  <img src={note_arrow} alt='up-arrow' />
                </span>
                Note:
              </span>
              <span className='order-details-special-instruction-note-text'>
                {item?.special_instructions}
              </span>
            </div>
          )}
        </Container>
      );
    });
    const editOrderView = this.state.currentOrderList?.map((item, index) => {
      return (
        <Container key={item.plu}>
          <Row sm={12}>
            <Col
              sm={5}
              lg={6}
              xxl={8}
              className='mt-10 mb-10 align-items-center'
            >
              <div className='d-flex align-items-center'>
                <section className=''>
                  {item?.quantity === 0 ? (
                    <img
                      className='edit-order-cursor-pointer'
                      data-test='emptyCheckBox'
                      src={emptyCheckBox}
                      alt='checked'
                      onClick={() =>
                        this.modifyQuantityOfAProduct(index, 'MAKE_IT_MAXIMUM')
                      }
                    />
                  ) : (
                    <img
                      id={`editOrderCheckBox${index}`}
                      className='edit-order-cursor-pointer'
                      data-test='checkBox'
                      src={CheckBox}
                      alt='checked'
                      onClick={() =>
                        this.modifyQuantityOfAProduct(index, 'MAKE_IT_ZERO')
                      }
                    />
                  )}
                </section>
                <section className='ms-3'>
                  {listOfVenderSupportProductCategoryName.includes(
                    orderDetails?.partner_name?.toLowerCase()
                  ) && (
                    <div className='light-grey-txt font-size-12 letter-spacing-15 text-break text-uppercase'>
                      {item?.catetory}
                    </div>
                  )}
                  <div
                    className={
                      item?.quantity === 0
                        ? 'text-decoration-line-through letter-spacing-O50'
                        : 'letter-spacing-O50'
                    }
                  >
                    {item?.name}
                  </div>
                </section>
              </div>
            </Col>
            <Col xxl={3} lg={5} sm={6} className='mt-10 mb-10'>
              <Row sm={12} className={'h-100 align-items-center'}>
                <Col sm={3}></Col>
                <Col sm={2}>
                  <img
                    src={item?.quantity === 0 ? circleMinus : circleMinusGreen}
                    alt='plus_icon'
                    id={`editOrderCloseBtn${index}`}
                    className={item?.quantity === 0 ? '' : 'cursor-pointer'}
                    onClick={() => this.modifyQuantityOfAProduct(index, 'DEC')}
                  />
                </Col>
                <Col
                  sm={3}
                  className={
                    item?.quantity === 0
                      ? 'text-decoration-line-through edit-order-view-quantity'
                      : 'edit-order-view-quantity'
                  }
                >
                  x{item?.quantity}
                </Col>
                <Col sm={4}>
                  <img
                    src={
                      this.isMaximumLimitReached(index, item?.quantity)
                        ? circlePlusGrey
                        : circlePlus
                    }
                    alt='plus_icon'
                    className={
                      this.isMaximumLimitReached(index, item?.quantity)
                        ? ''
                        : 'cursor-pointer'
                    }
                    onClick={() => this.modifyQuantityOfAProduct(index, 'INC')}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              sm={1}
              xxl={1}
              lg={1}
              className='mt-10 mb-10 pr-20 text-end align-items-center'
            >
              <div
                className={
                  item?.quantity === 0
                    ? 'text-decoration-line-through h-100 d-flex justify-content-end align-items-center'
                    : 'h-100 d-flex justify-content-end align-items-center'
                }
              >
                ${(item?.unit_price / 100).toFixed(2)}
              </div>
            </Col>
          </Row>
          {item?.children?.length > 0 &&
            item?.children?.map((itm) => {
              return this.orderListViewHandler(
                itm,
                orderDetails?.partner_name?.toLowerCase() ?? '',
                item?.quantity
              );
            })}
          {/* If any special_instruction for order-items then it will show */}
          {item?.special_instructions && (
            <div className='order-specific-instructions-row-style'>
              <span className='order-details-special-instruction-note'>
                <span>
                  <img src={note_arrow} alt='up-arrow' />
                </span>
                Note:
              </span>
              <span className='order-details-special-instruction-note-text'>
                {item?.special_instructions}
              </span>
            </div>
          )}
        </Container>
      );
    });
    return (
      <>
        <div
          className='order-details-main-container'
          data-test='orderDetailsMainContainer'
        >
          <SVTCompleteSingleOrderPopUp
            show={this.state.showCompleteOrderPopUp}
            onHide={this.markAsCompletedBtnHandler}
            action={'Completed'}
          />
          <SVTOrderDetailsHeader type={this.props.type} />
          <div className='d-flex flex-column justify-content-between mt-10 h-100'>
            <div className='overflow-auto order-details-scrollable-view'>
              <main className='order-details-table'>
                <section className='cultured-grey-bgc'>
                  <Container className='py-2'>
                    <Row sm={12}>
                      <Col sm={8}>{orderDetails?.customer_name} </Col>
                      <Col sm={2}>
                        <span>
                          <img
                            className='img-responsive'
                            alt='phone'
                            src={contact}
                          />
                        </span>
                        &nbsp;&nbsp;
                        <span className='customer-phone'>
                          {orderDetails?.customer_phone}
                        </span>
                      </Col>
                      <Col sm={2}>
                        {' '}
                        {orderDetails?.customer_phone_code && (
                          <>
                            <span className='light-grey-txt font-size-14 letter-spacing-O25'>
                              Code :
                            </span>
                            <span className='venetian-red-color font-size-14 letter-spacing-O25'>
                              {orderDetails?.customer_phone_code.replaceAll(
                                ' ',
                                ''
                              )}
                            </span>
                          </>
                        )}
                      </Col>
                    </Row>
                  </Container>
                </section>
                {shouldShowEditOrderViewInOrderDetailsPage ? (
                  <section className=''>
                    <Container>
                      <Row sm={12}>
                        <Col sm={4} lg={6} xxl={8} className='my-2'>
                          {' '}
                          ITEM NAME{' '}
                        </Col>
                        <Col xxl={3} lg={4} sm={6} className='my-2'>
                          <Row sm={12}>
                            <Col sm={7} lg={7} xxl={5}></Col>
                            <Col sm={5} lg={5} xxl={7} className=''>
                              QTY
                            </Col>
                          </Row>
                        </Col>
                        <Col
                          xxl={1}
                          lg={2}
                          sm={2}
                          className='my-2 pr-20 text-end text-break'
                        >
                          {' '}
                          Price
                        </Col>
                      </Row>
                      <Row sm={12}>
                        <Col
                          sm={12}
                          className='order-detail-table-first-col-border'
                        >
                          &nbsp;
                        </Col>
                      </Row>
                    </Container>
                  </section>
                ) : (
                  <section className=''>
                    <Container>
                      <Row sm={12}>
                        <Col sm={8} className='my-2'>
                          {' '}
                          ITEM NAME{' '}
                        </Col>
                        <Col sm={2} className='my-2'>
                          <Row sm={12}>
                            <Col sm={8}></Col>
                            <Col sm={4}>QTY</Col>
                          </Row>
                        </Col>
                        <Col sm={2} className='my-2 pr-20 text-end'>
                          {' '}
                          Price
                        </Col>
                      </Row>
                      <Row sm={12}>
                        <Col
                          sm={12}
                          className='order-detail-table-first-col-border'
                        >
                          &nbsp;
                        </Col>
                      </Row>
                    </Container>
                  </section>
                )}

                {shouldShowEditOrderViewInOrderDetailsPage ? (
                  <section>{editOrderView}</section>
                ) : (
                  <section>{orderItems}</section>
                )}
                <section>
                  <Container>
                    <Row>
                      <Col
                        sm={12}
                        className='order-detail-table-summary-border'
                      >
                        &nbsp;
                      </Col>
                    </Row>
                    <Row sm={12}>
                      <Col sm={1}></Col>
                      <Col sm={7} className='pt-2 pb-4'>
                        Total
                      </Col>
                      <Col sm={2} className='pt-2 pb-4'>
                        <Row sm={12}>
                          <Col sm={8}></Col>
                          <Col sm={4}>
                            x
                            {shouldShowEditOrderViewInOrderDetailsPage
                              ? totalNumberProductsForEditView
                              : orderDetails?.count?.total_items}
                          </Col>
                        </Row>
                      </Col>
                      <Col sm={2} className='text-end pr-20 pt-2 pb-4'>
                        $
                        {(shouldShowEditOrderViewInOrderDetailsPage
                          ? totalPriceForEditView / 100
                          : orderDetails?.total_price / 100
                        ).toFixed(2)}
                      </Col>
                    </Row>
                  </Container>
                </section>
              </main>
            </div>
            {shouldShowEditOrderViewInOrderDetailsPage ? (
              <FooterRightSideBtnForEditOrderView
                setShouldShowEditOrderViewInOrderDetailsPage={
                  this.props.setShouldShowEditOrderViewInOrderDetailsPage
                }
                isProductListInInitialCondition={
                  this.isProductListInInitialCondition
                }
                resetQuantityOfProduct={this.resetQuantityOfProduct}
                isAllItemsMarkedAsUnavailable={
                  this.isAllItemsMarkedAsUnavailable
                }
                getCurrentOrderDetails={this.getCurrentOrderDetails}
              />
            ) : (
              <FooterRightSideBtn
                orderDetails={orderDetails}
                markAsCompletedBtnHandler={this.markAsCompletedBtnHandler}
                goToLiveOrderPage={this.showOrderList}
              />
            )}
          </div>
        </div>
      </>
    );
  }
}
export function mapState(state) {
  const { orderManagementRelated } = state;
  const { selectedOrderDetails, shouldShowEditOrderViewInOrderDetailsPage } =
    orderManagementRelated;
  return {
    selectedOrderDetails,
    shouldShowEditOrderViewInOrderDetailsPage,
  };
}
const actionCreators = {
  setShouldShowEditOrderViewInOrderDetailsPage:
    orderManagementActions.setShouldShowEditOrderViewInOrderDetailsPage,
};

export default compose(
  withRouter,
  connect(mapState, actionCreators)
)(SVTViewOrderDetails);

// Custom Function for View
export function FooterRightSideBtn({
  orderDetails,
  markAsCompletedBtnHandler,
  goToLiveOrderPage,
}) {
  const { partner_name: partnerName = '', partner_status: partnerStatus = '' } =
    orderDetails ?? {};
  return (
    <div
      className='order-footer w-100 d-flex  justify-content-between'
      data-test='footerRightSideBtnContainer'
    >
      {orderDetails?.order_items?.length > 0 ? (
        <div className='printer-icon content-display'>
          <SVTPrintReceipt details={orderDetails} />
        </div>
      ) : (
        <div className='content-display'></div>
      )}
      <div className='d-flex footer-btn'>
        <div
          className='btn-back order-detail-btn cursor-pointer'
          onClick={goToLiveOrderPage}
        >
          {'Back'}
        </div>
        {/* TODO: Make it more readable */}
        {partnerName.trim('')?.toLowerCase() !== 'uber eats' &&
          partnerName.trim('')?.toLowerCase() !== 'doordash' &&
          partnerStatus.toLowerCase() === 'driver on the way' && (
            <div
              className='btn-ready-pickup order-detail-btn content-display text-capitalize cursor-pointer'
              onClick={() => markAsCompletedBtnHandler(true)}
            >
              {'Mark As Completed'}
            </div>
          )}
      </div>
    </div>
  );
}
export function FooterRightSideBtnForEditOrderView({
  setShouldShowEditOrderViewInOrderDetailsPage,
  isProductListInInitialCondition,
  resetQuantityOfProduct,
  isAllItemsMarkedAsUnavailable,
  getCurrentOrderDetails,
}) {
  const [shouldShowUpdateOrderPopup, setShouldShowUpdateOrderPopup] =
    React.useState(false);
  const closeEditViewHandler = () => {
    resetQuantityOfProduct();
    setShouldShowEditOrderViewInOrderDetailsPage(false);
  };
  const updateOrderHandler = () => {
    if (isProductListInInitialCondition()) {
      return;
    }
    setShouldShowUpdateOrderPopup(true);
  };
  const onCloseUpdateOrderPopupHandler = () => {
    setShouldShowUpdateOrderPopup(false);
  };
  const updateOrderDetails = getCurrentOrderDetails();
  return (
    <>
      <div
        className='order-footer w-100 d-flex  justify-content-between'
        data-test='footerRightSideBtnForEditOrderView'
      >
        <div className='printer-icon content-display'>
          <img
            src={closeButtonGreen}
            alt='closeIcon'
            className='header-svg-border-radius cursor-pointer '
            onClick={closeEditViewHandler}
          />
        </div>
        <div className='d-flex footer-btn mr-20'>
          {!isProductListInInitialCondition() && (
            <div
              className='btn-back order-detail-btn cursor-pointer'
              onClick={resetQuantityOfProduct}
            >
              <span className='w-100 d-flex justify-content-center align-items-center'>
                {'Reset'}
              </span>
            </div>
          )}
          <div
            className={
              isProductListInInitialCondition()
                ? 'update-order-active-btn-in-edit-order-view medium-light-grey-bgc'
                : 'update-order-active-btn-in-edit-order-view cursor-pointer dark-green-bgc'
            }
            data-test='updateOrderBtn'
            onClick={() => updateOrderHandler()}
          >
            {isAllItemsMarkedAsUnavailable() ? 'Cancel Order' : 'Update Order'}
          </div>
        </div>
      </div>
      <SVTCompleteSingleOrderPopUp
        show={shouldShowUpdateOrderPopup}
        onHide={onCloseUpdateOrderPopupHandler}
        updateOrderDetails={updateOrderDetails}
        action={isAllItemsMarkedAsUnavailable() ? 'Cancelled' : 'Update Order'}
      />
    </>
  );
}

// Custom helper function
export function isMoreThanZero(value) {
  return value > 0;
}
// const { show, onHide, selectedOrderDetails, action } = props;
