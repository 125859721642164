import React from 'react';
import { Row, Col } from 'react-bootstrap';

function SVTModifierForEditOrder(props) {
  const { modifierItem, isLineThrough } = props;
  return (
    <>
      <Row className='mt-2 mb-3' style={{ paddingLeft: '45px' }}>
        <Col
          sm={1}
          lg={1}
          xxl={1}
          className='d-lg-none d-sm-block d-md-block d-xl-none d-xxl-block'
        ></Col>
        <Col
          sm={11}
          lg={11}
          xxl={11}
          className={
            isLineThrough
              ? 'word-break text-decoration-line-through'
              : 'word-break'
          }
        >
          {modifierItem?.description}
        </Col>
      </Row>
    </>
  );
}

export default SVTModifierForEditOrder;
