import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import cross_icon from '../../images/cross_icon.svg';
import { Row, Col, Modal } from 'react-bootstrap';
import risk_icon from '../../images/Risk.svg';
import moveToHistory from '../../images/move-to-history.svg';
import { orderManagementActions } from '../../action/SVTOrderManagementAction';
import crossCircle from '../../images/crossCircle.svg';
import { orderManagementServices } from '../../services/SVTOrderManagementService';

function SVTCompleteSingleOrderPopUp(props) {
  const { show, onHide, selectedOrderDetails, action, updateOrderDetails } =
    props;
  const { partner_name } = selectedOrderDetails;
  const [popUpData, setPopUpData] = React.useState({});

  const handlePopUpAction = async () => {
    if (action === 'Move to Order History') {
      props.cancelOrder([selectedOrderDetails.id]);
    } else if (action === 'Cancelled') {
      props.cancelAcceptedOrder(
        {
          id: selectedOrderDetails.id,
          status: 'Cancelled',
          reason: 'OUT_OF_ITEMS',
        },
        props?.selectedOrderDetails?.partner_id
      );
    } else if (action === 'Update Order') {
      const orderItemPayload = [];
      const isDoordash = partner_name?.toLowerCase() === 'doordash';

      updateOrderDetails?.order_items.forEach((product) => {
        const orderItemObj = {
          item_id: !isDoordash ? product?.plu : undefined,
          line_item_id: isDoordash ? product?.line_item_id : undefined,
          quantity: product?.quantity,
          reason:
            product?.quantity === 0 ? 'OUT_OF_ITEM' : 'PARTIAL_AVAILABILITY',
        };
        orderItemPayload.push(orderItemObj);
      });
      const payload = {
        id: updateOrderDetails?.id,
        status: 'Accepted',
        order_items: orderItemPayload,
      };
      const response = await orderManagementServices.updateAcceptedOrder(
        payload
      );
      if (response?.status !== 'SUCCESS') {
        return;
      }
      const headerMsgPayload = {
        show: true,
        type: 'partialAcceptedOrderAfterAccepted',
        msg: `*partail accept after accepting the order`,
        orderId: '',
        partnerId: updateOrderDetails?.partner_id?.toUpperCase(),
      };
      props.setShowTopMsg(headerMsgPayload);
      onHide();
      props.history.push('/');
    } else if (action === 'Completed') {
      props.completeOrder([selectedOrderDetails.id]);
    }
    onHide(false);
    props.history.push('/');
  };

  React.useEffect(() => {
    if (action === 'Move to Order History') {
      setPopUpData({
        headerImg: <img src={risk_icon} alt='Risk' className='ps-2' />,
        headerMsg: 'Move to Order History?',
        sideImg: (
          <img
            src={moveToHistory}
            alt='move to order history'
            className='cancel-order-image'
          />
        ),
        firstMsg: 'Do you want to move this to Order History?',
        secondMsg:
          'Moving to Order History will not cancel the order. If you need to cancel the order, please contact Menulog Support.',
        acceptBtnText: 'Confirm',
      });
    } else if (action === 'Cancelled') {
      setPopUpData({
        headerImg: <img src={risk_icon} alt='Risk' className='ps-2' />,
        headerMsg: 'Cancel Order',
        sideImg: (
          <img
            src={crossCircle}
            alt='cancel order'
            className='cancel-order-image'
          />
        ),
        firstMsg: 'Do you want to cancel this order?',
        secondMsg:
          'Cancelling an order will change its status to cancelled and move it to the order history page.',
        acceptBtnText: 'Cancel Order',
      });
    } else if (action === 'Update Order') {
      setPopUpData({
        headerImg: null,
        headerMsg: 'Confirm Changes',
        sideImg: null,
        firstMsg: (
          <>
            {' '}
            Are you sure you want to save the changes made to order{' '}
            <strong>
              #{props?.selectedOrderDetails?.partner_id?.toUpperCase()}{' '}
            </strong>
            ?
          </>
        ),
        secondMsg: null,
        acceptBtnText: 'Update Order',
      });
    } else if (action === 'Completed') {
      setPopUpData({
        headerImg: null,
        headerMsg: 'Order picked up by driver?',
        sideImg: null,
        firstMsg: 'Do you want to mark this order as completed?',
        secondMsg:
          'If confirmed, you can view the order from the order history page.',
        acceptBtnText: 'Confirm',
      });
    }
  }, [props.action]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      size='md'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      data-test='completeOrderPopUp'
    >
      <Modal.Header>
        <Modal.Title id='contained-modal-title-vcenter'>
          <div className='d-flex'>
            {popUpData['headerImg']}
            <p className='m-0 p-0 normal-font fw-bold ps-2'>
              {popUpData['headerMsg']}
            </p>
          </div>
        </Modal.Title>
        <img
          src={cross_icon}
          height='21.98px'
          width='21.98px'
          onClick={() => onHide(false)}
          className='cursor-pointer'
          data-test='closeIcon'
          alt='cross icon'
        />
      </Modal.Header>
      <Modal.Body className='p-0 m-0 ps-3 pe-3'>
        <Row>
          <Col sm={12} className='m-0 pb-3 pl-20'>
            <hr className='m-0 contact-supp-hr' />
          </Col>
          <div
            className={
              action === 'Completed' || action === 'Update Order'
                ? 'm-0 p-0'
                : 'modal-body-container'
            }
          >
            {popUpData['sideImg']}
            <div>
              <Col md={12} className='complete-order-body-text pl-20 mb-10'>
                {popUpData['firstMsg']}
              </Col>
              <Col md={12} className='complete-order-footer-text pl-20'>
                {popUpData['secondMsg']}
              </Col>
            </div>
          </div>
        </Row>
      </Modal.Body>
      <Modal.Footer className='pt-20'>
        <button
          type='button'
          data-test='closeBtn'
          className='green-outline-btn shadow-none letter-spacing-125'
          onClick={() => onHide(false)}
        >
          {action === 'Completed' || action === 'Update Order'
            ? 'Cancel'
            : 'Back'}
        </button>
        <button
          type='button'
          data-test='actionSuccessButton'
          className={' shadow-none ms-3 success-btn-modal letter-spacing-125'}
          onClick={handlePopUpAction}
        >
          {popUpData['acceptBtnText']}
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export function mapState(state) {
  const { orderManagementRelated } = state;
  const { selectedOrderDetails } = orderManagementRelated;
  return { selectedOrderDetails };
}
const actionCreators = {
  setShowTopMsg: orderManagementActions.setShowTopMsg,
  completeOrder: orderManagementActions.completeOrder,
  cancelOrder: orderManagementActions.cancelOrder,
  cancelAcceptedOrder: orderManagementActions.cancelAcceptedOrder,
};

export default compose(
  withRouter,
  connect(mapState, actionCreators)
)(SVTCompleteSingleOrderPopUp);
