import React from 'react';
import { orderManagementActions } from '../../action/SVTOrderManagementAction';
import { compose } from 'redux';
import { connect } from 'react-redux';
function SVTOrderListScheduler(props) {
  const {
    getIncomingOrders,
    getAllNotificationsList,
    getJustCancelledOrderList,
    cancelledOrderNotifications,
    incomingOrderNotifications,
    incomingOrders,
    justCancelledOrderList,
    saveIncomingOrderNewNotification,
    saveCancelledOrderNewNotification,
    notificationList,
    currentPath,
    getOrderList,
    accessToken,
    getOrderReportCount,
    trigerEditOrder,
    getIncomingOrdersBackup,
    isOnline,
  } = props;
  React.useEffect(() => {
    const notificationListSchedulerId = setInterval(() => {
      if (isOnline) {
        incomingOrderNewNotificationFilter();
        cancelledOrderNewNotificationFilter();
      }
    }, 600000);
    return () => {
      clearInterval(notificationListSchedulerId);
    };
  }, [incomingOrderNotifications, cancelledOrderNotifications, isOnline]);
  React.useEffect(() => {
    const orderListSchedulerId = setInterval(() => {
      if (isOnline) {
        orderListScheduler();
      }
    }, 180000);
    return () => {
      clearInterval(orderListSchedulerId);
    };
  }, [trigerEditOrder, isOnline]);
  React.useEffect(() => {
    const acceptedOrderSchedulerId = setInterval(() => {
      if (isOnline) {
        acceptedOrderScheduler();
      }
    }, 60000);
    return () => clearInterval(acceptedOrderSchedulerId);
  }, [isOnline]);
  const isPresentInIncomingOrderList = (id) => {
    if (incomingOrders?.length > 0) {
      for (let index = 0; index < incomingOrders.length; index++) {
        const element = incomingOrders[index];
        if (element.id === id) {
          return true;
        }
      }
      return false;
    }
  };
  const isPresentInCancelledOrderList = (id) => {
    if (getJustCancelledOrderList?.length > 0) {
      for (let index = 0; index < justCancelledOrderList.length; index++) {
        const element = justCancelledOrderList[index];
        if (element.id === id) {
          return true;
        }
      }
      return false;
    }
  };
  const incomingOrderNewNotificationFilter = () => {
    if (accessToken && accessToken.length > 0) {
      const tmpArr = [];
      if (incomingOrderNotifications?.length > 0) {
        for (
          let index = 0;
          index < incomingOrderNotifications.length;
          index++
        ) {
          const element = incomingOrderNotifications[index];
          if (isPresentInIncomingOrderList(element.id)) {
            tmpArr.push(element);
          }
        }
      }
      saveIncomingOrderNewNotification(tmpArr);
    }
  };
  const cancelledOrderNewNotificationFilter = () => {
    if (accessToken && accessToken.length > 0) {
      const tmpArr = [];
      if (cancelledOrderNotifications?.length > 0) {
        for (
          let index = 0;
          index < cancelledOrderNotifications.length;
          index++
        ) {
          const element = cancelledOrderNotifications[index];
          if (isPresentInCancelledOrderList(element.id)) {
            tmpArr.push(element);
          }
        }
      }
      saveCancelledOrderNewNotification(tmpArr);
    }
  };
  const orderListScheduler = () => {
    console.log('trigerEditOrder...................', trigerEditOrder);
    console.log(
      'incomingOrderNotifications..................',
      incomingOrderNotifications
    );
    if (trigerEditOrder === false && accessToken?.length > 0) {
      console.log('inside incoming orders............');
      if (props.getIncomingOrders) {
        getIncomingOrders();
        getIncomingOrdersBackup();
        getJustCancelledOrderList();
        getOrderReportCount();
      }
    }
  };
  const acceptedOrderScheduler = () => {
    if (accessToken?.length > 0) {
      if (props.getOrderList) {
        console.log('inside if..............');
        getOrderList();
      }
    }
  };
  React.useEffect(() => {
    if (accessToken && accessToken?.length > 0 && isOnline) {
      if (getAllNotificationsList) {
        getAllNotificationsList();
      }
    }
  }, [notificationList, currentPath, isOnline]);
  return <div></div>;
}
function mapState(state) {
  const { orderManagementRelated, authInfo } = state;
  const {
    incomingOrderNotifications,
    cancelledOrderNotifications,
    incomingOrders,
    justCancelledOrderList,
    currentPath,
    notificationList,
    trigerEditOrder,
  } = orderManagementRelated;
  const { accessToken } = authInfo;
  return {
    cancelledOrderNotifications,
    incomingOrderNotifications,
    incomingOrders,
    justCancelledOrderList,
    currentPath,
    notificationList,
    accessToken,
    trigerEditOrder,
  };
}
const actionCreators = {
  getIncomingOrders: orderManagementActions.getIncomingOrders,
  getJustCancelledOrderList: orderManagementActions.getJustCancelledOrderList,
  saveIncomingOrderNewNotification:
    orderManagementActions.saveIncomingOrderNewNotification,
  saveCancelledOrderNewNotification:
    orderManagementActions.saveCancelledOrderNewNotification,
  getAllNotificationsList: orderManagementActions.getAllNotificationsList,
  getOrderList: orderManagementActions.getOrderList,
  getOrderReportCount: orderManagementActions.getOrderReportCount,
  getIncomingOrdersBackup: orderManagementActions.getIncomingOrdersBackup,
};
export default compose(connect(mapState, actionCreators))(
  SVTOrderListScheduler
);
