/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import firebase from '../../services/SVTFirebaseService';
import { getMessaging, onMessage } from 'firebase/messaging';
import { orderManagementActions } from '../../action/SVTOrderManagementAction';
import { compose } from 'redux';
import { connect } from 'react-redux';
import incomingSound from '../../sound/Incoming-order.wav';
import cancelledSound from '../../sound/Cancelled-order.wav';
import { menuManagementAction } from '../../action/SVTMenuManagementAction';
const incomingTone = new Audio(incomingSound);
const cancelledTone = new Audio(cancelledSound);
incomingTone.loop = true;
cancelledTone.loop = true;
const channel = new BroadcastChannel('sw-messages');
function SVTNotification(props) {
  const {
    saveCancelledOrderNewNotification,
    saveIncomingOrderNewNotification,
    setShowTopMsg,
    getIncomingOrders,
    getJustCancelledOrderList,
    getAllNotificationsList,
    getOrderList,
    getOrderReportCount,
    accessToken,
    saveHeaderMsgOrderId,
    getIncomingOrdersBackup,
    getTotalUnavailableCount,
    getTotalUnavailableItemList,
    getAllProductsList,
    getAllModifiersList,
    searchQuery,
    getAllProductsAndModifiersListFromSearchQuery,
    updateCurrentToggledModifier,
    currentActiveCategory,
    orderReportListCount,
  } = props;
  const {
    incomingOrderNotifications,
    cancelledOrderNotifications,
    currentHeaderMsgOrderId,
  } = props?.orderManagementRelated;
  const messaging = getMessaging(firebase);
  const isPresentInIncomingOrderNotificationsList = (id) => {
    for (let index = 0; index < incomingOrderNotifications.length; index++) {
      if (incomingOrderNotifications[index]['id'] === id) {
        return true;
      }
    }
    return false;
  };
  const isPresentInCancelledOrderNotificationsList = (id) => {
    for (let index = 0; index < cancelledOrderNotifications.length; index++) {
      if (cancelledOrderNotifications[index].id === id) {
        return true;
      }
    }
    return false;
  };
  const removeIncomingNotificationById = (id) => {
    let tmp = incomingOrderNotifications.slice();
    tmp = tmp.filter((notification) => notification.id !== id);
    saveIncomingOrderNewNotification(tmp);
  };
  const removeCancelledNotificationById = (id) => {
    let tmp = cancelledOrderNotifications.slice();
    tmp = tmp.filter((notification) => notification.id !== id);
    saveCancelledOrderNewNotification(tmp);
  };
  onMessage(messaging, async (payload) => {
    console.log('Message received. In App ', payload);
    if (accessToken && accessToken.length > 0) {
      const isIncomingOrder = payload?.notification?.title?.split(':');
      const currentNotification = {
        id: isIncomingOrder[isIncomingOrder.length - 1],
        notification: payload.notification,
      };
      if (
        isIncomingOrder[isIncomingOrder.length - 2].trim().toLowerCase() ===
        'incoming'
      ) {
        if (
          !isPresentInIncomingOrderNotificationsList(
            isIncomingOrder[isIncomingOrder.length - 1]
          )
        ) {
          const updatedIncomingNotification = [
            ...incomingOrderNotifications,
            currentNotification,
          ];
          saveIncomingOrderNewNotification(updatedIncomingNotification);
          getIncomingOrders();
          getIncomingOrdersBackup();
          getOrderList();
          getAllNotificationsList();
          getJustCancelledOrderList();
          getOrderReportCount();
        }
      } else if (
        isIncomingOrder[isIncomingOrder.length - 2].trim().toLowerCase() ===
        'cancelled'
      ) {
        if (isPresentInIncomingOrderNotificationsList(currentNotification.id)) {
          removeIncomingNotificationById(currentNotification.id);
        }
        if (
          !isPresentInCancelledOrderNotificationsList(
            isIncomingOrder[isIncomingOrder.length - 1]
          )
        ) {
          const headerMsg = `Order # ${isIncomingOrder[
            isIncomingOrder.length - 3
          ]?.trim()}# has been cancelled`;
          const headerMsgPayload = {
            show: true,
            type: 'Cancelled',
            msg: headerMsg,
            orderId: isIncomingOrder[isIncomingOrder.length - 1],
            partnerId: isIncomingOrder[isIncomingOrder.length - 3]?.trim(),
          };
          setShowTopMsg(headerMsgPayload);
          const updatedCancelledNotification = [
            ...cancelledOrderNotifications,
            currentNotification,
          ];
          saveCancelledOrderNewNotification(updatedCancelledNotification);
          getIncomingOrders();
          getIncomingOrdersBackup();
          getOrderList();
          getJustCancelledOrderList();
          getAllNotificationsList();
          getOrderReportCount();
        }
      } else if (
        isIncomingOrder[isIncomingOrder.length - 2].trim().toLowerCase() ===
        'assigningdriver'
      ) {
        const headerMsg = `Assigning driver to order #${isIncomingOrder[
          isIncomingOrder.length - 3
        ]?.trim()}#`;
        const headerMsgPayload = {
          show: true,
          type: 'assigningdriver',
          msg: headerMsg,
          orderId: isIncomingOrder[isIncomingOrder.length - 1],
          partnerId: isIncomingOrder[isIncomingOrder.length - 3]?.trim(),
        };
        setShowTopMsg(headerMsgPayload);
        saveHeaderMsgOrderId(currentNotification.id);
        getIncomingOrders();
        getIncomingOrdersBackup();
        getOrderList();
        getAllNotificationsList();
        getJustCancelledOrderList();
        getOrderReportCount();
      } else if (
        isIncomingOrder[isIncomingOrder.length - 2].trim().toLowerCase() ===
        'driverarrived'
      ) {
        const headerMsg = `A driver has arrived to pick up order #${isIncomingOrder[
          isIncomingOrder.length - 3
        ]?.trim()}#`;
        const headerMsgPayload = {
          show: true,
          type: 'driverarrived',
          msg: headerMsg,
          orderId: isIncomingOrder[isIncomingOrder.length - 1],
          partnerId: isIncomingOrder[isIncomingOrder.length - 3]?.trim(),
        };
        setShowTopMsg(headerMsgPayload);
        saveHeaderMsgOrderId(currentNotification.id);
        getIncomingOrders();
        getIncomingOrdersBackup();
        getOrderList();
        getAllNotificationsList();
        getJustCancelledOrderList();
        getOrderReportCount();
      } else if (
        isIncomingOrder[isIncomingOrder.length - 2].trim().toLowerCase() ===
        'movedtoorderhistory'
      ) {
        removeCancelledNotificationById(
          isIncomingOrder[isIncomingOrder.length - 1]
        );
        getIncomingOrders();
        getIncomingOrdersBackup();
        getOrderList();
        getAllNotificationsList();
        getJustCancelledOrderList();
        getOrderReportCount();
      } else if (
        isIncomingOrder[isIncomingOrder.length - 2].trim().toLowerCase() ===
        'completed'
      ) {
        if (currentHeaderMsgOrderId === currentNotification.id) {
          const headerMsgPayload = {
            show: false,
            type: 'others',
            msg: '',
            orderId: '',
          };
          setShowTopMsg(headerMsgPayload);
        }

        getOrderList();
        getAllNotificationsList();
        getJustCancelledOrderList();
        getOrderReportCount();
      } else if (
        isIncomingOrder[isIncomingOrder.length - 2].trim().toLowerCase() ===
        'updatemodifier'
      ) {
        const isModifierAvailable = isIncomingOrder[0]
          .toLowerCase()
          .includes('true');
        const modifierSkuCode = isIncomingOrder[1]?.trim();
        const [modifierName] = isIncomingOrder[0].split(',');
        const numberOfProductsHavingThisModifier = Number(
          isIncomingOrder[isIncomingOrder.length - 1].trim()
        );
        const modifierUpdatePayload = {
          skuCode: modifierSkuCode,
          availability: isModifierAvailable,
          modifierName: modifierName,
          numberOfProductsHavingThisModifier:
            numberOfProductsHavingThisModifier,
        };
        switch (currentActiveCategory) {
          case 'marked-as-unavailable':
            getTotalUnavailableItemList();
            getTotalUnavailableCount();
            break;
          case 'single-tabletall-products':
            getAllProductsList();
            getTotalUnavailableCount();
            break;
          case 'single-tabletall-modifiers':
            getAllModifiersList();
            getTotalUnavailableCount();
            break;
          case 'search':
            getAllProductsAndModifiersListFromSearchQuery(searchQuery);
            getTotalUnavailableCount();
            break;
          default:
            break;
        }
        updateCurrentToggledModifier(modifierUpdatePayload);
      } else {
        getIncomingOrders();
        getIncomingOrdersBackup();
        getOrderList();
        getAllNotificationsList();
        getJustCancelledOrderList();
        getOrderReportCount();
      }
    }
  });
  console.log(
    'incomingOrderNotifications',
    incomingOrderNotifications,
    cancelledOrderNotifications,
    orderReportListCount
  );
  if (
    props?.orderReportListCount?.incoming_orders_count > 0 &&
    incomingOrderNotifications?.length > 0
  ) {
    if (!cancelledTone.paused) {
      cancelledTone.pause();
    }
    incomingTone
      .play()
      .catch((err) =>
        console.log(
          `User is not given permission to play audio and because of that an error occurred with message => ${err?.message}`
        )
      );
  } else {
    if (!incomingTone.paused) {
      incomingTone.pause();
    }
    if (
      props?.orderReportListCount?.just_canceled_orders_count > 0 &&
      cancelledOrderNotifications?.length > 0
    ) {
      cancelledTone
        .play()
        .catch((err) =>
          console.log(
            `User is not given permission to play audio and because of that an error occurred with message => ${err?.message}`
          )
        );
    } else {
      if (!cancelledTone.paused) cancelledTone.pause();
    }
  }
  const [notificationMessage, setNotificationMessage] = React.useState();
  React.useEffect(() => {
    if (notificationMessage) {
      if (accessToken && accessToken.length > 0) {
        const payload = notificationMessage?.msg;
        const isIncomingOrder = payload?.notification?.title?.split(':');
        const currentBgcNotification = {
          id: isIncomingOrder[isIncomingOrder.length - 1],
          notification: payload.notification,
        };
        if (
          isIncomingOrder[isIncomingOrder.length - 2].trim().toLowerCase() ===
          'incoming'
        ) {
          if (
            !isPresentInIncomingOrderNotificationsList(
              isIncomingOrder[isIncomingOrder.length - 1]
            )
          ) {
            const updatedIncomingNotification = [
              ...incomingOrderNotifications,
              currentBgcNotification,
            ];
            saveIncomingOrderNewNotification(updatedIncomingNotification);
            getIncomingOrders();
            getIncomingOrdersBackup();
            getOrderList();
            getAllNotificationsList();
            getOrderReportCount();
          }
        } else if (
          isIncomingOrder[isIncomingOrder.length - 2].trim().toLowerCase() ===
          'cancelled'
        ) {
          if (
            isPresentInIncomingOrderNotificationsList(currentBgcNotification.id)
          ) {
            removeIncomingNotificationById(currentBgcNotification.id);
          }
          if (
            !isPresentInCancelledOrderNotificationsList(
              isIncomingOrder[isIncomingOrder.length - 1]
            )
          ) {
            const updatedCancelledNotification = [
              ...cancelledOrderNotifications,
              currentBgcNotification,
            ];
            saveCancelledOrderNewNotification(updatedCancelledNotification);
            getJustCancelledOrderList();
            getIncomingOrders();
            getIncomingOrdersBackup();
            getOrderList();
            getAllNotificationsList();
            getOrderReportCount();
            const headerMsg = `Order # ${
              isIncomingOrder[isIncomingOrder.length - 3]?.trim() + '#'
            } has been cancelled`;
            const headerMsgPayload = {
              show: true,
              type: 'Cancelled',
              msg: headerMsg,
              orderId: isIncomingOrder[isIncomingOrder.length - 1],
              partnerId: isIncomingOrder[isIncomingOrder.length - 3]?.trim(),
            };
            setShowTopMsg(headerMsgPayload);
          }
        } else if (
          isIncomingOrder[isIncomingOrder.length - 2].trim().toLowerCase() ===
          'assigningdriver'
        ) {
          const headerMsg = `Assigning driver to order #${isIncomingOrder[
            isIncomingOrder.length - 3
          ]?.trim()}#`;
          const headerMsgPayload = {
            show: true,
            type: 'assigningdriver',
            msg: headerMsg,
            orderId: isIncomingOrder[isIncomingOrder.length - 1],
            partnerId: isIncomingOrder[isIncomingOrder.length - 3]?.trim(),
          };
          setShowTopMsg(headerMsgPayload);
          saveHeaderMsgOrderId(currentBgcNotification.id);
          getIncomingOrders();
          getIncomingOrdersBackup();
          getOrderList();
          getAllNotificationsList();
          getOrderReportCount();
          getJustCancelledOrderList();
        } else if (
          isIncomingOrder[isIncomingOrder.length - 2].trim().toLowerCase() ===
          'driverarrived'
        ) {
          const headerMsg = `A driver has arrived to pick up order #${isIncomingOrder[
            isIncomingOrder.length - 3
          ]?.trim()}#`;
          const headerMsgPayload = {
            show: true,
            type: 'driverarrived',
            msg: headerMsg,
            orderId: isIncomingOrder[isIncomingOrder.length - 1],
            partnerId: isIncomingOrder[isIncomingOrder.length - 3]?.trim(),
          };
          setShowTopMsg(headerMsgPayload);
          saveHeaderMsgOrderId(currentBgcNotification.id);
          getIncomingOrders();
          getIncomingOrdersBackup();
          getOrderList();
          getAllNotificationsList();
          getJustCancelledOrderList();
          getOrderReportCount();
        } else if (
          isIncomingOrder[isIncomingOrder.length - 2].trim().toLowerCase() ===
          'movedtoorderhistory'
        ) {
          removeCancelledNotificationById(
            isIncomingOrder[isIncomingOrder.length - 1]
          );
          getIncomingOrders();
          getIncomingOrdersBackup();
          getOrderList();
          getAllNotificationsList();
          getJustCancelledOrderList();
          getOrderReportCount();
        } else if (
          isIncomingOrder[isIncomingOrder.length - 2].trim().toLowerCase() ===
          'completed'
        ) {
          if (currentHeaderMsgOrderId === currentBgcNotification.id) {
            const headerMsgPayload = {
              show: false,
              type: 'others',
              msg: '',
              orderId: '',
            };
            setShowTopMsg(headerMsgPayload);
          }

          getOrderList();
          getAllNotificationsList();
          getJustCancelledOrderList();
          getOrderReportCount();
        } else if (
          isIncomingOrder[isIncomingOrder.length - 2].trim().toLowerCase() ===
          'updatemodifier'
        ) {
          const isModifierAvailable = isIncomingOrder[0]
            .toLowerCase()
            .includes('true');
          const modifierSkuCode = isIncomingOrder[1]?.trim();
          const [modifierName] = isIncomingOrder[0].split(',');
          const numberOfProductsHavingThisModifier = Number(
            isIncomingOrder[isIncomingOrder.length - 1].trim()
          );
          const modifierUpdatePayload = {
            skuCode: modifierSkuCode,
            availability: isModifierAvailable,
            modifierName: modifierName,
            numberOfProductsHavingThisModifier:
              numberOfProductsHavingThisModifier,
          };
          switch (currentActiveCategory) {
            case 'marked-as-unavailable':
              getTotalUnavailableItemList();
              getTotalUnavailableCount();
              break;
            case 'single-tabletall-products':
              getAllProductsList();
              getTotalUnavailableCount();
              break;
            case 'single-tabletall-modifiers':
              getAllModifiersList();
              getTotalUnavailableCount();
              break;
            case 'search':
              getAllProductsAndModifiersListFromSearchQuery(searchQuery);
              getTotalUnavailableCount();
              break;
            default:
              break;
          }
          updateCurrentToggledModifier(modifierUpdatePayload);
        } else {
          getIncomingOrders();
          getIncomingOrdersBackup();
          getOrderList();
          getAllNotificationsList();
          getJustCancelledOrderList();
          getOrderReportCount();
        }
      }
    }
  }, [notificationMessage]);
  channel.addEventListener('message', (event) => {
    setNotificationMessage(event.data);
  });

  return <></>;
}
function mapState(state) {
  const { orderManagementRelated, authInfo, menuManagementRelated } = state;
  const { accessToken } = authInfo;
  const { searchQuery, currentActiveCategory } = menuManagementRelated;
  const { orderReportListCount } = orderManagementRelated;
  return {
    orderManagementRelated,
    accessToken,
    searchQuery,
    currentActiveCategory,
    orderReportListCount,
  };
}
const actionCreators = {
  saveIncomingOrderNewNotification:
    orderManagementActions.saveIncomingOrderNewNotification,
  saveCancelledOrderNewNotification:
    orderManagementActions.saveCancelledOrderNewNotification,
  getJustCancelledOrderList: orderManagementActions.getJustCancelledOrderList,
  getIncomingOrders: orderManagementActions.getIncomingOrders,
  setShowTopMsg: orderManagementActions.setShowTopMsg,
  getAllNotificationsList: orderManagementActions.getAllNotificationsList,
  getOrderList: orderManagementActions.getOrderList,
  getOrderReportCount: orderManagementActions.getOrderReportCount,
  saveHeaderMsgOrderId: orderManagementActions.saveHeaderMsgOrderId,
  getIncomingOrdersBackup: orderManagementActions.getIncomingOrdersBackup,
  getAllModifiersList: menuManagementAction.getAllModifiersList,
  getAllProductsList: menuManagementAction.getAllProductsList,
  getTotalUnavailableItemList: menuManagementAction.getTotalUnavailableItemList,
  getTotalUnavailableCount: menuManagementAction.getTotalUnavailableCount,
  getAllProductsAndModifiersListFromSearchQuery:
    menuManagementAction.getAllProductsAndModifiersListFromSearchQuery,
  updateCurrentToggledModifier:
    menuManagementAction.updateCurrentToggledModifier,
};

export default compose(connect(mapState, actionCreators))(SVTNotification);
