import { orderManagementServices } from '../services/SVTOrderManagementService';
import { startLoader, stopLoader, showErrorPage } from '../helper/common';
import { constants } from '../constant/reducerConstant';
import { sort } from 'fast-sort';

export const orderManagementActions = {
  getOrderList,
  storeOrderDetails,
  getJustCancelledOrderList,
  justCancelledSelectedOrderDetails,
  dismissLiveOrders,
  storeHistoryOrderDetails,
  getOrderHistoryList,
  getStoreDetails,
  updateStoreStatus,
  getIncomingOrders,
  addDeviceToken,
  searchForOrder,
  getPartnersList,
  filterOrderHistory,
  sortOrderHistoryList,
  saveIncomingOrderNewNotification,
  saveCancelledOrderNewNotification,
  clearAllSelections,
  getNotificationsList,
  updateOrderStatus,
  updateNotificationStatus,
  getNotifiedItemDetails,
  getSingleItemDetails,
  getOrderReportCount,
  updateEditedOrders,
  setAutoPrintOption,
  showEditedOrders,
  storePartiallyAcceptedItem,
  setShowTopMsg,
  getLoginUserDetails,
  storeAccessToken,
  cancelIcomingOrder,
  clearAllAuthInfo,
  setAllNotificationCount,
  getAllNotificationsList,
  logoutFromApplication,
  sortOrderItemList,
  setCurrentPath,
  getMyProfileDetails,
  getIncomingOrdersBackup,
  removeDeviceToken,
  storeDeviceToken,
  saveHeaderMsgOrderId,
  setShowMenuPopUp,
  getSearchQuery,
  setShowMenuPopUpModifier,
  trigerEditOrders,
  toggleShowOrderSummary,
  completeOrder,
  cancelOrder,
  setShouldShowEditOrderViewInOrderDetailsPage,
  cancelAcceptedOrder,
  setIncomingOrderList,
};

function getOrderList(number) {
  return (dispatch) => {
    dispatch(startLoader());
    orderManagementServices.getOrderList().then(
      (data) => {
        dispatch(stopLoader());
        const updatedData = customSortStatus(data?.data);
        data.data = updatedData;
        dispatch(getOrderListSuccess(data));
      },
      (error) => {
        dispatch(getOrderListFail());
        dispatch(stopLoader());
        dispatch(showErrorPage(error, 'Order List'));
      }
    );
  };
  function getOrderListSuccess(data) {
    return { type: constants.GET_ORDER_LIST_SUCCESS, data };
  }
  function getOrderListFail() {
    return { type: constants.GET_ORDER_LIST_FAIL };
  }
  function customSortStatus(orderList = []) {
    const tagsImportance = {
      'Driver Arrived': 4,
      'Driver on the way': 3,
      'Assigning Driver': 2,
      Accepted: 1,
    };
    return sort(orderList).by([
      { desc: (order) => tagsImportance[order.partner_status] },
      // We should remove "created_on" sorting in future. It is only for this release(April - May 2022 Release)
      { asc: (order) => order.partner_created_at ?? order.created_on },
    ]);
  }
}

function storeOrderDetails(order) {
  return { type: constants.STORE_ORDER_DETAILS, order };
}

function getJustCancelledOrderList() {
  return (dispatch) => {
    dispatch(startLoader());
    orderManagementServices.getJustCancelledOrderList().then(
      (data) => {
        dispatch(stopLoader());
        dispatch(getJustCancelledOrderListSuccess(data));
      },
      (error) => {
        dispatch(getJustCancelledOrderListFail());
        dispatch(stopLoader());
        dispatch(showErrorPage(error, 'Just Order Cancelled List'));
      }
    );
  };
  function getJustCancelledOrderListSuccess(data) {
    return { type: constants.GET_JUST_CANCELLED_ORDER_LIST_SUCCESS, data };
  }
  function getJustCancelledOrderListFail() {
    return { type: constants.GET_JUST_CANCELLED_ORDER_LIST_FAIL };
  }
}
function justCancelledSelectedOrderDetails(order) {
  return { type: constants.JUST_CANCELLED_ORDER_DETAILS, order };
}
function dismissLiveOrders(orderId) {
  return (dispatch) => {
    dispatch(startLoader());
    return orderManagementServices.dismissLiveOrders(orderId).then(
      (data) => {
        dispatch(stopLoader());
        dispatch(dismissOrderSuccess());
        return data;
      },
      (error) => {
        dispatch(dismissOrderFail());
        dispatch(stopLoader());
        dispatch(showErrorPage(error, 'Dismiss Live Orders'));
      }
    );
  };
  function dismissOrderSuccess() {
    return { type: constants.DISMISS_LIVE_ORDER_SUCCESS };
  }
  function dismissOrderFail() {
    return { type: constants.DISMISS_LIVE_ORDER_FAIL };
  }
}

function storeHistoryOrderDetails(order) {
  return { type: constants.STORE_ORDER_HISTOY_DETAILS, order };
}
function getOrderHistoryList(params) {
  return (dispatch) => {
    dispatch(startLoader());
    orderManagementServices.getOrderHistoryList().then(
      (data) => {
        dispatch(stopLoader());
        const updatedData = orderManagementServices.sortOrderHistoryList(
          data.data,
          'created_on',
          'desc'
        );
        data.data = updatedData;
        dispatch(getOrderHistoryListSuccess(data));
      },
      (error) => {
        dispatch(getOrderHistoryListFail());
        dispatch(stopLoader());
        dispatch(showErrorPage(error, 'Order History'));
      }
    );
  };
  function getOrderHistoryListSuccess(data) {
    return { type: constants.GET_ORDER_HISTORY_LIST_SUCCESS, data };
  }
  function getOrderHistoryListFail() {
    return { type: constants.GET_ORDER_HISTORY_LIST_FAIL };
  }
}

function getIncomingOrders() {
  return (dispatch) => {
    dispatch(startLoader());
    orderManagementServices.getIncomingOrders().then(
      (data) => {
        dispatch(stopLoader());
        // -----Redesign edit order Flow comment this for old flow
        // Start---------------
        // data = tmp24;
        data = data?.data;
        data = sort(data).asc((u) => u?.partner_created_at);
        data = data.map((e, index) => {
          if (index === 0) {
            return {
              ...e,
              raw_order_items: [],
              isOpen: true,
              isEditedViewOpen: false,
            };
          }
          return {
            ...e,
            raw_order_items: [],
            isOpen: false,
            isEditedViewOpen: false,
          };
        });
        data = {
          data,
        };
        //  End ---------------------
        dispatch(getIncomingOrdersSuccess(data));
      },
      (error) => {
        dispatch(getIncomingOrdersFail());
        dispatch(stopLoader());
        dispatch(showErrorPage(error, 'Incoming Orders'));
      }
    );
  };
  function getIncomingOrdersSuccess(data) {
    return { type: constants.GET_INCOMING_ORDERS_SUCCESS, data };
  }
  function getIncomingOrdersFail() {
    return { type: constants.GET_INCOMING_ORDERS_FAIL };
  }
}

function getStoreDetails() {
  return (dispatch) => {
    dispatch(startLoader());
    orderManagementServices.getStoreDetails().then(
      (data) => {
        dispatch(stopLoader());
        dispatch(getStoreDetailsSuccess(data));
      },
      (error) => {
        dispatch(getStoreDetailsFail());
        dispatch(stopLoader());
        dispatch(showErrorPage(error, 'Store Details'));
      }
    );
  };
  function getStoreDetailsSuccess(data) {
    return { type: constants.GET_STORE_DETAILS_SUCCESS, data };
  }
  function getStoreDetailsFail() {
    return { type: constants.GET_STORE_DETAILS_FAIL };
  }
}
function addDeviceToken(token) {
  return (dispatch) => {
    dispatch(startLoader());
    dispatch(storeDeviceToken(token));
    orderManagementServices.addDeviceToken(token).then(
      (data) => {
        dispatch(stopLoader());
        dispatch(addDeviceTokenSuccess(data));
      },
      (error) => {
        dispatch(addDeviceTokenFail());
        dispatch(stopLoader());
        dispatch(showErrorPage(error, 'Add Device Token'));
      }
    );
  };
  function addDeviceTokenSuccess(data) {
    return { type: constants.GET_DEVICE_TOKEN_SUCCESS, data };
  }
  function addDeviceTokenFail() {
    return { type: constants.GET_DEVICE_TOKEN_FAIL };
  }
}
function storeDeviceToken(token) {
  return { type: constants.STORE_DEVICE_TOKEN, token };
}
function updateStoreStatus(storeStatus, reason, storeId) {
  return (dispatch) => {
    dispatch(startLoader());
    orderManagementServices
      .updateStoreStatus(storeStatus, reason, storeId)
      .then(
        (data) => {
          orderManagementServices.getMyProfileDetails().then(
            (dataP) => {
              dispatch(stopLoader());
              dispatch(getMyProfileDetailsSuccess(dataP));
              if (data?.partners_response?.length > 0) {
                let allStoreSuccess = false;
                data?.partners_response.forEach((store) => {
                  if (store['status'] >= 200 && store['status'] <= 299) {
                    allStoreSuccess = true;
                  }
                });
                if (allStoreSuccess) {
                  dispatch(setStoreStatusSuccess(storeStatus));
                  dispatch(stopLoader());
                  window.location.href = '/order-setting';
                } else {
                  dispatch(stopLoader());
                }
              } else {
                dispatch(stopLoader());
              }
              return {
                success: true,
              };
            },
            (error) => {
              dispatch(getMyProfileDetailsFail());
              dispatch(stopLoader());
              dispatch(showErrorPage(error, 'Logout application'));
            }
          );
        },
        (error) => {
          dispatch(getStoreDetailsFail());
          dispatch(stopLoader());
          dispatch(showErrorPage(error, 'Store Status Update'));
        }
      );
  };
  function getMyProfileDetailsSuccess(data) {
    return { type: constants.GET_PROFILE_DETAILS_SUCCESS, data };
  }
  function getMyProfileDetailsFail() {
    return { type: constants.GET_PROFILE_DETAILS_FAIL };
  }
  function setStoreStatusSuccess(storeStatus) {
    return {
      type: constants.UPDATE_STORE_STATUS,
      storeStatus: storeStatus,
    };
  }
  function getStoreDetailsFail() {
    return { type: constants.GET_STORE_DETAILS_FAIL };
  }
}
function getPartnersList() {
  return (dispatch) => {
    dispatch(startLoader());
    orderManagementServices.getPartnersList().then(
      (data) => {
        dispatch(stopLoader());
        dispatch(getPartnersListSuccess(data));
      },
      (error) => {
        dispatch(getPartnersListFail());
        dispatch(stopLoader());
      }
    );
  };
  function getPartnersListSuccess(data) {
    return { type: constants.GET_PARTNERS_LIST_SUCCESS, data };
  }
  function getPartnersListFail() {
    return { type: constants.GET_PARTNERS_LIST_FAIL };
  }
}

function sortOrderHistoryList(orderHistoryList, sortOn, sortBy) {
  return (dispatch) => {
    const updatedOrderHistoryList =
      orderManagementServices.sortOrderHistoryList(
        orderHistoryList.data,
        sortOn,
        sortBy
      );
    dispatch(
      getOrderHistoryListSuccess({
        ...orderHistoryList,
        data: updatedOrderHistoryList,
      })
    );
  };

  function getOrderHistoryListSuccess(data) {
    return { type: constants.GET_ORDER_HISTORY_LIST_SUCCESS, data };
  }
}

function searchForOrder(orderId, filters) {
  return (dispatch) => {
    dispatch(startLoader());
    orderManagementServices.searchForOrder(orderId, filters).then(
      (data) => {
        if (data?.data) {
          data.data = sort(data.data).asc((u) => u.partner_id);
        }
        dispatch(stopLoader());
        dispatch(getOrderDetailsByIDSuccess(data));
      },
      (error) => {
        dispatch(getOrderDetailsByIDFail());
        dispatch(stopLoader());
      }
    );
  };

  function getOrderDetailsByIDSuccess(data) {
    return { type: constants.GET_ORDER_DETAILS_BY_ID_SUCCESS, data };
  }
  function getOrderDetailsByIDFail() {
    return { type: constants.GET_ORDER_DETAILS_BY_ID_FAIL };
  }
}
function filterOrderHistory(filterOn = [], isDueToCleanup) {
  if (isDueToCleanup) {
    return { type: constants.SET_FILTER_ON_ORDER_HISTORY, data: filterOn };
  }
  return (dispatch) => {
    dispatch(startLoader());
    orderManagementServices.filterOrderHistory(filterOn).then(
      (data) => {
        dispatch(stopLoader());
        dispatch(setFilterOnOrderHistory(filterOn));
        dispatch(getOrderHistoryListSuccess(data));
      },
      (error) => {
        dispatch(getOrderHistoryListFail());
        dispatch(stopLoader());
        dispatch(showErrorPage(error, 'Filter order history'));
      }
    );
  };
  function getOrderHistoryListSuccess(data) {
    return { type: constants.GET_ORDER_HISTORY_LIST_SUCCESS, data };
  }
  function getOrderHistoryListFail() {
    return { type: constants.GET_ORDER_HISTORY_LIST_FAIL };
  }
  function setFilterOnOrderHistory(data) {
    return { type: constants.SET_FILTER_ON_ORDER_HISTORY, data };
  }
}

function saveIncomingOrderNewNotification(notificationList) {
  return {
    type: constants.GET_INCOMING_ORDERS_NOTIFICATION,
    data: notificationList,
  };
}
function saveCancelledOrderNewNotification(notificationList) {
  return {
    type: constants.GET_JUST_CANCELLED_ORDER_NOTIFICATION,
    data: notificationList,
  };
}
function clearAllSelections() {
  return { type: constants.CLEAR_ALL_SELECTIONS };
}

function clearAllAuthInfo() {
  return { type: constants.CLEAR_ALL_AUTH_INFO };
}
function getNotificationsList() {
  return (dispatch) => {
    dispatch(startLoader());
    orderManagementServices.getNotificationsList().then(
      (data) => {
        dispatch(stopLoader());
        dispatch(getNotificationsListSuccess(data));
      },
      (error) => {
        dispatch(getNotificationsListFail());
        dispatch(stopLoader());
        dispatch(showErrorPage(error, 'Notification List'));
      }
    );
  };
  function getNotificationsListSuccess(data) {
    return { type: constants.GET_NOTIFICATION_LIST_SUCCESS, data };
  }
  function getNotificationsListFail() {
    return { type: constants.GET_NOTIFICATION_LIST_FAIL };
  }
}
function updateOrderStatus(payload) {
  return (dispatch) => {
    dispatch(startLoader());
    return orderManagementServices.updateOrderStatus(payload).then(
      (data) => {
        dispatch(stopLoader());
        dispatch(updateOrderStatusSuccess(data));
        return data;
      },
      (error) => {
        dispatch(updateOrderStatusFail());
        dispatch(stopLoader());
        dispatch(showErrorPage(error, 'Update Order status'));
      }
    );
  };
  function updateOrderStatusSuccess(data) {
    return { type: constants.UPDATE_ORDER_STATUS_SUCCESS, data };
  }
  function updateOrderStatusFail() {
    return { type: constants.UPDATE_ORDER_STATUS_FAIL };
  }
}
function updateNotificationStatus(payload) {
  return (dispatch) => {
    dispatch(startLoader());
    return orderManagementServices.updateNotificationStatus(payload).then(
      (data) => {
        dispatch(stopLoader());
        dispatch(updateNotificationStatusSuccess(data));
        return data;
      },
      (error) => {
        dispatch(updateNotificationStatusFail());
        dispatch(stopLoader());
        //	dispatch(showErrorPage(error, 'Update notification status'));
      }
    );
  };
  function updateNotificationStatusSuccess(data) {
    return { type: constants.UPDATE_NOTIFICATION_STATUS_SUCCESS, data };
  }
  function updateNotificationStatusFail() {
    return { type: constants.UPDATE_NOTIFICATION_STATUS_FAIL };
  }
}
function getNotifiedItemDetails(payload) {
  return (dispatch) => {
    dispatch(startLoader());
    return orderManagementServices.getNotifiedItemDetails(payload).then(
      (data) => {
        dispatch(stopLoader());
        dispatch(getNotifiedItemDetailsSuccess(data));
        return data;
      },
      (error) => {
        dispatch(getNotifiedItemDetailsFail());
        dispatch(stopLoader());
        //	dispatch(showErrorPage(error, 'Notified item details'));
      }
    );
  };
  function getNotifiedItemDetailsSuccess(data) {
    return { type: constants.GET_NOTIFIED_ITEM_DETAILS_SUCCESS, data };
  }
  function getNotifiedItemDetailsFail() {
    return { type: constants.GET_NOTIFIED_ITEM_DETAILS_FAIL };
  }
}
function getSingleItemDetails(payload) {
  return (dispatch) => {
    dispatch(startLoader());
    return orderManagementServices.getSingleItemDetails(payload).then(
      (data) => {
        dispatch(stopLoader());
        dispatch(getSingleItemDetailsSuccess(data));
        const order = data.data[0];
        dispatch(storeOrderDetails(order));
        return data;
      },
      (error) => {
        dispatch(getSingleItemDetailsFail());
        dispatch(stopLoader());
        dispatch(showErrorPage(error, 'Single Item Details'));
      }
    );
  };
  function getSingleItemDetailsSuccess(data) {
    return { type: constants.GET_SINGLE_ITEM_DETAILS_SUCCESS, data };
  }
  function getSingleItemDetailsFail() {
    return { type: constants.GET_SINGLE_ITEM_DETAILS_FAIL };
  }
}
function getOrderReportCount() {
  return (dispatch) => {
    dispatch(startLoader());
    orderManagementServices.getOrderReportCount().then(
      (data) => {
        dispatch(stopLoader());
        dispatch(getOrderReportCountSuccess(data));
      },
      (error) => {
        dispatch(getOrderReportCountFail());
        dispatch(stopLoader());
        //	dispatch(showErrorPage(error, 'Get report count'));
      }
    );
  };
  function getOrderReportCountSuccess(data) {
    return { type: constants.GET_REPORT_COUNT_SUCCESS, data };
  }
  function getOrderReportCountFail() {
    return { type: constants.GET_REPORT_COUNT_FAIL };
  }
}

function updateEditedOrders(payload) {
  return (dispatch) => {
    dispatch(startLoader());
    return orderManagementServices.updateEditedOrders(payload).then(
      (data) => {
        dispatch(stopLoader());
        dispatch(updateEditedOrdersSuccess());
        return data;
      },
      (error) => {
        dispatch(updateEditedOrdersFail());
        dispatch(stopLoader());
        dispatch(showErrorPage(error, 'Update Edited Order'));
      }
    );
  };
  function updateEditedOrdersSuccess() {
    return { type: constants.UPDATE_EDIT_ORDER_SUCCESS };
  }
  function updateEditedOrdersFail() {
    return { type: constants.UPDATE_EDIT_ORDER_FAIL };
  }
}
function setAutoPrintOption(printOption) {
  return { type: constants.STORE_PRINT_OPTION, printOption };
}
function showEditedOrders(editedOrder) {
  return { type: constants.SHOW_EDITED_ORDER, editedOrder };
}
function trigerEditOrders(trigerEditOrders) {
  return { type: constants.TRIGER_EDIT_ORDERS, trigerEditOrders };
}
function storePartiallyAcceptedItem(item) {
  return { type: constants.STORE_PARTIALLY_ACCEPTED_ITEM, item };
}
function setShowTopMsg(data) {
  return { type: constants.SET_SHOW_TOP_MSG, data };
}
function getLoginUserDetails(payload) {
  return (dispatch) => {
    dispatch(startLoader());
    return orderManagementServices.getLoginUserDetails(payload).then(
      (data) => {
        dispatch(stopLoader());
        dispatch(getLoginUserDetailsSuccess());
        dispatch(storeAccessToken(data.access_token));
        return data;
      },
      (error) => {
        dispatch(getLoginUserDetailsFail());
        dispatch(stopLoader());
        dispatch(showErrorPage(error, 'User Login'));
      }
    );
  };
  function getLoginUserDetailsSuccess() {
    return { type: constants.GET_USER_DETAILS_SUCCESS };
  }
  function getLoginUserDetailsFail() {
    return { type: constants.GET_USER_DETAILS_FAIL };
  }
}
function storeAccessToken(token) {
  return { type: constants.SAVE_ACCESS_TOKEN, token };
}
function cancelIcomingOrder(payload) {
  return (dispatch) => {
    dispatch(startLoader());
    orderManagementServices.cancelIcomingOrder(payload).then(
      (data) => {
        dispatch(stopLoader());
        dispatch(cancelIcomingOrderSuccess());
      },
      (error) => {
        dispatch(cancelIcomingOrderFail());
        dispatch(stopLoader());
        dispatch(showErrorPage(error, 'Cancelled Incoming Orders'));
      }
    );
  };
  function cancelIcomingOrderSuccess() {
    return { type: constants.CANCEL_INCOMING_ORDER_SUCCESS };
  }
  function cancelIcomingOrderFail() {
    return { type: constants.CANCEL_INCOMING_ORDER_FAIL };
  }
}
function setAllNotificationCount(data) {
  return { type: constants.SET_ALL_NOTIFICATION_COUNT, data };
}
function getAllNotificationsList() {
  return async (dispatch) => {
    dispatch(startLoader());
    orderManagementServices.getAllNotificationsList().then(
      (data) => {
        if (data?.length > 0) {
          dispatch(stopLoader());
          dispatch(recentNotifications(data[0]));
          dispatch(allNotifications(data[1]));
          dispatch(allNotificationsCount(data[2]));
        }
      },
      (error) => {
        dispatch(stopLoader());
        dispatch(recentNotifications([]));
        dispatch(allNotifications([]));
        dispatch(allNotificationsCount(0));
        dispatch(showErrorPage(error, 'Notification List'));
      }
    );
  };

  function recentNotifications(data) {
    return { type: constants.SET_RECENT_NOTIFICATIONS_LIST, data };
  }
  function allNotifications(data) {
    return { type: constants.SET_ALL_NOTIFICATIONS_LIST, data };
  }
  function allNotificationsCount(data) {
    return { type: constants.SET_ALL_NOTIFICATION_COUNT, data };
  }
}
function logoutFromApplication() {
  return (dispatch) => {
    dispatch(startLoader());
    orderManagementServices.logoutFromApplication().then(
      (data) => {
        dispatch(stopLoader());
        dispatch(logoutFromApplicationSuccess());
      },
      (error) => {
        dispatch(logoutFromApplicationFail());
        dispatch(stopLoader());
      }
    );
  };
  function logoutFromApplicationSuccess() {
    return { type: constants.LOGOUT_FROM_APPLICATION_SUCCESS };
  }
  function logoutFromApplicationFail() {
    return { type: constants.LOGOUT_FROM_APPLICATION_FAIL };
  }
}

function sortOrderItemList(orderItemList, sortOn, sortBy) {
  return (dispatch) => {
    const updatedOrderItemList = orderManagementServices.sortOrderHistoryList(
      orderItemList,
      sortOn,
      sortBy
    );
    dispatch(
      getOrderItemListSuccess({
        ...orderItemList,
        data: updatedOrderItemList,
      })
    );
  };

  function getOrderItemListSuccess(data) {
    return { type: constants.GET_ORDER_LIST_SUCCESS, data };
  }
}

function setCurrentPath(path) {
  return { type: constants.SET_CURRENT_PATH, path };
}
function getMyProfileDetails() {
  return (dispatch) => {
    dispatch(startLoader());
    return orderManagementServices.getMyProfileDetails().then(
      (data) => {
        dispatch(stopLoader());
        dispatch(getMyProfileDetailsSuccess(data));
        return {
          success: true,
        };
      },
      (error) => {
        dispatch(getMyProfileDetailsFail());
        dispatch(showErrorPage(error, 'Logout application'));
        dispatch(stopLoader());
      }
    );
  };
  function getMyProfileDetailsSuccess(data) {
    return { type: constants.GET_PROFILE_DETAILS_SUCCESS, data };
  }
  function getMyProfileDetailsFail() {
    return { type: constants.GET_PROFILE_DETAILS_FAIL };
  }
}
function getIncomingOrdersBackup() {
  return (dispatch) => {
    dispatch(startLoader());
    orderManagementServices.getIncomingOrders().then(
      (data) => {
        dispatch(stopLoader());
        dispatch(getIncomingOrdersSuccessTest(data));
      },
      (error) => {
        dispatch(getIncomingOrdersFailTest());
        dispatch(stopLoader());
        dispatch(showErrorPage(error, 'Incoming Orders'));
      }
    );
  };
  function getIncomingOrdersSuccessTest(data) {
    return { type: constants.GET_BACKUP_INCOMING_ORDERS_SUCCESS, data };
  }
  function getIncomingOrdersFailTest() {
    return { type: constants.GET_BACKUP_INCOMING_ORDERS_FAIL };
  }
}
function removeDeviceToken(device_token) {
  return (dispatch) => {
    dispatch(startLoader());
    return orderManagementServices.removeDeviceToken(device_token).then(
      (data) => {
        // dispatch(stopLoader());
        dispatch(removeDeviceTokenSuccess());
        return 'success';
      },
      (error) => {
        dispatch(clearAllSelections());
        dispatch(clearAllAuthInfo());
        dispatch(removeDeviceTokenFail());
        // dispatch(stopLoader());
        return 'fail';
      }
    );
  };
  function removeDeviceTokenSuccess() {
    return { type: constants.REMOVE_DEVICE_TOKEN_SUCCESS };
  }
  function removeDeviceTokenFail() {
    return { type: constants.REMOVE_DEVICE_TOKEN_FAIL };
  }
}
function saveHeaderMsgOrderId(orderId) {
  return { type: constants.SAVE_HEADER_MSG_ORDER_ID, orderId };
}

function setShowMenuPopUp(data) {
  return { type: constants.SET_SHOW_MENU_POPUP, data };
}

function getSearchQuery(queryText) {
  return (dispatch) => {
    dispatch(startLoader());
    return orderManagementServices.getSearchQuery(queryText).then(
      (data) => {
        dispatch(stopLoader());
        dispatch(getSearchResults(data));
      },
      (error) => {
        dispatch(getSearchNoResults());
        dispatch(stopLoader());
      }
    );
  };
  function getSearchResults(data) {
    return { type: constants.GET_MENU_SEARCH_RESULTS, data };
  }
  function getSearchNoResults() {
    return { type: constants.GET_MENU_SEARCH_RESULTS_FAIL };
  }
}

function setShowMenuPopUpModifier(data) {
  return { type: constants.SET_SHOW_MENU_POPUP_MODIFIER, data };
}

function toggleShowOrderSummary(data) {
  return { type: constants.SET_TOGGLE_ORDER_SUMMARY, data };
}
// It is not an action creator. It is a helper function for "completeOrder"  and "cancelOrder" action creator;
function getPartnerOrderIdList(completedOrdersList = []) {
  return completedOrdersList.map((order) => {
    if (Object.values(order)[0]?.status) {
      return Object.values(order)[0]?.partner_id;
    }
    return null;
  });
}
function completeOrder(orderList) {
  return (dispatch) => {
    dispatch(startLoader());
    return orderManagementServices.completeOrder(orderList).then(
      (data) => {
        dispatch(stopLoader());
        dispatch(completeOrderSuccess(getPartnerOrderIdList(data?.data)));
        dispatch(showBarNotification(getPartnerOrderIdList(data?.data)));
      },
      (error) => {
        dispatch(completeOrderFail());
        dispatch(stopLoader());
        dispatch(showErrorPage(error, 'Complete Orders'));
      }
    );
  };
  function completeOrderSuccess(data) {
    return { type: constants.COMPLETE_ORDER_SUCCESS, data };
  }
  function completeOrderFail() {
    return { type: constants.COMPLETE_ORDER_FAIL };
  }

  function showBarNotification(partnerIdList = []) {
    const headerMsgPayload = {
      show: false,
      type: 'Completed',
      msg: '',
      orderId: '',
      partnerId: '',
    };
    if (partnerIdList.length === 1 && partnerIdList[0] !== null) {
      headerMsgPayload.show = true;
      headerMsgPayload.partnerId = partnerIdList[0].toUpperCase();
    } else if (partnerIdList.length > 1) {
      headerMsgPayload.show = true;
      headerMsgPayload.type = 'multipleCompleted';
    }
    return { type: constants.SET_SHOW_TOP_MSG, data: headerMsgPayload };
  }
}

function cancelOrder(orderList) {
  return (dispatch) => {
    dispatch(startLoader());
    return orderManagementServices.cancelOrder(orderList).then(
      (data) => {
        dispatch(stopLoader());
        dispatch(cancelOrderSuccess(getPartnerOrderIdList(data?.data)));
        dispatch(showBarNotification(getPartnerOrderIdList(data?.data)));
      },
      (error) => {
        dispatch(cancelOrderFail());
        dispatch(stopLoader());
        dispatch(showErrorPage(error, 'Cancel Orders'));
      }
    );
  };
  function showBarNotification(partnerIdList = []) {
    const partnerId =
      partnerIdList.length === 1 && partnerIdList !== null
        ? partnerIdList[0]
        : '';
    const headerMsgPayload = {
      show: false,
      type: 'Move To Order History',
      msg: '',
      orderId: '',
      partnerId: '',
    };
    if (partnerId !== '') {
      headerMsgPayload.show = true;
      headerMsgPayload.partnerId = partnerId.toUpperCase();
    }
    return { type: constants.SET_SHOW_TOP_MSG, data: headerMsgPayload };
  }
}

function cancelAcceptedOrder(payload, partnerId) {
  return (dispatch) => {
    dispatch(startLoader());
    return orderManagementServices.cancelAcceptedOrder(payload).then(
      (data) => {
        dispatch(stopLoader());
        dispatch(cancelOrderSuccess([partnerId]));
        dispatch(showBarNotification(partnerId, payload.id));
      },
      (error) => {
        dispatch(cancelOrderFail());
        dispatch(stopLoader());
        dispatch(showErrorPage(error, 'Cancel Orders'));
      }
    );
  };
  function showBarNotification(partnerId, id) {
    const headerMsgPayload = {
      show: false,
      type: 'Cancelled',
      msg: '',
      orderId: '',
      partnerId: '',
    };
    if (partnerId !== '') {
      headerMsgPayload.show = true;
      headerMsgPayload.partnerId = partnerId.toUpperCase();
      headerMsgPayload.orderId = id;
    }
    return { type: constants.SET_SHOW_TOP_MSG, data: headerMsgPayload };
  }
}

function cancelOrderSuccess(data) {
  return { type: constants.CANCEL_ORDER_SUCCESS, data };
}
function cancelOrderFail() {
  return { type: constants.CANCEL_ORDER_FAIL };
}

function setShouldShowEditOrderViewInOrderDetailsPage(data) {
  return {
    type: constants.SET_SHOULD_SHOW_EDIT_ORDER_VIEW_IN_ORDER_DETAILS_PAGE,
    data,
  };
}

function setIncomingOrderList(incomingOrder) {
  return {
    type: constants.GET_INCOMING_ORDERS_SUCCESS,
    data: { data: incomingOrder },
  };
}
function setIsResumeBtnDisabled(btnState) {
  return { type: constants.SET_IS_RESUME_BTN_DISABLED, data: btnState };
}
