import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import UberEatsLogo from '../../images/UberEats.svg';
import menulogLogo from '../../images/menuLog.svg';
import doordashLogo from '../../images/Doordash.svg';
import collapseArrow from '../../images/collapseArrow.svg';
import expandArrow from '../../images/expandArrow.svg';
import SVTOrderCountDown from '../../services/SVTOrderCountDown';

function SVTIncomingOrderCardTopView(props) {
  const {
    isExpanded,
    setIsExpanded,
    partnerName,
    partnerId,
    totalItems,
    orderReceiveOnTime,
  } = props;
  const listOfVenderSupportPartialAcceptance = [''];
  const imageList = {
    'uber eats': UberEatsLogo,
    menulog: menulogLogo,
    doordash: doordashLogo,
  };

  return (
    <Container
      style={{ backgroundColor: '#EBEBEB' }}
      className='pb-20 cursor-pointer'
      onClick={() => setIsExpanded(!isExpanded)}
    >
      <Row>
        <Col sm={1} className='mt-20'>
          <img
            src={imageList[partnerName.toLowerCase()]}
            alt='Aggregator Logo'
            width={'34px'}
            height={'34px'}
          />
        </Col>
        <Col sm={10} className='mt-20'>
          <main className='ml-12'>
            <section className='id_style'>#{partnerId}</section>
            <section className='small_text gray_text' data-test='acceptedMsg'>
              {listOfVenderSupportPartialAcceptance.includes(
                partnerName.toLowerCase()
              ) ? null : (
                <span>Auto-Accepted at </span>
              )}
              {orderReceiveOnTime}
              {listOfVenderSupportPartialAcceptance.includes(
                partnerName.toLowerCase()
              ) ? (
                <span>
                  &nbsp;
                  <SVTOrderCountDown date={orderReceiveOnTime} />
                </span>
              ) : null}
            </section>

            <section
              className='total-item-count-in-incoming-order-card mt-2'
              data-test='totalItems'
            >
              {totalItems} {totalItems > 1 ? 'items' : 'item'}
            </section>
          </main>
        </Col>
        <Col sm={1} className='mt-20'>
          <img
            src={isExpanded ? expandArrow : collapseArrow}
            alt='Expand Arrow'
            width={'24px'}
            height={'24px'}
            className='cursor-pointer'
          />
        </Col>
      </Row>
    </Container>
  );
}

export default SVTIncomingOrderCardTopView;
