import React, { useEffect, useState } from 'react';
import { Offcanvas, Row, Col, Container } from 'react-bootstrap';
import info_icon from '../../images/info.svg';
import green_outline_cross_icon from '../../images/bp-icon-crossmark-green.svg';
import close from '../../images/close.svg';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { orderManagementActions } from '../../action/SVTOrderManagementAction';

import order_received from '../../images/order_received.svg';
import order_completed from '../../images/order_completed.svg';

import bellIcon from '../../images/Notification ALT.svg';
function SVTNotificationComponent(props) {
	useEffect(() => {
		if (props?.allNotificationsList) {
			setAllNotificationList(props?.allNotificationsList);
		}
		if (props.recentNotificationsList) {
			setRecentNotificationList(props.recentNotificationsList);
		}
	}, [props?.allNotificationsList, props.recentNotificationsList]);
	const [activeLink, setActiveLink] = React.useState({
		recent: true,
		all: false,
	});
	// const [hide, setOnHide] = useState(false);
	// const handleOnHide = () => setOnHide(!hide);
	const [recentNotificationList, setRecentNotificationList] = useState([]);
	const [allNotificationList, setAllNotificationList] = useState([]);
	const clearNotification = async item => {
		const payload = {
			id: item.id,
			status: 'Deactive',
		};
		const notificationStatus = await props.updateNotificationStatus(payload);
		if (notificationStatus?.status === 'SUCCESS') {
			props.getAllNotificationsList();
		}
	};
	const viewItemDetails = async item => {
		props.onHide();
		clearNotification(item);
		const queryString = `id=${item.order_id}`;
		const itemDetails = await props.getSingleItemDetails(queryString);
		if (itemDetails?.status === 'success') {
			props.getAllNotificationsList();
			props.history.push('/order-details/Incoming');
		}
	};
	const clearAllNotification = async () => {
		let payload = {};
		if (activeLink.all) {
			payload = {
				id: '*',
				status: 'Deactive',
			};
		} else if (activeLink.recent) {
			let ids = '';
			for (let index = 0; index < recentNotificationList.length; index++) {
				ids += recentNotificationList[index]['id'] + ', ';
				payload = {
					id: ids,
					status: 'Deactive',
				};
			}
		}
		const notificationStatus = await props.updateNotificationStatus(payload);
		if (notificationStatus?.status === 'SUCCESS') {
			props.getAllNotificationsList();
		}
	};
	React.useEffect(() => {
		const allNotificationCount =
			recentNotificationList.length + allNotificationList.length;
		if (props.setAllNotificationCount) {
			props.setAllNotificationCount(allNotificationCount);
		}
	}, [recentNotificationList.length, allNotificationList.length]);

	return (
		<>
			<Offcanvas
				show={props.show}
				onHide={props.onHide}
				placement='end'
				className='notification-box'
				backdrop={true}
				backdropClassName='offcanvas-backdrop'
			>
				<Offcanvas.Header className='gray-bg m-0 p-0 ps-4 pt-4 pe-5 '>
					<Container fluid className='gray-bg m-0 p-0'>
						<Row>
							<Col md={11} xs={10} className='pt-2 notifications-main-font'>
								Notifications
							</Col>
							<Col md={1} xs={2}>
								<img
									src={close}
									alt='close_icon'
									onClick={props.onHide}
									className='cursor-pointer'
								/>
							</Col>
						</Row>
						<Row className='pt-4 pe-3 notification-row-clear-all-spacing'>
							<Col md={3} xs={4} sm={4}>
								<div
									className={
										'd-flex pb-3 maxWidthAuto ' +
										(activeLink.recent ? 'active-main-header-link' : '')
									}
								>
									<div
										className='pt-1 cursor-pointer'
										onClick={() =>
											setActiveLink({
												recent: true,
												all: false,
											})
										}
									>
										Recent
									</div>
									<div
										className={
										recentNotificationList.length
											? "notification_style green_background-color ms-2"
											: "notification_style ms-2 pale-grey-bg"
										}
									>
										{recentNotificationList.length}
									</div>
								</div>
							</Col>
							<Col md={2} xs={4} sm={4}>
								<div
									className={
										'd-flex pb-3 maxWidthAuto ' +
										(activeLink.all ? 'active-main-header-link' : '')
									}
								>
									<div
										className='pt-1 cursor-pointer'
										onClick={() =>
											setActiveLink({
												recent: false,
												all: true,
											})
										}
									>
										All
									</div>
									<div className="notification_style pale-grey-bg ms-2">
										{allNotificationList.length}
									</div>
								</div>
							</Col>
							<Col md={5} xs={1} sm={1}></Col>
							<Col md={2} sm={3} xs={3} className='pb-2'>
							{
								activeLink.all && allNotificationList.length > 0 ? 
								(
									<button
										className="gray-outline-btn gray-outline-btn-txt"
										onClick={() => {
										clearAllNotification();
										}}
									>
										Clear All
									</button>
								) : 
								activeLink.recent && recentNotificationList.length > 0 ? 
								(
									<button
										className='gray-outline-btn gray-outline-btn-txt'
										onClick={() => {
											clearAllNotification();
										}}
									>
										Clear All
									</button>
								): 
								(
									""
								)
							}
							</Col>
						</Row>
					</Container>
				</Offcanvas.Header>
				<Offcanvas.Body className='m-0 p-0 scroller-notification spacing-scroller'>
					{activeLink.recent ? (
						recentNotificationList.length != 0 ? (
							recentNotificationList.map((item,index) => (
								<div className='pt-3 ps-4 pe-4' id={`${item?.partner_id}_${index}`}>
									<NoticationCard
										item={item}
										clearNotification={clearNotification}
										viewItemDetails={viewItemDetails}
									/>
								</div>
							))
						) : (
							<NoNotificationCard />
						)
					) : (
						''
					)}
					{activeLink.all ? (
						allNotificationList.length != 0 ? (
							allNotificationList.map((item,index) => (
								<div className='pt-3 ps-4 pe-4' id={`${item?.partner_id}_${index}`}>
									<NoticationCard
										item={item}
										clearNotification={clearNotification}
										viewItemDetails={viewItemDetails}
									/>
								</div>
							))
						) : (
							<NoNotificationCard />
						)
					) : (
						''
					)}
				</Offcanvas.Body>
			</Offcanvas>
		</>
	);
}

function mapState(state) {
	const { orderManagementRelated } = state;
	const {
		recentNotificationsList,
		allNotificationsList,
	} = orderManagementRelated;
	return {
		recentNotificationsList,
		allNotificationsList,
	};
}
const actionCreators = {
	updateNotificationStatus: orderManagementActions.updateNotificationStatus,
	getSingleItemDetails: orderManagementActions.getSingleItemDetails,
	setAllNotificationCount: orderManagementActions.setAllNotificationCount,
	getAllNotificationsList: orderManagementActions.getAllNotificationsList,
};

export default compose(
	withRouter,
	connect(mapState, actionCreators),
)(SVTNotificationComponent);

function extractOrderID(message, orderId) {
	let i = 0;
	const return_array_data = [];
	const split_data = message.split(' ');
	let m_prior = '';
	let m_after = '';
	for (i = 0; i < split_data.indexOf(orderId); i++) {
		m_prior += split_data[i];
		m_prior += ' ';
	}
	i += 1;
	for (; i < split_data.length; i++) {
		m_after += split_data[i];
		m_after += ' ';
	}
	if (m_after.endsWith(' ')) {
		m_after = m_after.trimRight();
	}
	return_array_data.push(m_prior);
	return_array_data.push(orderId);
	return_array_data.push(m_after);
	return return_array_data;
}

function formatMessage(message) {
	let orderId = '';
	let messageBody = '';
	const messageArr = message.split(':');
	orderId = messageArr[messageArr.length - 1]?.trim();
	messageBody = messageArr[0];
	if (messageBody.includes('received')) {
		messageBody = (
			<span>
				A new order <strong>#{orderId + ' '}</strong>
				has arrived.
			</span>
		);
	} else if (message.includes('cancelled')) {
		messageBody = (
			<span>
				Order <strong>#{orderId + ' '}</strong>
				has been cancelled.
			</span>
		);
	} else {
		messageBody = (
			<span>
				{messageArr[0] + ': '} <strong> #{orderId}.</strong>
			</span>
		);
	}
	return messageBody;
}
function NoticationCard(props) {
	const itemVal = props.item;
	// let tdata = extractOrderID(itemVal.message, itemVal.order_id);
	const notificationText = formatMessage(itemVal.message);
	return (
		<>
			<Container fluid className='pb-3 border-below ms-2'>
				<Row>
					<Col xs={12} className='notification-card-time px-0'>
					{
						itemVal.message.toLowerCase().includes('completed') ?
							<>{itemVal.time} • SILENT NOTIFICATION</>
						:
							itemVal.message.toLowerCase().includes('received') ?
								<>{itemVal.time} • SILENT NOTIFICATION</>
							:
							<>{itemVal.time}</>
					}
					</Col>
				</Row>
				<Row className='py-1'>
					<Col md={1} className='pt-2'>
						{
						itemVal.message.toLowerCase().includes('completed') ?
							<img src={order_completed} alt='cross' width='36px' height='36px' />
							:
							itemVal.message.toLowerCase().includes('received') ?
								<img src={order_received} alt='cross' width='36px' height='36px' />
							:
						 		<img src={info_icon} alt='cross' width='35.82px' height='35.82px' />
						}
					</Col>
					<Col md={10}>
						<div className={itemVal.message.includes('received')?'pb-15 pt-15':''}>{notificationText}</div>
						{!itemVal.message.includes('received') && <a
							className='green-text text-decoration-none cursor-pointer'
							onClick={() => props.viewItemDetails(itemVal)}
						>
							Tap to view.
						</a>}
					</Col>
					<Col md={1}>
						<img
							src={green_outline_cross_icon}
							alt='cross'
							onClick={() => props.clearNotification(itemVal)}
						/>
					</Col>
				</Row>
			</Container>
		</>
	);
}

function NoNotificationCard() {
	return (
		<div className='text-center ps-2' style={{ paddingTop: '80px' }}>
			<span className='pe-2'>
				<img src={bellIcon} alt='bell' />
			</span>
			<span className='no-notification-text'>No notifications</span>
		</div>
	);
}
