import { constants } from '../constant/reducerConstant';

const initialState = {
	orderList: [],
	selectedOrderDetails: {},
	orderHistoryList: [],
	selectedHistoryDetails: {},
	justCancelledOrderList: [],
	justCancelledSelectedOrderDetails: {},
	incomingOrders: [],
	deviceTokenDetails: undefined,
	orderHistoryFilter: [],
	orderByIdList: [],
	partnerList: [],
	incomingOrderNotifications: [],
	cancelledOrderNotifications: [],
	notificationList: [],
	updateOrderStatus: [],
	updateNotificationStatus: [],
	storeDetails: {},
	orderReportListCount: [],
	showPrintOption: false,
	updatedEditOrder: [],
	showEditedOrder: false,
	storePartiallyAcceptedItems: [],
	showEditOrder: false,
	showTopMsg: { show: false, type: '', msg: '', orderId: '' },
	userDetails: [],
	cancelIncomingOrder: [],
	totalNotificationCount: 0,
	recentNotificationsList: [],
	allNotificationsList: [],
	logoutApp: [],
	currentPath: '',
	profileDetails:[],
	backupIncomingOrder: [],
	storeDeviceToken:'',
	currentHeaderMsgOrderId:'',
	showMenuPopUp:false,
	menuSearchResults:[],
	showMenuPopUpModifier:false,
	trigerEditOrder:false,
	shouldShowSummary:true,
	completeOrder: [],
	cancelOrder: [],
	shouldShowEditOrderViewInOrderDetailsPage:false,
	isResumeBtnDisabled:false
};

export function orderManagementRelated(state = initialState, action) {
	switch (action.type) {
		case constants.GET_ORDER_LIST_SUCCESS:
			return {
				...state,
				orderList: action?.data?.data ? action.data.data : action.data,
			};
		case constants.GET_ORDER_LIST_FAIL:
			return {
				...state,
				orderList: [],
			};
		case constants.STORE_ORDER_DETAILS:
			return {
				...state,
				selectedOrderDetails: action.order,
			};
		case constants.GET_ORDER_HISTORY_LIST_SUCCESS:
			return {
				...state,
				orderHistoryList: action.data,
			};
		case constants.GET_ORDER_HISTORY_LIST_FAIL:
			return {
				...state,
				orderHistoryList: [],
			};
		case constants.STORE_ORDER_HISTOY_DETAILS:
			return {
				...state,
				selectedHistoryDetails: action.order,
			};
		case constants.GET_JUST_CANCELLED_ORDER_LIST_SUCCESS:
			const justCancelledOrderList = action.data?.data.filter(
				order => order.dismiss === 'false',
			);
			return {
				...state,
				justCancelledOrderList: justCancelledOrderList,
			};
		case constants.GET_JUST_CANCELLED_ORDER_LIST_FAIL:
			return {
				...state,
				justCancelledOrderList: [],
			};
		case constants.DISMISS_JUST_CANCELLED_ORDER_SUCCESS:
			return {
				...state,
			};
		case constants.DISMISS_JUST_CANCELLED_ORDER_FAIL:
			return {
				...state,
			};
		case constants.JUST_CANCELLED_ORDER_DETAILS:
			return {
				...state,
				justCancelledSelectedOrderDetails: action.order,
			};
		case constants.GET_INCOMING_ORDERS_SUCCESS:
			return {
				...state,
				incomingOrders: action.data.data,
			};
		case constants.GET_INCOMING_ORDERS_FAIL:
			return {
				...state,
				incomingOrders: [],
			};
		case constants.GET_STORE_DETAILS_SUCCESS:
			return {
				...state,
				storeDetails: action.data.store_data[0],
			};
		case constants.GET_STORE_DETAILS_FAIL:
			return {
				...state,
				storeDetails: {},
			};
		case constants.GET_DEVICE_TOKEN_SUCCESS:
			return {
				...state,
				deviceTokenDetails: action.data,
			};
		case constants.GET_DEVICE_TOKEN_FAIL:
			return {
				...state,
				deviceTokenDetails: [],
			};
		case constants.GET_ORDER_DETAILS_BY_ID_SUCCESS:
			return {
				...state,
				orderHistoryList: action.data,
			};
		case constants.GET_ORDER_DETAILS_BY_ID_FAIL:
			return {
				...state,
				orderHistoryList: [],
			};
		case constants.SET_FILTER_ON_ORDER_HISTORY:
			return {
				...state,
				orderHistoryFilter: action.data,
			};

		case constants.GET_PARTNERS_LIST_SUCCESS:
			return {
				...state,
				partnerList: action.data,
			};
		case constants.GET_PARTNERS_LIST_FAIL:
			return {
				...state,
				partnerList: [],
			};
		case constants.GET_INCOMING_ORDERS_NOTIFICATION:
			return {
				...state,
				incomingOrderNotifications: action.data,
			};
		case constants.GET_JUST_CANCELLED_ORDER_NOTIFICATION:
			return {
				...state,
				cancelledOrderNotifications: action.data,
			};
		case constants.GET_NOTIFICATION_LIST_SUCCESS:
			return {
				...state,
				notificationList: action.data,
			};
		case constants.GET_NOTIFICATION_LIST_FAIL:
			return {
				...state,
				notificationList: [],
			};
		case constants.UPDATE_ORDER_STATUS_SUCCESS:
			return {
				...state,
				updateOrderStatus: action.data,
			};
		case constants.UPDATE_ORDER_STATUS_FAIL:
			return {
				...state,
				updateOrderStatus: [],
			};
		case constants.UPDATE_NOTIFICATION_STATUS_SUCCESS:
			return {
				...state,
				updateNotificationStatus: action.data,
			};
		case constants.UPDATE_NOTIFICATION_STATUS_FAIL:
			return {
				...state,
				updateNotificationStatus: [],
			};
		case constants.GET_SINGLE_ITEM_DETAILS_SUCCESS:
			return {
				...state,
				getSingleItemDetails: action.data,
			};
		case constants.GET_SINGLE_ITEM_DETAILS_FAIL:
			return {
				...state,
				getSingleItemDetails: [],
			};
		case constants.GET_REPORT_COUNT_SUCCESS:
			return {
				...state,
				orderReportListCount: action.data,
			};
		case constants.GET_REPORT_COUNT_FAIL:
			return {
				...state,
				orderReportListCount: [],
			};
		case constants.UPDATE_EDIT_ORDER_SUCCESS:
			return {
				...state,
				updatedEditOrder: action.data,
			};
		case constants.UPDATE_EDIT_ORDER_FAIL:
			return {
				...state,
				updatedEditOrder: [],
			};
		case constants.SHOW_EDITED_ORDER:
			return {
				...state,
				showEditedOrder: action.editedOrder,
			};
		case constants.STORE_PARTIALLY_ACCEPTED_ITEM:
			return {
				...state,
				storePartiallyAcceptedItems: action.item,
			};
		case constants.CLEAR_ALL_SELECTIONS:
			return initialState;

		case constants.SET_SHOW_EDIT_ORDER_SUCCESS:
			return {
				...state,
				showEditOrder: true,
			};
		case constants.SET_SHOW_EDIT_ORDER_FAIL:
			return {
				...state,
				showEditOrder: false,
			};
		case constants.STORE_PRINT_OPTION:
			return {
				...state,
				showPrintOption: action.printOption,
			};
		case constants.SET_SHOW_TOP_MSG:
			return {
				...state,
				showTopMsg: action.data,
			};
		case constants.GET_USER_DETAILS_SUCCESS:
			return {
				...state,
				userDetails: action.data,
			};
		case constants.GET_USER_DETAILS_FAIL:
			return {
				...state,
				userDetails: [],
			};
		case constants.CANCEL_INCOMING_ORDER_SUCCESS:
			return {
				...state,
				cancelIncomingOrder: action.data,
			};
		case constants.CANCEL_INCOMING_ORDER_FAIL:
			return {
				...state,
				cancelIncomingOrder: [],
			};
		case constants.SET_ALL_NOTIFICATION_COUNT:
			return {
				...state,
				totalNotificationCount: action.data,
			};
		case constants.SET_RECENT_NOTIFICATIONS_LIST:
			return {
				...state,
				recentNotificationsList: action.data,
			};
		case constants.SET_ALL_NOTIFICATIONS_LIST:
			return {
				...state,
				allNotificationsList: action.data,
			};
		case constants.LOGOUT_FROM_APPLICATION_SUCCESS:
			return {
				...state,
				logoutApp: action.data,
			};
		case constants.LOGOUT_FROM_APPLICATION_FAIL:
			return {
				...state,
				logoutApp: [],
			};
		case constants.SET_CURRENT_PATH:
			return {
				...state,
				currentPath: action.path,
			};
		case constants.UPDATE_STORE_STATUS:
			const storeDetails = { ...state.storeDetails };
			storeDetails.store_status = action.storeStatus;
			return {
				...state,
				storeDetails: storeDetails,
			};
		case constants.GET_PROFILE_DETAILS_SUCCESS:
			return {
				...state,
				profileDetails: action.data,
			};
		case constants.GET_PROFILE_DETAILS_FAIL:
			return {
				...state,
				profileDetails: [],
			};		
		case constants.GET_BACKUP_INCOMING_ORDERS_SUCCESS:
			return {
				...state,
				backupIncomingOrder: action.data.data,
			};
		case constants.GET_BACKUP_INCOMING_ORDERS_FAIL:
			return {
				...state,
				backupIncomingOrder: [],
			};
        case constants.STORE_DEVICE_TOKEN:
			return {
				...state,
				storeDeviceToken: action.token,	
			}
		case constants.SAVE_HEADER_MSG_ORDER_ID:
			return{
				...state,
				currentHeaderMsgOrderId:action.orderId
			}
		case constants.SET_SHOW_MENU_POPUP:
			return{
				...state,
				showMenuPopUp:action.data
			}
		case constants.GET_MENU_SEARCH_RESULTS:
			return{
				...state,
				menuSearchResults:action.data
			}
		case constants.GET_MENU_SEARCH_RESULTS_FAIL:
			return{
				...state,
				menuSearchResults:[]
			}
		case constants.SET_SHOW_MENU_POPUP_MODIFIER:
			return{
				...state,
				showMenuPopUpModifier:action.data
			}
		case constants.TRIGER_EDIT_ORDERS:
			return {
				...state,
				trigerEditOrder: action.trigerEditOrders,
			}	
		case constants.SET_TOGGLE_ORDER_SUMMARY:
			return{
				...state,
				shouldShowSummary:action.data
			}
		case constants.COMPLETE_ORDER_SUCCESS:
			return{
				...state,
				completeOrder: action.data
			}
		case constants.COMPLETE_ORDER_FAIL:
			return{
				...state,
				completeOrder: []
			}
		case constants.CANCEL_ORDER_SUCCESS:
			return{
				...state,
				cancelOrder: action.data
			}
		case constants.CANCEL_ORDER_FAIL:
			return{
				...state,
				cancelOrder: []
			}
		case constants.SET_SHOULD_SHOW_EDIT_ORDER_VIEW_IN_ORDER_DETAILS_PAGE:
			return{
				...state,
				shouldShowEditOrderViewInOrderDetailsPage:action.data
			}
		case constants.SET_IS_RESUME_BTN_DISABLED:
			return{
				...state,
				isResumeBtnDisabled: action.data
			}
		default:
			return state;
	}
}
