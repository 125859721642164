import React, { useRef, useState, useEffect } from 'react';
import {
  Card,
  Row,
  Col,
  Accordion,
  useAccordionButton,
  Modal,
} from 'react-bootstrap';
import online_indicator from '../../images/online_indicator.svg';
import arrow_up from '../../images/arrow_up.svg';
import arrow_down from '../../images/arrow_down.svg';
import store_picture from '../../images/StorePicture.png';
import risk from '../../images/Risk.svg';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { orderManagementActions } from '../../action/SVTOrderManagementAction';
import { menuManagementAction } from '../../action/SVTMenuManagementAction';
import cross_icon from '../../images/cross_icon.svg';
import risk_icon from '../../images/Risk.svg';
import offline_indicator from '../../images/offline_indicator.svg';
import SVTHeaderComponent from './SVTHeaderComponent';
import { authActions } from '../../action/SVTAuthActions';
import SVTMainComponent from './SVTMainComponent';
import phone from '../../images/phone.svg';
import envelope from '../../images/Envelope.png';
import uberEats from '../../images/UberEats.svg';
import crossVector from '../../images/crossCircle.svg';
import warningIcon from '../../images/warningIcon.svg';
import menuLog from '../../images/menuLog.svg';
import checkmarkCircle from '../../images/checkmarkCircle.svg';
import viewModifierArrow from '../../images/viewModifierArrow.svg';
import aggregator_offline_indicator from '../../images/aggregator_offline_indicator.svg';
import DummyMenuItemImg from '../../images/DummyMenuItemImg.png';
import doordash from '../../images/Doordash.svg';
import RefreshIcon from '../../images/RefreshIcon.svg';

function SVTOrderSettingPage(props) {
  const [showPauseIncomingOrdersPopup, setPauseIncomingOrdersPopup] = useState({
    shouldShow: false,
    reason: '',
  });
  const [
    showPauseIncomingOrdersOthersPopup,
    setPauseIncomingOrdersOthersPopup,
  ] = useState(false);
  const [showLogoutPopup, setLogoutPopup] = useState(false);
  const [cancelReason, setCancelReason] = useState('');
  // Use to track weather the accordion is open or collapse
  const accordionFirst = useRef();
  const accordionSecond = useRef();

  const [showPauseOrders, setShowPauseOrders] = useState(true);
  const [showMore, setShowMore] = useState(false);

  const [
    storesHavingValidAggregatorStatus,
    setStoresHavingValidAggregatorStatus,
  ] = useState([]);
  const [
    storesHavingInvalidAggregatorStatus,
    setStoresHavingInvalidAggregatorStatus,
  ] = useState([]);

  const imageObj = {
    'Uber Eats': uberEats,
    Menulog: menuLog,
    Doordash: doordash,
  };

  const storeStatus = props?.storeDetails?.store_status?.toLowerCase();

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey);
    switch (eventKey) {
      case '0':
        if (accordionFirst?.current?.props?.in) {
          return (
            <>
              <img
                src={arrow_up}
                alt='arrow'
                onClick={decoratedOnClick}
                className='pe-3'
              />
              <span>{children}</span>
            </>
          );
        }
        return (
          <>
            <img
              src={arrow_down}
              alt='arrow'
              onClick={decoratedOnClick}
              className='pe-3'
            />
            <span>{children}</span>
          </>
        );
      case '1':
        if (accordionSecond?.current?.props?.in) {
          return (
            <>
              <img
                src={arrow_up}
                alt='arrow'
                onClick={decoratedOnClick}
                className='pe-3'
              />
              <span>{children}</span>
            </>
          );
        }
        return (
          <>
            <img
              src={arrow_down}
              alt='arrow'
              onClick={decoratedOnClick}
              className='pe-3'
            />
            <span>{children}</span>
          </>
        );
      default:
        break;
    }
  }
  const handleCancelReason = (e) => {
    setCancelReason(e.target.value);
  };

  const handleCloseStore = () => {
    switch (cancelReason) {
      case 'Other':
        setPauseIncomingOrdersOthersPopup(true);
        break;
      default:
        setPauseIncomingOrdersPopup({
          shouldShow: true,
          reason: cancelReason,
        });

        break;
    }
  };
  const resumeOrder = () => {
    props?.updateStoreStatus('Online', '', props?.storeDetails?.store_id);
    const headerMsgPayload = {
      show: false,
      type: '',
      msg: '',
      orderId: '',
    };
    props.getMyProfileDetails();
    props.setShowTopMsg(headerMsgPayload);
  };

  const handleRetryConnection = () => {
    if (storeStatus === 'online') {
      resumeOrder();
    } else {
      props?.updateStoreStatus(
        'Offline',
        props?.storeDetails?.store_pause_reason,
        props?.storeDetails?.store_id
      );
      props.getMyProfileDetails();
    }
  };

  const logoutSuccess = async () => {
    setLogoutPopup(false);
    const devicetSatus = await props.removeDeviceToken(
      props.storeDeviceToken?.device_token
        ? props.storeDeviceToken?.device_token
        : props.storeDeviceToken
    );
    if (devicetSatus === 'success' || devicetSatus === 'fail') {
      props.userLogout(props.sessionData.refresh_token);
      sessionStorage.setItem('accessToken', '');
      props.clearAllAuthInfo();
      props.clearAllSelections();
      props.clearAllMenuSelections();
      props.saveCancelledOrderNewNotification([]);
      props.saveIncomingOrderNewNotification([]);
    }
  };

  const isAggregatorStatusValid = (aggregator) => {
    return (
      (aggregator.status === 'active' && storeStatus === 'online') ||
      (aggregator.status === 'deactive' && storeStatus === 'offline')
    );
  };

  const getStoresHavingValidAggregatorStatus = () => {
    return props?.profileDetails?.stores?.filter((store) => {
      return store.aggregators.every(isAggregatorStatusValid);
    });
  };

  const getStoresHavingInvalidAggregatorStatus = () => {
    return props?.profileDetails?.stores?.filter((store) => {
      return !store.aggregators.every(isAggregatorStatusValid);
    });
  };

  useEffect(() => {
    props.setCurrentPath(props.location.pathname);
    props.getMyProfileDetails();
  }, []);

  useEffect(() => {
    setStoresHavingValidAggregatorStatus(
      getStoresHavingValidAggregatorStatus()
    );
    setStoresHavingInvalidAggregatorStatus(
      getStoresHavingInvalidAggregatorStatus()
    );
  }, [props?.profileDetails?.stores]);

  if (props?.showErrorPage) {
    return <SVTMainComponent />;
  }

  const openHelpPage = () => {
    props.history.push('/order-help');
  };
  function AggresDetailsUpdated({ propsPassed }) {
    return (
      <>
        {propsPassed?.map((item, index) => (
          <>
            <div className='d-flex col-md-12 peb-10 svt-bold-text'>
              <div className='col-md-6 d-flex'>
                <div className='brandImg'>
                  <img
                    src={DummyMenuItemImg}
                    alt='store-branding'
                    className='border-setting-brand brandImg'
                  />
                </div>
                <div className='ml-20 mt-20'>{item?.store_name}</div>
                {item?.aggregators?.length > 0 &&
                  !item?.aggregators.every((i) => i.status === 'active') && (
                    <div className='mt-20 ml-5'>
                      {' '}
                      <img src={warningIcon} alt='warning' />
                    </div>
                  )}
              </div>
              {item?.aggregators?.length > 0 &&
                item?.aggregators?.map((itm) => {
                  return (
                    <>
                      <div className='mr-20 d-flex'>
                        <div
                          className={
                            itm?.status !== 'active'
                              ? 'inactiveAgreegatorNames'
                              : 'activeAgreegatorNames'
                          }
                        >
                          <img
                            src={imageObj[itm?.partner_name]}
                            alt={itm?.partner_name}
                            className='partner_logo'
                          />
                          <div className='agreegatoNotAvailable'>
                            <img
                              src={
                                itm?.status !== 'active'
                                  ? crossVector
                                  : checkmarkCircle
                              }
                              alt={itm?.status}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>
          </>
        ))}
      </>
    );
  }

  return (
    <>
      <PauseIncomingOrdersPopup
        show={showPauseIncomingOrdersPopup}
        onHide={setPauseIncomingOrdersPopup}
        setCancelReason={setCancelReason}
        {...props}
      />
      <PauseIncomingOrdersOthersPopup
        show={showPauseIncomingOrdersOthersPopup}
        onHide={setPauseIncomingOrdersOthersPopup}
        setCancelReason={setCancelReason}
        {...props}
      />
      <LogoutPopup
        show={showLogoutPopup}
        onHide={setLogoutPopup}
        {...props}
        logoutSuccess={logoutSuccess}
      />
      <SVTHeaderComponent />
      <div className='container-fluid padding-left-right-order-setting'>
        <div className='order-setting container-order-setting'>
          <Row>
            <Col md={12}>
              <div className='d-flex justify-content-between border-bottom-order-setting'>
                <div className='order-setting-bold-text'>Settings</div>
                <div className='order-setting-bold-text'>
                  Store Status:
                  <img
                    src={
                      (props?.storeDetails?.store_status === 'Offline' &&
                        offline_indicator) ||
                      (!!storesHavingInvalidAggregatorStatus?.length &&
                        aggregator_offline_indicator) ||
                      online_indicator
                    }
                    alt='online indicator'
                    className='ps-3 pe-2'
                  />
                  <span className='order-setting-normal-text'>
                    {(props?.storeDetails?.store_status === 'Offline' &&
                      'Offline') ||
                      (!!storesHavingInvalidAggregatorStatus?.length && //props?.storeDetails?.store_status === 'Aggregator Offline'
                        'Aggregator(s) Offline') ||
                      'Online'}
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          <div className='scrollbar-order-setting'>
            <div className='order-setting-spacing-top'>
              <div className=''>
                <Row>
                  <Col
                    md={12}
                    className='order-setting-bold-text order-setting-about-spacing'
                  >
                    About
                  </Col>
                </Row>
                <Row>
                  <Col md={3} style={{ maxWidth: '250px' }} className='ml-66'>
                    <img
                      src={store_picture}
                      alt='Store Img'
                      width='216px'
                      height='128px'
                    />
                    <div className='d-flex flex-column setting-about-row-gap'>
                      <div className='order-setting-bold-text'>
                        {/* {props.profileDetails?.store_name} */}
                        {props?.storeDetails?.store_name}
                      </div>
                      <div>
                        <p className='mb-0 order-setting-store-details-text'>
                          {/* {props.profileDetails?.store_address} */}
                          {props?.storeDetails?.store_address}
                        </p>
                        {/* <p className='mb-0 order-setting-store-details-text'>
                          {props?.storeDetails?.store_location}
                        </p> */}
                      </div>
                      <div className='mb-0 order-setting-store-details-text'>
                        <img
                          src={phone}
                          alt='Phone Img'
                          width='19.9px'
                          height='19.9px'
                        />
                        <span className='help-image-margin-right'>
                          {/* {props.profileDetails?.store_mobile} */}
                          {props?.storeDetails?.store_phone}
                        </span>
                      </div>
                      <div className='mb-0 order-setting-store-details-email-text'>
                        <img
                          src={envelope}
                          alt='Email Img'
                          width='19.9px'
                          height='19.9px'
                        />
                        <span className='help-image-margin-right'>
                          {/* {props.profileDetails?.store_email} */}
                          {props?.storeDetails?.store_email}
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col
                    md={9}
                    className='order-setting-store-spacing aggregator-list px-0'
                  >
                    <div className='d-flex col-md-12 border-bottom p-20 svt-bold-text'>
                      <div className='col-md-6'>Brands</div>
                      <div className='col-md-6'>
                        Partnered Aggregator Connection
                      </div>
                    </div>
                    <div className='aggr-list-height'>
                      <div
                        className={
                          !!storesHavingInvalidAggregatorStatus?.length
                            ? 'aggr-problem-block'
                            : 'aggr-normal-block'
                        }
                      >
                        <AggresDetailsUpdated
                          propsPassed={storesHavingValidAggregatorStatus}
                        />

                        {!!storesHavingInvalidAggregatorStatus?.length && (
                          <div className='custom-row-flex'>
                            <AggresDetailsUpdated
                              propsPassed={storesHavingInvalidAggregatorStatus}
                            />

                            <div className='openHelp-and-retry-section'>
                              <div
                                className='green-txt cursor-pointer mb-18'
                                onClick={() => openHelpPage()}
                              >
                                <div className='open-help-section-text d-flex align-self-end'>
                                  Open Help Section
                                  <img
                                    height='16px'
                                    src={viewModifierArrow}
                                    alt='Open help'
                                    className='cursor-pointer ml-10'
                                  />
                                </div>
                              </div>

                              <div
                                className='green-txt cursor-pointer mb-18'
                                onClick={() => handleRetryConnection()}
                              >
                                <div className='open-retry-section-text d-flex align-self-start'>
                                  Retry Connection
                                  <img
                                    height='16px'
                                    src={RefreshIcon}
                                    alt='Retry'
                                    className='cursor-pointer ml-10'
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <hr className='order-card-hr' />
            <Row>
              <div className='align-self-start'>
                <div>
                  <div>
                    <div className='setting-accordion'>
                      <Card className='order-setting-pause-border'>
                        <Card.Header
                          style={{ margin: '-4px -16px' }}
                          onClick={() => setShowPauseOrders(!showPauseOrders)}
                          className='cursor-pointer'
                        >
                          <img
                            src={showPauseOrders ? arrow_up : arrow_down}
                            alt='arrow'
                            className='mr-10'
                          />
                          <span className='order-setting-chevron-header'>
                            Temporarily Pause Orders
                          </span>
                        </Card.Header>
                        {showPauseOrders && (
                          <Card.Body
                            className='order-setting-pause-order-card-body'
                            style={{ margin: '0px -16px' }}
                          >
                            {props?.storeDetails?.store_status === 'Online' && (
                              <div className='setting-accordion-card'>
                                <Row className='pb-4'>
                                  <Col sm={1} style={{ width: '24px' }}>
                                    <img src={risk} alt='danger-icon' />
                                  </Col>
                                  <Col md={8}>
                                    <div className='setting-accordion-more-txt'>
                                      If orders are paused, the store will no
                                      longer receive any new orders until
                                      manually resumed.{' '}
                                    </div>
                                    <div className='setting-accordion-more-txt'>
                                      You can still finish already existing
                                      orders.{' '}
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col
                                    md={12}
                                    className='ms-1 ps-5 setting-accordion-more-txt text-gray-order-setting'
                                  >
                                    Reason For Pausing:
                                  </Col>
                                </Row>
                                <Row className='mt-3'>
                                  <Col md={3} className='col-width'>
                                    <input
                                      className='form-check-input shadow-none'
                                      type='radio'
                                      name='flexRadioDefault'
                                      id='shortageOfStaff'
                                      value='Shortage of Staff Working'
                                      onChange={handleCancelReason}
                                    />
                                    <label
                                      className='form-check-label ms-3'
                                      htmlFor='shortageOfStaff'
                                    >
                                      Shortage of Staff Working
                                    </label>
                                  </Col>
                                  <Col md={3} className='col-width'>
                                    <input
                                      className='form-check-input shadow-none'
                                      type='radio'
                                      name='flexRadioDefault'
                                      id='storeClosingEarly'
                                      value='Store Closing Early'
                                      onChange={handleCancelReason}
                                    />
                                    <label
                                      className='form-check-label ms-3'
                                      htmlFor='storeClosingEarly'
                                    >
                                      Store Closing Early
                                    </label>
                                  </Col>
                                  <Col md={6} className='col-other-first'>
                                    <input
                                      className='form-check-input shadow-none'
                                      type='radio'
                                      name='flexRadioDefault'
                                      id='others'
                                      value='Other'
                                      onChange={handleCancelReason}
                                    />
                                    <label
                                      className='form-check-label ms-3'
                                      htmlFor='others'
                                    >
                                      Others
                                    </label>
                                  </Col>
                                </Row>
                                <Row className='mt-3 remove-margin-top'>
                                  <Col md={3} className='col-width'>
                                    <input
                                      className='form-check-input shadow-none'
                                      type='radio'
                                      name='flexRadioDefault'
                                      id='emergencyIssue'
                                      value='Emergency Issue'
                                      onChange={handleCancelReason}
                                    />
                                    <label
                                      className='form-check-label ms-3'
                                      htmlFor='emergencyIssue'
                                    >
                                      Emergency Issue
                                    </label>
                                  </Col>
                                  <Col md={3} className='col-width'>
                                    <input
                                      className='form-check-input shadow-none'
                                      type='radio'
                                      name='flexRadioDefault'
                                      id='deviceIssue'
                                      value='Hardware/Device Issue'
                                      onChange={handleCancelReason}
                                    />
                                    <label
                                      className='form-check-label ms-3'
                                      htmlFor='deviceIssue'
                                    >
                                      Hardware/Device Issue
                                    </label>
                                  </Col>
                                  <Col className='col-other-last'>
                                    <input
                                      className='form-check-input shadow-none'
                                      type='radio'
                                      name='flexRadioDefault'
                                      id='others'
                                      value='Other'
                                      onChange={handleCancelReason}
                                    />
                                    <label
                                      className='form-check-label ms-3'
                                      htmlFor='others'
                                    >
                                      Others
                                    </label>
                                  </Col>
                                </Row>
                                <Row className='pause-order-margin-top'>
                                  <Col md={12}>
                                    <button
                                      className={
                                        'shadow-none ' +
                                        (cancelReason === ''
                                          ? 'success-btn-disable-order-setting'
                                          : 'success-btn-order-setting')
                                      }
                                      disabled={
                                        cancelReason === '' ? true : false
                                      }
                                      onClick={handleCloseStore}
                                    >
                                      Pause Order
                                    </button>
                                  </Col>
                                </Row>
                              </div>
                            )}
                            {props?.storeDetails?.store_status ===
                              'Offline' && (
                              <div className='setting-accordion-card'>
                                <button
                                  className='success-btn-modal shadow-none'
                                  onClick={resumeOrder}
                                >
                                  Resume Orders
                                </button>
                              </div>
                            )}
                          </Card.Body>
                        )}
                      </Card>
                      <hr className='order-card-hr' />
                      <Card className='order-setting-log-out-card'>
                        <Card.Header
                          style={{ margin: '-4px -16px' }}
                          onClick={() => setShowMore(!showMore)}
                          className='cursor-pointer'
                        >
                          <img
                            src={showMore ? arrow_up : arrow_down}
                            alt='arrow'
                            className='mr-10'
                          />
                          <span className='order-setting-chevron-header'>
                            More
                          </span>
                        </Card.Header>
                        {showMore && (
                          <Card.Body
                            style={{
                              margin: '0px -16px',
                              paddingBottom: '4px',
                            }}
                          >
                            <div className='d-flex flex-column setting-accordion-card'>
                              <div>
                                <img src={risk} alt='danger-icon' />
                                <span className='setting-accordion-more-txt'>
                                  If logged out, you will no longer be able to
                                  see any incoming or received orders.
                                </span>
                              </div>
                              <div>
                                <button
                                  className='green-outline-btn shadow-none mt-4'
                                  onClick={() => setLogoutPopup(true)}
                                >
                                  Log Out
                                </button>
                              </div>
                            </div>
                          </Card.Body>
                        )}
                      </Card>
                      <hr className='mb-0 order-card-hr' />
                    </div>
                  </div>
                </div>
              </div>
            </Row>
          </div>
          <div className='order-setting-footer order-setting-footer-margin'>
            <div className='m-0 p-0'>
              <button
                type='button'
                className='green-outline-btn shadow-none float-end'
                onClick={() => props?.history?.goBack()}
              >
                Go Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function PauseIncomingOrdersPopup(props) {
  const { show, onHide, setCancelReason } = props;
  const pauseOrder = () => {
    props?.updateStoreStatus(
      'Offline',
      show?.reason,
      props?.storeDetails?.store_id
    );
    onHide(false);
    const headerMsgPayload = {
      show: true,
      type: 'store',
      msg: 'All incoming orders to the store are manually paused.',
      orderId: '',
    };
    props.setShowTopMsg(headerMsgPayload);
    setCancelReason('');
  };
  return (
    <Modal
      show={show.shouldShow}
      onHide={() =>
        onHide({
          shouldShow: false,
          reason: '',
        })
      }
      size='md'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header style={{ padding: '20px 20px 0px 20px' }}>
        <Modal.Title id='contained-modal-title-vcenter'>
          <div className='d-flex'>
            <img src={risk_icon} alt='Risk' />
            <p className='m-0 p-0 normal-font fw-bold ps-2'>
              Pause Incoming Orders
            </p>
          </div>
        </Modal.Title>
        <img
          src={cross_icon}
          height='21.98px'
          width='21.98px'
          onClick={() => onHide(false)}
          alt='cross icon'
        />
      </Modal.Header>
      <Modal.Body className='m-0' style={{ padding: '0px 20px 0px 20px' }}>
        <Row>
          <Col sm={12} className='m-0'>
            <hr className='m-0 contact-supp-hr-setting-other' />
          </Col>
          <Col md={12} className='pe-2'>
            The store will no longer receive any new orders until manually
            resumed.
          </Col>
        </Row>
        <Row>
          <Col sm={12} className='pt-4 pb-3'>
            Are you sure you want to pause incoming orders?
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <button
          type='button'
          className='green-outline-btn shadow-none btn-cancel-text-order-setting-success'
          onClick={() => onHide(false)}
        >
          Cancel
        </button>
        <button
          type='button'
          className='success-btn-modal shadow-none ms-3 btn-pause-text-order-setting-success'
          onClick={pauseOrder}
        >
          Pause Orders
        </button>
      </Modal.Footer>
    </Modal>
  );
}

function PauseIncomingOrdersOthersPopup(props) {
  const { show, onHide, setCancelReason } = props;
  const [otherReason, setReason] = useState('');
  const pauseOrder = () => {
    props?.updateStoreStatus('Offline', 'Other', props?.storeDetails?.store_id);
    onHide(false);
    const headerMsgPayload = {
      show: true,
      type: 'store',
      msg: 'All incoming orders to the store are manually paused.',
      orderId: '',
    };
    props.setShowTopMsg(headerMsgPayload);
    setCancelReason('');
  };
  return (
    <Modal
      show={show}
      onHide={onHide}
      size='md'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header style={{ padding: '20px 20px 0px 20px' }}>
        <Modal.Title id='contained-modal-title-vcenter'>
          <div className='d-flex'>
            <p className='m-0 px-0 normal-font fw-bold '>Other</p>
          </div>
        </Modal.Title>
        <img
          src={cross_icon}
          height='21.98px'
          width='21.98px'
          onClick={() => onHide(false)}
          alt='cross icon'
        />
      </Modal.Header>
      <Modal.Body className='m-0' style={{ padding: '0px 20px 0px 20px' }}>
        <Row>
          <Col sm={12} className='m-0'>
            <hr className='m-0 contact-supp-hr-setting-others' />
          </Col>
          <Col md={12}>
            <textarea
              className='pause-incoming-orders-others-popup'
              id='exampleFormControlTextarea1'
              rows='5'
              placeholder='Reason For Pausing'
              value={otherReason}
              onChange={(e) => setReason(e.target.value)}
            ></textarea>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer style={{ padding: '15px 20px 20px 20px' }}>
        <button
          type='button'
          className='green-outline-btn shadow-none btn-cancel-text-order-setting'
          onClick={() => onHide(false)}
        >
          Cancel
        </button>
        <button
          type='button'
          className={
            ' shadow-none ms-3 ' +
            (otherReason === ''
              ? 'success-btn-disable btn-pause-text-order-setting'
              : 'success-btn-modal btn-pause-text-order-setting-success')
          }
          onClick={pauseOrder}
          disabled={otherReason === '' ? true : false}
        >
          Pause Orders
        </button>
      </Modal.Footer>
    </Modal>
  );
}

function LogoutPopup(props) {
  const { show, onHide } = props;
  return (
    <Modal
      show={show}
      onHide={onHide}
      size='md'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header>
        <Modal.Title id='contained-modal-title-vcenter'>
          <div className='d-flex'>
            <img src={risk_icon} alt='Risk' />
            <p className='m-0 p-0 normal-font fw-bold ps-2'>Log Out</p>
          </div>
        </Modal.Title>
        <img
          src={cross_icon}
          height='21.98px'
          width='21.98px'
          onClick={() => onHide(false)}
          alt='cross icon'
        />
      </Modal.Header>
      <Modal.Body className='p-0 m-0 ps-3 pe-3'>
        <Row>
          <Col sm={12} className='m-0 pb-3'>
            <hr className='m-0 contact-supp-hr' />
          </Col>
          <Col md={12}>
            Logging out will hide all incoming orders. All orders received will
            no longer be visible.
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <button
          type='button'
          className='green-outline-btn shadow-none'
          onClick={() => onHide(false)}
        >
          Cancel
        </button>
        <button
          type='button'
          className={' shadow-none ms-3 success-btn-modal'}
          onClick={() => props.logoutSuccess()}
        >
          Log out
        </button>
      </Modal.Footer>
    </Modal>
  );
}

function mapState(state) {
  const { orderManagementRelated, authInfo, errorPageRelated } = state;
  const { storeDetails, storeDeviceToken, profileDetails } =
    orderManagementRelated;
  const { showErrorPage } = errorPageRelated;
  const { sessionData } = authInfo;
  return {
    storeDetails,
    sessionData,
    storeDeviceToken,
    showErrorPage,
    profileDetails,
  };
}

const actionCreators = {
  updateStoreStatus: orderManagementActions.updateStoreStatus,
  setShowTopMsg: orderManagementActions.setShowTopMsg,
  clearAllAuthInfo: orderManagementActions.clearAllAuthInfo,
  clearAllSelections: orderManagementActions.clearAllSelections,
  setCurrentPath: orderManagementActions.setCurrentPath,
  userLogout: authActions.userLogout,
  saveIncomingOrderNewNotification:
    orderManagementActions.saveIncomingOrderNewNotification,
  saveCancelledOrderNewNotification:
    orderManagementActions.saveCancelledOrderNewNotification,
  removeDeviceToken: orderManagementActions.removeDeviceToken,
  getMyProfileDetails: orderManagementActions.getMyProfileDetails,
  clearAllMenuSelections: menuManagementAction.clearAllMenuSelections,
};

export default compose(
  withRouter,
  connect(mapState, actionCreators)
)(SVTOrderSettingPage);
