import React from 'react';
import arrowImg from '../../images/up_arrow.svg';
import downArrow from '../../images/down_green_arrow.svg';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { orderManagementActions } from '../../action/SVTOrderManagementAction';

function formatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

function SVTOrderHistorySummaryComponent(props) {
  const historyCount = props.historyDetails.count;
  // const [shouldShowSummary, setSummaryView] = useState(true);
  const isCustom = () => {
    if (props.orderHistoryFilter) {
      for (let index = 0; index < props.orderHistoryFilter.length; index++) {
        if (props.orderHistoryFilter[index]['custom']) {
          return [true, props.orderHistoryFilter[index]['custom']];
        }
      }
    }
    return [false];
  };
  const showTimeline = () => {
    if (props.orderHistoryFilter) {
      if (props.orderHistoryFilter.includes('today')) {
        return 'Today';
      }
    }
    const tmp = isCustom();
    if (tmp[0] === true) {
      const customDate = tmp[1].split(',');
      let firstDate = customDate[0].split('-');
      firstDate = firstDate[0] + ' - ' + firstDate[1];
      let secondDate = customDate[1].split('-');
      secondDate = secondDate[0] + ' - ' + secondDate[1];
      return `${firstDate} to ${secondDate}`;
    }
    return 'Last 7 Days';
  };
  return (
    <>
      <div
        className={
          props.shouldShowSummary
            ? 'order-history-summary-container padding-left1 padding-right1 margin-top-live-order'
            : 'order-history-summary-container-altered padding-left1 padding-right1 margin-top-live-order'
        }
        data-test='orderHistorySummaryComponentContainer'
      >
        <div className='summary-header'>
          <div className='summary-title'>
            <span className='summary-header-padding-right10'>
              <strong>Summary</strong>
            </span>
            <span className='last-7-days'>
              {props.orderHistoryFilter && <> {showTimeline()} </>}
            </span>
          </div>
          <img
            src={props.shouldShowSummary ? arrowImg : downArrow}
            alt='arrow'
            height='9px'
            width='24px'
            onClick={() =>
              props.toggleShowOrderSummary(!props.shouldShowSummary)
            }
            className='cursor-pointer'
            data-test='cursorPointer'
          />
        </div>
        {props.shouldShowSummary && <hr />}
        {props.shouldShowSummary && (
          <>
            <div className='summary-content-grid d-none'>
              <div className='orders-details-flex'>
                <div className='summary-content'>
                  <p className='content-header bold'>Sales</p>
                  <p className='summary-content-values'>
                    {/* ${(historyCount?.total_sales / 100).toFixed(2)} */}$
                    {formatNumber((historyCount?.total_sales / 100).toFixed(2))}
                  </p>
                </div>
                <div className='summary-content'>
                  <p className='content-header bold'>Total Orders</p>
                  <p className='summary-content-values'>
                    {historyCount?.total_orders}
                  </p>
                </div>
                <div className='summary-content'>
                  <p className='content-header bold'>Completed</p>
                  <p className='summary-content-values'>
                    {historyCount?.completed_orders}
                  </p>
                </div>
                <div className='summary-content'>
                  <p className='content-header bold'>Cancelled</p>
                  <p className='summary-content-values cancelled-color-history'>
                    {historyCount?.cancelled_by_aggregator +
                      historyCount?.cancelled_by_store}
                  </p>
                </div>
              </div>
            </div>
            <div className='orders-details-flex summary-content-grid my-0'>
              <div className='row py-0 my-0'>
                <div className='summary-content col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-5 col-xxl-4'>
                  <p className='content-header bold'>Sales</p>
                  <p className='summary-content-values'>
                    {/* ${(historyCount?.total_sales / 100).toFixed(2)} */}$
                    {formatNumber((historyCount?.total_sales / 100).toFixed(2))}
                  </p>
                </div>
                <div className='col'>
                  <div className='row'>
                    <div className='summary-content col-12 col-xs-12 col-sm-12 col-md-4'>
                      <p className='content-header bold'>Total Orders</p>
                      <p className='summary-content-values'>
                        {historyCount?.total_orders}
                      </p>
                    </div>
                    <div className='summary-content col-12 col-xs-12 col-sm-12 col-md-4'>
                      <p className='content-header bold'>Completed</p>
                      <p className='summary-content-values'>
                        {historyCount?.completed_orders}
                      </p>
                    </div>
                    <div className='summary-content col-12 col-xs-12 col-sm-12 col-md-4'>
                      <p className='content-header bold'>Cancelled</p>
                      <p className='summary-content-values cancelled-color-history'>
                        {historyCount?.cancelled_by_aggregator +
                          historyCount?.cancelled_by_store}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
function mapState(state) {
  const { orderManagementRelated } = state;
  const { shouldShowSummary } = orderManagementRelated;
  return { shouldShowSummary };
}
const actionCreators = {
  toggleShowOrderSummary: orderManagementActions.toggleShowOrderSummary,
};
export default compose(
  withRouter,
  connect(mapState, actionCreators)
)(SVTOrderHistorySummaryComponent);
