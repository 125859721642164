import { constants } from '../constant/reducerConstant';
import { authServices } from '../services/SVTAuthServices';
import { showErrorPage, startLoader, stopLoader } from '../helper/common';
import { apiUrl } from '../url/apiUrl';

export const authActions = {
	callAuth,
	savePath,
	getUserData,
	refreshAccessToken,
	userLogout,
	clearAllSelection,
	saveUserInfo,
	setIsAppInitializeAfterLogin
};

function callAuth(code) {
	return dispatch => {
		dispatch(startLoader());
		return authServices.callAuth(code).then(
			data => {
				data.tokenStartTime = new Date().getTime();
				data.tokenExpiryTime = new Date().getTime() + data.expires_in * 1000;
				dispatch(stopLoader());
				dispatch(saveAuthInfo(data));
				return {
					success: true,
				};
			},
			error => {
				dispatch(clearAuthInfo());
				dispatch(stopLoader());
				dispatch(showErrorPage(error, 'Token API Failure'));
			},
		);
	};
	function saveAuthInfo(data) {
		return { type: constants.SAVE_AUTH_INFO, data };
	}
	function clearAuthInfo() {
		return { type: constants.CLEAR_AUTH_INFO };
	}
}

function savePath(pathName) {
	return { type: constants.SAVE_REDIRECT_PATH, pathName };
}

function getUserData(data) {
	return dispatch => {
		dispatch(startLoader());
		return authServices.getUserData(data).then(
			data => {
				dispatch(stopLoader());
				dispatch(saveUserData(data));
				return {
					success: true,
				};
			},
			error => {
				dispatch(clearUserData());
				dispatch(stopLoader());
				dispatch(showErrorPage(error, 'No user found'));
			},
		);
	};
	function saveUserData(data) {
		return { type: constants.SAVE_USER_DATA, data };
	}
	function clearUserData() {
		return { type: constants.CLEAR_USER_DATA };
	}
}

function refreshAccessToken(refreshToken) {
	return dispatch => {
		dispatch(startLoader());
		dispatch(callRefreshToken(false));
		return authServices.refreshAccessToken(refreshToken).then(
			data => {
				data.refresh_token = refreshToken;
				data.tokenStartTime = new Date().getTime();
				data.tokenExpiryTime = new Date().getTime() + data.expires_in * 1000;
				dispatch(stopLoader());
				dispatch(saveAuthInfo(data));
				dispatch(callRefreshToken(true));
			},
			error => {
				dispatch(clearAuthInfo());
				dispatch(stopLoader());
				dispatch(callRefreshToken(true));
				dispatch(showErrorPage());
			},
		);
	};
	function saveAuthInfo(data) {
		return { type: constants.SAVE_AUTH_INFO, data };
	}
	function clearAuthInfo() {
		return { type: constants.CLEAR_AUTH_INFO };
	}
	function callRefreshToken(val) {
		return { type: constants.CALL_REFRESH_TOKEN, val };
	}
}

function userLogout(refreshToken) {
  return (dispatch) => {
        dispatch(clearAuthInfo());
        dispatch(clearAllSelection());
        localStorage.userLoggedIn = false;
        localStorage.userInfo = null;
		window.location.href = apiUrl.logoutUrl;
    };
  function clearAuthInfo() {
    return { type: constants.CLEAR_ALL_AUTH_INFO };
  }
}

function clearAllSelection() {
	return { type: constants.CLEAR_ALL_SELECTIONS };
}

function saveUserInfo() {
	const data = localStorage.userInfo;
	return { type: constants.SAVE_USER_DATA, data };
}
function setIsAppInitializeAfterLogin(data){
	return {type: constants.SET_IS_APP_INITIALIZE_AFTER_LOGIN,data}
}
