import React from 'react';
import { Container } from 'react-bootstrap';
import closeButtonGreen from '../../images/closeButtonGreen.svg';

function SVTEditOrderFooter(props) {
  const {
    hideEditOrderViewHandler,
    setShouldOpenPartialOrderAccept,
    resetOrderItems,
    isProductItemsInInitialState,
    isAllItemsMarkedToZero,
  } = props;
  return (
    <>
      <Container className='footer-in-incoming-order-card'>
        <main
          className={
            'd-flex flex-sm-row flex-lg-column flex-xxl-row justify-content-sm-between justify-content-lg-center justify-content-xxl-between'
          }
        >
          <section className='mt-20 d-flex justify-content-lg-center'>
            <img
              src={closeButtonGreen}
              alt='close'
              className='cursor-pointer'
              width={'44px'}
              height={'44px'}
              onClick={hideEditOrderViewHandler}
            />
          </section>

          <section className='mt-20 d-flex flex-row-reverse justify-content-lg-center flex-gap-20 '>
            <button
              className={
                isProductItemsInInitialState()
                  ? 'partially-accept-btn'
                  : 'partially-accept-btn darkgreen-background-color'
              }
              disabled={isProductItemsInInitialState() ? true : false}
              onClick={() => setShouldOpenPartialOrderAccept(true)}
            >
              {isAllItemsMarkedToZero() ? 'Cancel' : 'P. Accept'}
            </button>
            {!isProductItemsInInitialState() && (
              <button
                className={true ? 'success-border-btn' : 'partially-accept-btn'}
                onClick={resetOrderItems}
              >
                Reset
              </button>
            )}
          </section>
        </main>
      </Container>
    </>
  );
}
export default SVTEditOrderFooter;
