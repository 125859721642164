import { constants } from '../constant/reducerConstant';

const initialState = {
	showErrorPage: false,
	errorPageAPIErrorMessageHeader: '',
	errorPageAPIErrorMessageDetails: '',
	errorPageAPIErrorCodeDetails: '',
};

export function errorPageRelated(state = initialState, action) {
	switch (action.type) {
		case constants.SHOW_ERROR_PAGE:
			return {
				...state,
				showErrorPage: true,
				errorPageAPIErrorMessageHeader: action.errorMessages['methodDetails'],
				errorPageAPIErrorMessageDetails: action.errorMessages['message']??'',
				errorPageAPIErrorCodeDetails: action.errorMessages['code']?? ''
			};
		case constants.HIDE_ERROR_PAGE:
			return {
				...state,
				showErrorPage: false,
				errorPageAPIErrorMessageHeader: '',
				errorPageAPIErrorMessageDetails: '',
				errorPageAPIErrorCodeDetails: ''
			};
		default:
			return state;
	}
}
