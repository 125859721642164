import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import plus_icon from '../../images/plus_icon.svg';
import { useHistory } from 'react-router-dom';
import SVTPrintReceipt from './SVTPrintReceipt';
import upArrowNoteIcon from '../../images/arrow-up-note.svg';
function SVTHistoryItemList(props) {
	const orderDetails = props.orderDetails;
	const history = useHistory();
	// let totalQuantity = 0;
	// let totalPrice = 0;
	function showOrderHistoryList() {
		history.push('/order-history');
	}

	const orderViewHandler = (itm) => {
		return (
			<>
				<Row className='m-0 p-0 mt-0 pb-2 '>
					<Col sm={1} md={1}></Col>
					<Col md={7} className='m-0 p-0 '>
						<div>{orderDetails?.partner_name==="Menulog"? itm?.name : itm?.description} </div>
					</Col>
					<Col
						md={2}
						className='order-font-history-item-list align-self-end px-1'
					>
						x{itm?.quantity ? itm?.quantity : 1}
					</Col>
					<Col
						md={2}
						className='order-font-history-item-list align-self-end px-1'
					>
						$
						{(itm?.unit_price
							? itm?.unit_price / 100
							: itm?.price / itm?.quantity / 100
						).toFixed(2)}
					</Col>
				</Row>
				{itm?.children?.length > 0 && itm?.children?.map(childItem => {
					return (orderViewHandler(childItem))
				})}

			</>
		);
	}

	const orderItems = orderDetails?.order_items?.map(item => {
		return (<div className='m-0 p-0 pt-2' key={item.plu}>
			<Row className='m-0 p-0 pt-2'>
				<Col md={12} sm={12} xs={12} className='category_text'>
					{item?.catetory}
				</Col>
				<Col md={8} className='live-order-menu-item-text'>
					{item?.name}
				</Col>
				<Col md={2} className='order-font-history-item-list px-1'>
					x{item?.quantity ? item?.quantity : 1}
				</Col>
				<Col md={2} className='order-font-history-item-list px-1'>
					$
					{(item?.unit_price
						? item?.unit_price / 100
						: item?.price / item?.quantity / 100
					).toFixed(2)}
				</Col>
			</Row>
			{item?.children?.length > 0 && item?.children?.map(itm => {
				return (orderViewHandler(itm))
			})}
			{/* If any special_instruction for order-items then it will show */}
			{item?.special_instructions?.length > 0 && (
				<Row className='mx-0 mt-2 pb-3'>
					<Col md={12}>
						<img
							src={upArrowNoteIcon}
							alt='upArrowNoteIcon'
							className='item-specific-instruction-image'
						/>
						<span className='ps-1 item-specific-instruction'>
							{item?.special_instructions}
						</span>
					</Col>
				</Row>
			)}
		</div>)
	})

	return (
		<>
			<div
				className={
					props.cancelled == 'Cancelled'
						? 'menu-table-container-cancelled margin-top-menu-table-history'
						: 'menu-table-container-history margin-top-menu-table-history'
				}
				data-test="historyItemListContainer"
			// style={props.cancelled=='Cancelled'?{minHeight:'62vh',maxHeight:'62vh'}:{}}>
			>
				<div
					className={
						props.cancelled == 'Cancelled'
							? 'menu-table menu-table-order-history-cancelled'
							: 'menu-table menu-table-order-history'
					}
				>
					<Row className='m-0 p-0 pb-2 menu-header border-below'>
						<Col md={8} className='px-0 order-details-item-qty-price'>
							Item Name
						</Col>
						<Col md={2} className='order-details-item-qty-price '>
							{'QTY'}
						</Col>
						<Col md={2} className='order-details-item-qty-price'>
							{'PRICE'}
						</Col>
					</Row>
					<Row>{orderItems}</Row>
					<Row className='m-0 p-0 border-bottom pt-3'> </Row>
					<Row className='mt-2 order-font-history-item-list'>
						<Col
							md={8}
							className='pt-2 pb-2 large_text'
							style={{ paddingLeft: '70px' }}
						>
							Total
						</Col>
						<Col md={2} className='pt-2 pb-2 px-1'>
							x{orderDetails?.count?.total_items}
						</Col>
						<Col md={2} className='px-1 pt-2 pb-2'>
							${(parseFloat(orderDetails?.total_price) / 100).toFixed(2)}
						</Col>
					</Row>
				</div>
				<div className='order-history-footer d-flex justify-content-between col-md-12 col-sm-12 col-12'>
					{orderDetails?.order_items?.length > 0 ? <div className='printer-icon-order-history content-display'>
						<SVTPrintReceipt details={orderDetails} />
					</div> : <div className='content-display'></div>}
					<div>
						<div
							className='order-history-view-order-btn-back cursor-pointer'
							onClick={showOrderHistoryList}
							data-test="showOrderHistoryListBtn"
						>
							<span className='order-history-item-list-btn-back-text'>
								{'Back'}
							</span>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default SVTHistoryItemList;
