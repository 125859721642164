import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { history } from './helper/history';
import { BrowserRouter, Route, Switch, HashRouter } from 'react-router-dom';
import SVTLandingPage from './components/SVTLandingPage';
import { store, persistor } from './helper/store';
import { PersistGate } from 'redux-persist/integration/react';
import SVTOrderHistoryComponent from './components/aggregators/SVTOrderHistoryComponent';
import SVTOrderHelpComponent from './components/aggregators/SVTOrderHelpComponent';
import SVTOrderViewComponent from './components/aggregators/SVTOrderViewComponent';
import SVTOrderSettingPage from './components/aggregators/SVTOrderSettingPage';
import SVTOrderHistoryDetails from './components/aggregators/SVTOrderHistoryDetails';
import SVTNotification from './components/aggregators/SVTNotification';
import SVTOrderListScheduler from './components/aggregators/SVTOrderListScheduler';
import SVTMenuPage from './components/aggregators/SVTMenuPage';
import OfflinePopup from './components/aggregators/SVTOfflinePopup';
import { delayForGivenTime } from './helper/common';

const firebaseConfig = new URLSearchParams({
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
}).toString();

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register(`../firebase-messaging-sw.js?${firebaseConfig}`)
    .then(function (registration) {
      console.log('Registration successful, scope is:', registration.scope);
    })
    .catch(function (err) {
      console.log('Service worker registration failed, error:', err);
    });
}
function SVTApp() {
  const [isOnline, setIsOnline] = React.useState(navigator.onLine);
  const [isLoading, setIsLoading] = React.useState(false);
  const checkInternetConnection = async () => {
    setIsLoading(true);
    if (navigator.onLine) {
      setIsOnline(true);
    }
    await delayForGivenTime(300);
    setIsLoading(false);
  };
  React.useEffect(() => {
    window.addEventListener('online', () => {
      setIsOnline(true);
    });
    window.addEventListener('offline', () => {
      setIsOnline(false);
    });
    return () => {
      window.removeEventListener('online', () => {
        setIsOnline(true);
      });
      window.removeEventListener('offline', () => {
        setIsOnline(false);
      });
    };
  }, []);
  return (
    <BrowserRouter history={history}>
      <SVTOrderListScheduler isOnline={isOnline} />
      <SVTNotification />
      <OfflinePopup
        show={!isOnline}
        retryHandler={checkInternetConnection}
        isLoading={isLoading}
      />
      <Switch>
        <Route exact path='/' component={SVTLandingPage} />
        <Route
          exact
          path='/order-details/:type'
          component={SVTOrderViewComponent}
        />
        <Route exact path='/order-list' component={SVTLandingPage} />
        <Route
          exact
          path='/order-history'
          component={SVTOrderHistoryComponent}
        />
        <Route exact path='/order-help' component={SVTOrderHelpComponent} />
        {/* <Route
							path='/order-details/:type'
							component={SVTOrderViewComponent}
						/> */}
        <Route exact path='/order-setting' component={SVTOrderSettingPage} />
        <Route
          exact
          path='/order-history-details'
          component={SVTOrderHistoryDetails}
        />
        <Route exact path='/menu' component={SVTMenuPage} />
      </Switch>
    </BrowserRouter>
  );
}
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <SVTApp />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
