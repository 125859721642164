import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';

function SVTTotalSectionOfIncomingOrderCard(props) {
  const { totalItems, totalPrice } = props;
  return (
    <>
      <Container className='p-0'>
        <Row className='grey-border mt-10 mb-3'></Row>
        <Row className='my-3'>
          <Col sm={8} lg={6} xxl={8} className='word-break'>
            <section
              className='font-size-14 letter-spacing-025'
              style={{ paddingLeft: '30%' }}
            >
              Total
            </section>
          </Col>
          <Col
            sm={2}
            lg={3}
            xxl={2}
            className='word-break font-size-14 letter-spacing-025'
          >
            {' '}
            x{totalItems}
          </Col>
          <Col sm={2} lg={3} xxl={2} className='d-flex flex-row-reverse'>
            <section className='font-size-14 letter-spacing-025'>
              ${(totalPrice / 100).toFixed(2)}
            </section>
          </Col>
        </Row>
      </Container>
    </>
  );
}
export default SVTTotalSectionOfIncomingOrderCard;
