import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
const calculateDuration = date => moment.duration(Math.max(date - (Math.floor(Date.now() / 1000)), 0), 'seconds');
function SVTOrderCountDown({ date }) {
    const datePlus10Minutes = moment(date, "HH:mm A").add(10, 'minutes')
    const interval = 1000;
    const eventTime = datePlus10Minutes.format("X");
    const [duration, setDuration] = useState(calculateDuration(eventTime));
    const timerRef = useRef(0);
    const timerCallback = useCallback(() => {
        setDuration(calculateDuration(eventTime));
    }, [eventTime])
    useEffect(() => {
        timerRef.current = setInterval(timerCallback);
        return () => {
            clearInterval(timerRef.current);
        }
    }, [eventTime, interval, timerCallback]);
    return (
        <span className="order-count-down">
            {duration.minutes()} Minutes {duration.seconds()} Sec
        </span>
    )
}
export default SVTOrderCountDown;