import { initializeApp } from 'firebase/app';
import {apiUrl} from '../url/apiUrl';
const firebaseConfig = {
	apiKey: apiUrl.apiKey,
	authDomain: apiUrl.authDomain,
	projectId: apiUrl.projectId,
	storageBucket: apiUrl.storageBucket,
	messagingSenderId: apiUrl.messagingSenderId,
	appId: apiUrl.appId,
	measurementId: apiUrl.measurementId,
};
const fapp = initializeApp(firebaseConfig);

export default fapp;
