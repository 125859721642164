import React, { Component } from "react";
import LoadingOverlay from 'react-loading-overlay';
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import SVTExceptionPage from "../common/SVTExceptionPage";
import SVTAuthCheck from '../common/SVTAuthCheck';

class SVTMainComponent extends Component {

    componentDidMount(){
        console.log("in mainn", this.props.showLoader);
    }

    render(){
        return(
          
            <LoadingOverlay active={this.props.showLoader} className="loaderSpinner" spinner 
            styles={{
                wrapper: {
                  width: '100%',
                  height: '100%'
                }
              }}>
                  <SVTAuthCheck>
                <SVTExceptionPage>
                {this.props.children}
                </SVTExceptionPage>              
                {/* {this.props.children} */}
                </SVTAuthCheck>
            </LoadingOverlay>
        )
    }
}

export function mapState(state) {
    const { loader } = state;
    const { showLoader } = loader;
    return { showLoader };
}

const actionCreators = {
 
}


export default compose(withRouter,
    connect(mapState,actionCreators))(SVTMainComponent);
