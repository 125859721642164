import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SVTDriverStatusComponent from '../common/SVTDriverStatusComponent';

class SVTOrderDetailsHeader extends Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {}
	
	
	render() {
	    let orderDetails = this.props.selectedOrderDetails;
		switch (this.props.type) {
			case 'Incoming':
				break;
			case 'Cancelled':
				orderDetails = this.props.selectedOrderDetails;
				break;
			default:
				break;
		}
		return (
			<>
				
				<SVTDriverStatusComponent driverStatus={orderDetails} type={'orderDetailHeader'} orderType={this.props.type}/>
            </>
		);
	}
}

export function mapState(state) {
	const { orderManagementRelated } = state;
	const { selectedOrderDetails } =
		orderManagementRelated;
	return { selectedOrderDetails };
}
const actionCreators = {};

export default compose(
	withRouter,
	connect(mapState, actionCreators),
)(SVTOrderDetailsHeader);
