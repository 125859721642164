import React from 'react';
import { Row, Col } from 'react-bootstrap';

function SVTIncomingOrderProductView(props) {
  const { productItem } = props;
  return (
    <>
      <Row>
        <Col
          sm={12}
          className='font-size-12 letter-spacing-15 text-uppercase dark-shade-gray word-break'
        >
          {productItem?.catetory}
        </Col>
        <Col
          sm={8}
          lg={6}
          xxl={8}
          className='word-break line-height-24 letter-spacing-O50'
        >
          <section> {productItem?.name}</section>
        </Col>
        <Col
          sm={2}
          lg={3}
          xxl={2}
          className='word-break font-size-14 letter-spacing-025'
        >
          x{productItem?.quantity}
        </Col>
        <Col
          sm={2}
          lg={3}
          xxl={2}
          className='d-flex flex-row-reverse font-size-14'
        >
          <section>
            $
            {(productItem?.unit_price
              ? productItem?.unit_price / 100
              : productItem?.price / productItem?.quantity / 100
            ).toFixed(2)}
          </section>
        </Col>
      </Row>
    </>
  );
}
export default SVTIncomingOrderProductView;
