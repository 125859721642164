import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import phoneIcon from '../../images/phone.svg';

function SVTIncomingOrderCardCustomerInfoView(props) {
  const { customerName, customerPhoneNumber, customerPhoneCode } = props;
  return (
    <Container className='customer-info-in-incoming-order-card'>
      <Row>
        <Col
          sm={3}
          lg={4}
          xl={3}
          className='font-size-14 letter-spacing-O25 word-break'
        >
          {customerName}
        </Col>
        <Col sm={5} lg={8} xl={5} className='d-flex align-items-center'>
          <main className='d-flex flex-gap-10'>
            <section>
              <img
                src={phoneIcon}
                alt='Phone Icon'
                width={'24px'}
                height={'24px'}
              />
            </section>
            <section className='font-size-12 letter-spacing-O50'>
              {customerPhoneNumber}
            </section>
          </main>
        </Col>
        <Col
          sm={4}
          lg={12}
          xl={4}
          className='d-flex flex-lg-row-reverse align-items-center flex-xl-row-reverse flex-sm-row-reverse '
        >
          <main className='d-flex'>
            <section className='customer-phone-code-text'>Code:&nbsp;</section>
            <section className='customer-phone-code-number'>
              {customerPhoneCode}
            </section>
          </main>
        </Col>
      </Row>
    </Container>
  );
}

export default SVTIncomingOrderCardCustomerInfoView;
