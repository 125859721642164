/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import SVTHeaderComponent from './SVTHeaderComponent';
import SVTMenuHeader from './SVTMenuHeader';
import checklistGray from '../../images/ChecklistGray.svg';
import checklistGreen from '../../images/ChecklistGreen.svg';
import SVTProductItemsWrapper from './SVTProductItemsWrapper';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { menuManagementAction } from '../../action/SVTMenuManagementAction';
import SVTMenuSearchResults from './SVTMenuSearchResults';
import allProductActive from '../../images/all-products-active.svg';
import allProductInactive from '../../images/all-products-inactive.svg';
import allModifiersActive from '../../images/all-modifiers-active.svg';
import allModifiersInactive from '../../images/all-modifiers-inactive.svg';
import SVTMainComponent from './SVTMainComponent';
import { orderManagementActions } from '../../action/SVTOrderManagementAction';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import ErrorPopupForUpdateItemAPIFailure from './SVTErrorPopupForUpdateItemAPIFailure';

/**
 *
 * @returns {Array<object>}
 */

const categoryKey1 = 'marked-as-unavailable';
const categoryKey2 = 'single-tabletall-products';
const categoryKey3 = 'single-tabletall-modifiers';
const border = 'tab-custom-border';

export const getInitialStateForTab = () => {
  return [
    {
      category_key: categoryKey1,
      category_name: 'Marked as unavailable',
      id: categoryKey1,
      product_names: [],
      active: true,
      itemIndex: 0,
    },
    {
      category_key: categoryKey2,
      category_name: 'All Product',
      id: 'recent',
      product_names: [],
      active: false,
      itemIndex: 1,
    },
    {
      category_key: categoryKey3,
      category_name: 'All Modifiers',
      id: 'recent',
      product_names: [],
      active: false,
      itemIndex: 2,
    },
    {
      category_key: 'search',
      category_name: 'search',
      id: 'search',
      product_names: [],
      active: false,
      itemIndex: 3,
    },
  ];
};
/**
 *
 * @param {Array<object>} tabList
 * @returns {string} currentActiveTab
 */
export const getCurrentActiveTabKey = (tabList = []) => {
  const currentActiveTab = tabList.filter((tab) => tab.active)[0];
  return currentActiveTab.category_key;
};
/**
 *
 * @param {Array<object>} tabList
 * @returns {string} currentActiveTabIndex
 */
export const getCurrentActiveTabIndex = (tabList = []) => {
  const currentActiveTabIndex = tabList.findIndex((tab) => tab.active);
  return currentActiveTabIndex;
};
/**
 *
 * @param {Date} time
 * @returns {Boolean} True if time is more than 10 mins else False
 */
export const isMoreThan5mins = (time) => {
  const currentTime = new Date();
  return differenceInMinutes(currentTime, new Date(time)) > 5;
};
/**
 *
 * @param {} modifierSkuCodes Object of modifier sku codes
 * @returns Object of modifier sku codes which are loading from less then 10 mins
 */
export const filterModifierWhichIsLoadingFromMoreThan5Mins = (
  modifierSkuCodes
) => {
  const updatedModifierSkuCodes = {};
  for (const skuCode in modifierSkuCodes) {
    if (!isMoreThan5mins(modifierSkuCodes[skuCode])) {
      updatedModifierSkuCodes[skuCode] = modifierSkuCodes[skuCode];
    }
  }
  return updatedModifierSkuCodes;
};
function SVTMenuPage(props) {
  const {
    getTotalUnavailableCount,
    getTotalUnavailableItemList,
    productStatus,
    totalUnavailableCount,
    searchQuery,
    totalProductsCount,
    getAllProductsAndModifiersListFromSearchQuery,
    storeSearchQuery,
    setShowTopMsg,
    currentActiveProduct,
    setCurrentActiveProduct,
    getAllModifiersList,
    getAllProductsList,
    allUnavailableProductsList,
    allProductsList,
    currentActiveModifier,
    setCurrentActiveModifier,
    totalModifiersCount,
    setProductStatusToNull,
    allProductsListFromSearchQuery,
    allModifiersListFromSearchQuery,
    showLoaderForModifiers,
    allModifiersList,
    setShowLoaderForModifiersObj,
    updateCurrentToggledModifier,
    saveCurrentActiveCategory,
    setItemListForWhichAPIFailing,
    itemHavingErrorPopupState,
    setItemHavingErrorPopupState,
    setShowLoaderForProducts,
    setShowLoaderForModifiers,
  } = props;
  const [tabList, setTabList] = React.useState(() => getInitialStateForTab());
  const [scrollTopPosition, setScrollPosition] = React.useState(0);
  const productRef = React.useRef();
  const setActiveTab = (index) => {
    if (getCurrentActiveTabKey(tabList) === 'search') {
      storeSearchQuery('');
    }
    const tempItemList = getInitialStateForTab();
    productRef.current?.scrollTo(0, 0);
    if (index !== 0) {
      tempItemList[0]['active'] = false;
      tempItemList[index]['active'] = true;
    }
    setTabList(tempItemList);
  };
  React.useEffect(() => {
    props.setCurrentPath(props.location.pathname);
    storeSearchQuery(null);
    getTotalUnavailableCount();
  }, []);
  /**
   * This effect will run when any left tab is getting changed
   */
  React.useEffect(() => {
    const currentActiveTabKey = getCurrentActiveTabKey(tabList);
    saveCurrentActiveCategory(currentActiveTabKey);
    setItemListForWhichAPIFailing([]);
    switch (currentActiveTabKey) {
      case categoryKey1:
        getTotalUnavailableItemList();
        getTotalUnavailableCount();
        break;
      case categoryKey2:
        getAllProductsList();
        getTotalUnavailableCount();
        break;
      case categoryKey3:
        getAllModifiersList();
        getTotalUnavailableCount();
        break;
      default:
        break;
    }
  }, [tabList]);
  /**
   * This effect will update the product list when any user try to make it available or unavailable
   */
  React.useEffect(() => {
    if (!productStatus && currentActiveModifier?.modifierName === '') {
      return;
    }
    if (getCurrentActiveTabKey(tabList) === categoryKey1) {
      getTotalUnavailableItemList();
      getTotalUnavailableCount();
    } else if (getCurrentActiveTabKey(tabList) === categoryKey2) {
      getAllProductsList();
      getTotalUnavailableCount();
    } else if (getCurrentActiveTabKey(tabList) === categoryKey3) {
      const { shouldShow = false } = currentActiveModifier;
      // for stopping multiple api call for same toggle action on modifier
      if (shouldShow) {
        getAllModifiersList();
        getTotalUnavailableCount();
      }
    } else {
      if (getCurrentActiveTabKey(tabList) === 'search') {
        getAllProductsAndModifiersListFromSearchQuery(searchQuery);
        getTotalUnavailableCount();
      }
    }
    return () => {
      setProductStatusToNull();
      setCurrentActiveModifier();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productStatus]);

  const scrollEventListener = (e) => {
    setScrollPosition(e.target.scrollTop);
  };
  const previousActiveTabIndex = React.useRef(0);
  React.useEffect(() => {
    // == is intensional. Please do not replace it with === .
    if (searchQuery == null) {
      return;
    }
    let currentActiveTabKey;
    if (searchQuery.length === 0) {
      currentActiveTabKey = getCurrentActiveTabKey(tabList);
      if (currentActiveTabKey === 'search') {
        setActiveTab(previousActiveTabIndex.current);
        currentActiveTabKey =
          tabList[previousActiveTabIndex.current]?.category_key;
      }
      switch (currentActiveTabKey) {
        case categoryKey1:
          getTotalUnavailableItemList();
          break;
        case categoryKey2:
          getAllProductsList();
          break;
        case categoryKey3:
          getAllModifiersList();
          break;
        default:
          break;
      }
    } else if (searchQuery.length > 2) {
      currentActiveTabKey = getCurrentActiveTabKey(tabList);
      if (currentActiveTabKey !== 'search') {
        const currentActiveTabIndex = getCurrentActiveTabIndex(tabList);
        previousActiveTabIndex.current = currentActiveTabIndex;
        setActiveTab(3);
      }
    }
  }, [searchQuery]);

  React.useEffect(() => {
    if (currentActiveProduct?.shouldShow) {
      const headerMsg = `${currentActiveProduct?.productName}`;
      const headerMsgPayload = {
        show: true,
        type: 'menu',
        msg: headerMsg,
        orderId: '',
        partnerId: '',
        isProductAvailable: currentActiveProduct?.isProductAvailable,
      };
      setShowLoaderForProducts(currentActiveProduct.productKey, false);
      setShowTopMsg(headerMsgPayload);
      const setCurrentActiveProductPayload = {
        productKey: '',
        productName: '',
        isProductAvailable: false,
        shouldShow: false,
      };
      setCurrentActiveProduct(setCurrentActiveProductPayload);
    }
    if (currentActiveModifier?.shouldShow) {
      const {
        modifierName,
        numberOfProductsHavingThisModifier,
        isModifierAvailable,
      } = currentActiveModifier;
      const headerMsg = String(modifierName);
      const headerMsgPayload = {
        show: true,
        type: 'menu',
        msg: headerMsg,
        isProductAvailable: isModifierAvailable,
        isModifier: true,
        numberOfProductsHavingThisModifier,
      };
      setShowLoaderForModifiers(currentActiveModifier.skuCode, false);
      setShowTopMsg(headerMsgPayload);
      updateCurrentToggledModifier();
    }
    // Checking if any modifier is loading from more than 10 mins
    const updatedShowLoaderForModifiers =
      filterModifierWhichIsLoadingFromMoreThan5Mins(showLoaderForModifiers);
    if (
      JSON.stringify(showLoaderForModifiers) !==
      JSON.stringify(updatedShowLoaderForModifiers)
    ) {
      console.log('SET_SHOW_LOADER_FOR_MODIFIER');
      setShowLoaderForModifiersObj(updatedShowLoaderForModifiers);
    }
  }, [
    allProductsList,
    allModifiersList,
    allUnavailableProductsList,
    allProductsListFromSearchQuery,
    allModifiersListFromSearchQuery,
  ]);
  const itemHavingErrorPopupHideHandler = () => {
    const itemHavingErrorPopupStatePayload = {
      shouldShow: false,
      itemName: '',
    };
    setItemHavingErrorPopupState(itemHavingErrorPopupStatePayload);
  };
  if (props?.showErrorPage) {
    return <SVTMainComponent />;
  }
  return (
    <>
      <SVTHeaderComponent />
      <div className='menu-main-container' data-test='menuPageContainer'>
        <SVTMenuHeader currentActiveTab={getCurrentActiveTabKey(tabList)} />
        <main className='menu-body-container'>
          <section className='menu-body-left'>
            <div
              className={
                tabList[0]?.active === true
                  ? 'left-card-item left-card-item-active mark-as-unavailable-tab cursor-pointer'
                  : 'left-card-item mark-as-unavailable-tab cursor-pointer left-card-bottom-border'
              }
              onClick={() => setActiveTab(0)}
              data-test='markAsUnavailableTab'
            >
              <div className={tabList[0]?.active === true ? border : ''}></div>
              <div>
                <div className='w-100 d-flex flex-row-reverse menu-count-height'>
                  {totalUnavailableCount > 0 && (
                    <div
                      className={
                        tabList[0]?.active === true
                          ? 'mark-as-unavailable-tab-count ms-2 unavailable-count-active'
                          : 'mark-as-unavailable-tab-count ms-2 unavailable-count-inactive '
                      }
                    >
                      {totalUnavailableCount}
                    </div>
                  )}
                </div>
                <div className='d-flex flex-column justify-content-center align-items-center menu-left-tab-small '>
                  <div>
                    {tabList[0]?.active === true ? (
                      <img
                        src={checklistGreen}
                        alt='checklistGray'
                        width='36px'
                        height='36px'
                        className='mark-as-unavailable-logo'
                      />
                    ) : (
                      <img
                        src={checklistGray}
                        alt='checklistGray'
                        width='36px'
                        height='36px'
                        className='mark-as-unavailable-logo'
                      />
                    )}
                  </div>
                  <div className='left-card-text d-flex flex-row make-flex-col-on-overflow-for-mark-as-unavailable-tab'>
                    <div className='word-break'>Marked as&nbsp;</div>
                    <div className='word-break'>Unavailable</div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={
                tabList[1]?.active === true
                  ? 'left-card-item left-card-items-body left-card-item-active cursor-pointer'
                  : 'left-card-item left-card-items-body cursor-pointer left-card-bottom-border'
              }
              onClick={() => setActiveTab(1)}
              data-test='allProductTab'
            >
              <div className={tabList[1]?.active === true ? border : ''}></div>
              <div className='d-flex flex-column justify-content-center align-items-center  flex-gap-15 menu-left-tab-small'>
                {tabList[1]?.active === true ? (
                  <img
                    src={allProductActive}
                    alt='checklistGray'
                    width='36px'
                    height='36px'
                    className='all-product-logo'
                  />
                ) : (
                  <img
                    src={allProductInactive}
                    alt='checklistGray'
                    width='36px'
                    height='36px'
                    className='all-product-logo'
                  />
                )}
                <div className='left-card-text d-flex flex-row justify-content-center align-items-center make-flex-col-on-overflow-for-all-product-tab'>
                  <div>All&nbsp;</div>
                  <div>Products&nbsp;</div>
                  <div>({totalProductsCount})</div>
                </div>
              </div>
            </div>
            <div
              className={
                tabList[2]?.active === true
                  ? 'left-card-item left-card-items-body left-card-item-active cursor-pointer'
                  : 'left-card-item left-card-items-body cursor-pointer left-card-bottom-border'
              }
              onClick={() => setActiveTab(2)}
              data-test='allModifiersTab'
            >
              <div className={tabList[2]?.active === true ? border : ''}></div>
              <div className='d-flex flex-column justify-content-center align-items-center  flex-gap-15 menu-left-tab-small'>
                {tabList[2]?.active === true ? (
                  <img
                    src={allModifiersActive}
                    alt='checklistGray'
                    width='36px'
                    height='36px'
                    className='all-product-logo'
                  />
                ) : (
                  <img
                    src={allModifiersInactive}
                    alt='checklistGray'
                    width='36px'
                    height='36px'
                    className='all-product-logo'
                  />
                )}
                <div className='left-card-text d-flex flex-row justify-content-center align-items-center make-flex-col-on-overflow-for-all-product-tab'>
                  <div>All&nbsp;</div>
                  <div>Modifiers&nbsp;</div>
                  <div>({totalModifiersCount})</div>
                </div>
              </div>
            </div>
          </section>
          {searchQuery?.length > 2 ? (
            <section className='menu-body-right'>
              <SVTMenuSearchResults
                tabList={tabList}
                currentActiveTab={getCurrentActiveTabKey(tabList)}
                searchQuery={searchQuery}
              />
            </section>
          ) : (
            <section
              ref={productRef}
              className='menu-body-right'
              onScroll={scrollEventListener}
              data-test='scrollableProductView'
            >
              <SVTProductItemsWrapper
                scrollTopPosition={scrollTopPosition}
                currentActiveTab={getCurrentActiveTabKey(tabList)}
              />
            </section>
          )}
        </main>
      </div>
      <ErrorPopupForUpdateItemAPIFailure
        show={itemHavingErrorPopupState?.shouldShow}
        onHide={itemHavingErrorPopupHideHandler}
        productName={itemHavingErrorPopupState?.itemName}
      />
    </>
  );
}

export function mapState(state) {
  const { menuManagementRelated, errorPageRelated } = state;
  const { showErrorPage } = errorPageRelated;
  const {
    searchQuery,
    totalUnavailableCount,
    productList,
    productStatus,
    totalProductsCount,
    currentActiveProduct,
    allModifiersList,
    allUnavailableProductsList,
    allProductsList,
    currentActiveModifier,
    totalModifiersCount,
    allProductsListFromSearchQuery,
    allModifiersListFromSearchQuery,
    showLoaderForModifiers,
    itemHavingErrorPopupState,
  } = menuManagementRelated;
  return {
    searchQuery,
    totalUnavailableCount,
    productList,
    productStatus,
    totalProductsCount,
    showErrorPage,
    currentActiveProduct,
    allModifiersList,
    allUnavailableProductsList,
    allProductsList,
    currentActiveModifier,
    totalModifiersCount,
    allProductsListFromSearchQuery,
    allModifiersListFromSearchQuery,
    showLoaderForModifiers,
    itemHavingErrorPopupState,
  };
}
const actionCreators = {
  getTotalUnavailableItemList: menuManagementAction.getTotalUnavailableItemList,
  getTotalUnavailableCount: menuManagementAction.getTotalUnavailableCount,
  getAllProductsAndModifiersListFromSearchQuery:
    menuManagementAction.getAllProductsAndModifiersListFromSearchQuery,
  storeSearchQuery: menuManagementAction.storeSearchQuery,
  setShowTopMsg: orderManagementActions.setShowTopMsg,
  setCurrentActiveProduct: menuManagementAction.setCurrentActiveProduct,
  getAllModifiersList: menuManagementAction.getAllModifiersList,
  getAllProductsList: menuManagementAction.getAllProductsList,
  setCurrentActiveModifier: menuManagementAction.setCurrentActiveModifier,
  setProductStatusToNull: menuManagementAction.setProductStatusToNull,
  setShowLoaderForModifiersObj:
    menuManagementAction.setShowLoaderForModifiersObj,
  setCurrentPath: orderManagementActions.setCurrentPath,
  updateCurrentToggledModifier:
    menuManagementAction.updateCurrentToggledModifier,
  saveCurrentActiveCategory: menuManagementAction.saveCurrentActiveCategory,
  setItemListForWhichAPIFailing:
    menuManagementAction.setItemListForWhichAPIFailing,
  setItemHavingErrorPopupState:
    menuManagementAction.setItemHavingErrorPopupState,
  setShowLoaderForProducts: menuManagementAction.setShowLoaderForProducts,
  setShowLoaderForModifiers: menuManagementAction.setShowLoaderForModifiers,
};

export default compose(
  withRouter,
  connect(mapState, actionCreators)
)(SVTMenuPage);
