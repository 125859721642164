import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';

const calculateDuration = (date) => moment(date).fromNow();

function StoreOfflineTime(date) {
  const correctDate = date?.replace(' ', 'T');

  const datePlus10Minutes = moment(correctDate, 'YYYY-MM-DDTHH:mm:ss.SSSSZ');
  const timePaused = datePlus10Minutes.format('HH:mm A');
  const interval = 15000;
  const eventTime = datePlus10Minutes;
  const [duration, setDuration] = useState(
    calculateDuration(datePlus10Minutes)
  );
  const timerRef = useRef(0);
  const timerCallback = useCallback(() => {
    setDuration(calculateDuration(eventTime));
  }, [eventTime]);

  useEffect(() => {
    timerRef.current = setInterval(timerCallback, interval);
    return () => {
      clearInterval(timerRef.current);
    };
  }, [eventTime, interval, timerCallback]);

  useEffect(() => {
    setDuration(calculateDuration(datePlus10Minutes));
  }, [date]);

  return (
    <div className='store-offline-time'>
      Paused at: {timePaused} ({duration})
    </div>
  );
}

export default StoreOfflineTime;
