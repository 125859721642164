import { compose } from 'redux';
import { connect } from 'react-redux';
import { menuManagementAction } from '../../action/SVTMenuManagementAction';
import Loading from '../../images/Loading.png';
import { orderManagementActions } from '../../action/SVTOrderManagementAction';
function SVTModifiersItems(props) {
	const {
		currentModifiersList,
		updateModifierStatus,
		setShowLoaderForModifiers,
		showLoaderForModifiers,
		setShowTopMsg,
		itemsListHavingError,
		setItemHavingErrorPopupState,
	} = props;
	const modifierAvailabilityHandler = (
		skuCode,
		availabilityFlag,
		modifierName,
	) => {
		if (itemsListHavingError?.includes(skuCode)) {
			itemHavingErrorPopupShowHandler(modifierName);
			return;
		}
		setShowLoaderForModifiers(skuCode, true);
		const payload = {
			sku_code: skuCode,
			status: String(availabilityFlag),
			modifierName,
		};
		const headerMsgPayload = {
			show: true,
			type: 'modifierBgcUpdating',
			msg: '',
		};
		setShowTopMsg(headerMsgPayload);
		updateModifierStatus(payload);
	};
	const itemHavingErrorPopupShowHandler = modifierName => {
		const itemHavingErrorPopupStatePayload = {
			shouldShow: true,
			itemName: modifierName,
		};
		setItemHavingErrorPopupState(itemHavingErrorPopupStatePayload);
	};
	const getSliderClassName = skuCode => {
		if (itemsListHavingError?.includes(skuCode)){
			return 'slider round disable-grey';
		}
		if (Object.keys(showLoaderForModifiers).includes(skuCode)){
			return 'slider round disable-grey context-menu-cursor';
		}
		return 'slider round';
	};
	return (
		<>
			{currentModifiersList &&
				currentModifiersList.map(modifier => {
					const {
						alter_name: modifierName = '',
						numberOfProductsHavingThisModifier = 1,
						availability = true,
						sku_code: skuCode = '',
					} = modifier;
					return (
						<main
							className='modifier-main-container'
							data-test='modifierMainContainer'
							key={skuCode}
						>
							<section className='modifier-title d-flex align-items-center flex-gap-10'>
								<div className='letter-spacing-O50 text-capitalize text-break text-center'>
									{modifierName}
								</div>
								<div className='font-size-14 gray_text letter-spacing-O25 text-break text-center'>
									{`in ${numberOfProductsHavingThisModifier} ${
										numberOfProductsHavingThisModifier > 1
											? 'products.'
											: 'product(s).'
									}`}
								</div>
							</section>
							<section className='right-part flex-gap-20'>
								<div className='loader-available'>
									{Object.keys(
										showLoaderForModifiers ?? {},
									).includes(skuCode) && (
										<img
											class='rotating-loader'
											src={Loading}
											alt='loader'
										/>
									)}
									<div
										className={
											itemsListHavingError?.includes(
												skuCode,
											)
												? 'w-100 pt-2'
												: 'w-100'
										}
									>
										<div className='small_text gray_text text-uppercase letter-spacing-15 text-end font-size-12 pt-1'>
											{availability
												? 'Available'
												: 'Unavailable'}
										</div>
										{itemsListHavingError?.includes(
											skuCode,
										) && (
											<div className='font-size-12'>
												FAILED TO UPDATE
											</div>
										)}
									</div>
								</div>
								<label className='switch'>
									<input
										type='checkbox'
										id='selectID'
										checked={availability}
										disabled={Object.keys(
											showLoaderForModifiers ?? {},
										).includes(skuCode)}
										onChange={e =>
											modifierAvailabilityHandler(
												skuCode,
												e.target.checked,
												modifierName,
											)
										}
										data-test='availabilityHandlerForModifiers'
									/>
									<span
										className={getSliderClassName(skuCode)}
									></span>
								</label>
							</section>
						</main>
					);
				})}
		</>
	);
}
export function mapState(state) {
	const { menuManagementRelated } = state;
	const { showLoaderForModifiers, itemsListHavingError } =
		menuManagementRelated;
	return {
		showLoaderForModifiers,
		itemsListHavingError,
	};
}
const actionCreators = {
	getTotalUnavailableCount: menuManagementAction.getTotalUnavailableCount,
	updateModifierStatus: menuManagementAction.updateModifierStatus,
	setShowLoaderForModifiers: menuManagementAction.setShowLoaderForModifiers,
	setItemHavingErrorPopupState:
		menuManagementAction.setItemHavingErrorPopupState,
	setShowTopMsg: orderManagementActions.setShowTopMsg,
};

export default compose(connect(mapState, actionCreators))(SVTModifiersItems);
