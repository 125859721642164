import React, { useState } from 'react';
import uber_eats from '../../images/UberEats.svg';
import menulog from '../../images/menuLog.svg';
import doordash from '../../images/Doordash.svg';
import up_arrow from '../../images/up_arrow.svg';
import phone from '../../images/phone.svg';

function SVTAccordionTabComponent({
  OrderDetails,
  order,
  index,
  setShouldShowEditOrder,
  shouldShowEditOrder,
  id,
  foodQuantity,
  setFoodQuantity,
  resetCurrentItem,
  reset,
  resetById,
}) {
  const [rotation, setRotation] = useState(0);
  const [show, setShow] = useState(true);

  const rotateArrow = () => {
    let newRotation = rotation + 180;
    if (newRotation >= 360) {
      newRotation = newRotation - 360;
    }
    setRotation(newRotation);
    setShow(!show);
  };

  const imageObj = {
    'Uber Eats': uber_eats,
    Menulog: menulog,
    Doordash: doordash,
  };
  return (
    <>
      {order.partner_name === 'Uber Eats' ? (
        <div
          className='Accordion_Style'
          id={order?.partner_order_id}
          data-test='UberEatsAccordion'
        >
          <OrderDetails
            image={imageObj[order.partner_name]}
            up_arrow={up_arrow}
            rotation={index === 0 ? rotation : rotation + 180}
            rotateArrow={rotateArrow}
            show={index === 0 ? show : !show}
            phone={phone}
            order={order}
            setShouldShowEditOrder={setShouldShowEditOrder}
            shouldShowEditOrder={shouldShowEditOrder}
            id={id}
            foodQuantity={foodQuantity}
            setFoodQuantity={setFoodQuantity}
            resetCurrentItem={resetCurrentItem}
            reset={reset}
            resetById={resetById}
            currentOrder={order}
          />
        </div>
      ) : (
        <>
          {order.partner_name === 'Menulog' ||
          order.partner_name === 'Doordash' ? (
            <div
              className='Accordion_Style'
              id={order?.partner_order_id}
              data-test='DoordashMenulogAccordion'
            >
              <OrderDetails
                image={imageObj[order.partner_name]}
                up_arrow={up_arrow}
                rotation={index === 0 ? rotation : rotation + 180}
                rotateArrow={rotateArrow}
                show={index === 0 ? show : !show}
                phone={phone}
                order={order}
                currentOrder={order}
              />
            </div>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
}

export default SVTAccordionTabComponent;
