import { constants } from '../constant/reducerConstant';

export function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = new Error((data && data.message) || response.statusText);
      error.code = response?.status;
      throw error;
      //We are thowing error so below code will never runs
      // return Promise.reject(error);
    }
    return data;
  });
}
export const startLoader = () => {
  window.scrollTo(0, 0);
  return { type: constants.SHOW_LOADER };
};

export const stopLoader = () => {
  return { type: constants.HIDE_LOADER };
};

export function showErrorPage(dataError, methoddetails) {
  let errorMessages = {};
  if (dataError !== null) {
    if ('code' in dataError) {
      if (dataError?.code === 401) {
        sessionStorage.setItem('accessToken', '');
      }
      errorMessages = {
        methodDetails: methoddetails,
        message: dataError?.message,
        code: dataError?.code,
      };
    } else {
      errorMessages = {
        methodDetails: methoddetails,
        message: dataError?.message,
      };
    }
  } else {
    errorMessages = {
      methodDetails: methoddetails,
      message: '',
    };
  }
  return { type: constants.SHOW_ERROR_PAGE, errorMessages };
}
export const hideErrorPage = () => {
  return { type: constants.HIDE_ERROR_PAGE };
};

export function delayForGivenTime(time) {
  return new Promise((res, rej) => {
    setTimeout(() => {
      res(24);
    }, time);
  });
}
