const text1 = (
	<span>
		<strong>Uber Eats:</strong>
		<br />
		If you forgot an item and the food has not been delivered, you can
		contact the Home Delivery Aggregator directly for support. If the order
		has been delivered or the driver cannot return, contact Uber Support
		(1800 839 157) Uber will reach out to the customer to see if they still
		want the missing item. Uber will adjust the total sale of the order &
		refund the customer for the missing item – if they do not want the
		missing item. <br />
		<br />
		<strong>Menu Log:</strong>
		<br />
		For any live order issues, including a missing item for a customer, you
		will need to contact Menulog directly on 1300 974 172 who will contact
		the customer.
	</span>
);

const text2 = (
	<span>
		<strong>Uber Eats:</strong>
		<br />
		Reach out directly to coordinate a return of the order to the
		restaurant.
		<br />
		<br />
		If you are unable to contact the delivery driver person directly, you
		may call Uber Support at 1800 839 157. Uber will advise the customer to
		let them know to expect a delay due to the mix up.
		<br />
		<br />
		<strong>Menu Log:</strong>
		<br />
		For any live order issues, including a wrong delivery person, you will
		need to contact Menulog directly on 1300 974 172 who will contact the
		customer.
	</span>
);

const text3 = (
	<span>
		<ol>
			<li>
				When an order is placed by a customer, your device will ring to
				notify you of an incoming order.
			</li>
			<li>
				To acknowledge the order for Uber eats Orders, tap “Accept”. For
				Menulog Orders, the order will be automatically accepted. Once
				accepted, the new order will flow in the Orders screen and a
				receipt will automatically print.
			</li>
			<li>
				Use the receipt to pick and pack your orders and staple the
				receipt to the bag.
			</li>
		</ol>
	</span>
);

const text4 = (
	<span>
		Should you need temporarily pause Order In for some unexpected reason
		you can do so in the web app. <br />
		<ol>
			<li>
				To do this click on the settings icon on the top right of the
				screen
			</li>
			<li>
				Scroll to the bottom of the page where ‘Temporarily Pause
				Orders’ is located.
			</li>
			<li>
				Select the reason for pausing. You must select an option to
				proceed.
			</li>
			<li> Tap ‘Pause Order’ </li>
			<li>
				You will be prompted to confirm whether you would like to go
				ahead, in which you will need to tap ‘Pause Orders’.
			</li>
			<li>
				Your store’s status will now appear as ‘Offline’ for both Uber
				Eats and Menulog.
			</li>
		</ol>
	</span>
);

const text5 = (
	<span>
		Locating the Home Delivery Partner support number <br />
		<ol>
			<li>
				You can access the Home Delivery Partner’s support team through
				the ‘Order Help’ button which is available within the order
				detail view or the incoming orders view.
			</li>
			<li> Once selected, click on ‘Contact Support’ </li>
			<li>
				The relevant partner’s contact number for that order will pop up
				in a separate window. Tap ‘close’ to exit the view.
			</li>
		</ol>
		Contacts a customer to cancel an order (Uber Eats) <br />
		<ol>
			<li>
				Customers details are provided in the incoming orders screen in
				the section below the order number or below the driver status in
				the order details page.
			</li>
		</ol>
		For more information, please refer to your Order In Home Delivery manual
	</span>
);

const text6 = (
	<span>
		Editing an Order an out of stock / unavailable item ordered by a
		customer. <br />
		<ol>
			<li>
				To edit an incoming order, select “Order Help” at the bottom of
				the incoming orders screen
			</li>
			<li> Select “Edit Order” </li>
			<li>
				When the edit order button is clicked, you will be able to see
				additional editing options such as a check box a “+” or “-“
				button.
			</li>
		</ol>
	</span>
);

const text7 = (
	<span>
		In your Settings Tab on Order In: <br />
		<ol>
			<li>
				Once you have paused all orders, your status will appear as
				‘offline’ as shown on the top right section of your app.
			</li>
			<li>
				To take your store offline and resume orders, select the ‘Resume
				Orders’ button in the setting page.
			</li>
			<li>
				Your store’s status will now change to ‘Online’ and customers
				will be able to order from your store.
			</li>
		</ol>
	</span>
);

const text8 = (
	<span>
		Please perform the below: <br />
		<ol>
			<li> Check power to unit </li>
			<li> Check tablet is docked correctly. </li>
			<li> Check power is plugged to the wall socket correctly.</li>
		</ol>
		Please contact the Retail Helpdesk
		<br />
		<br />
		PHONE: 1300 881 867
	</span>
);

const text9 = (
	<span>
		<strong>
			<u>Record what’s happening:</u>
		</strong>
		<br />
		<ul>
			<li>
				Take note of the date, time and order number (provide this to
				the store manager if not on site when issue occurs)
			</li>
			<li> Share a picture of both tablets to the store manager </li>
		</ul>
		<strong>
			<u>Check the connection:</u>
		</strong>
		<br />
		<ul>
			<li>
				Try and check that you are connected to the internet on the
				Retail tablet
			</li>
			<li> Refresh the browser in chrome. </li>
			<li>
				Close the browser and click the “Order in” icon to log back in
				(requires store credentials)
			</li>
		</ul>
		<strong>
			<u>Swap to other tablet:</u>
		</strong>
		<br />
		If Order does not prompt in BP tablet, accept and proceed using the Home
		Delivery tablet to complete the order (leaving a note with the details
		of the issues faced for the store manager)
	</span>
);

const text10 = (
	<span>
		<ul>
			<li> Log back in with store credentials (if known) </li>
			<li>
				Follow up with the store manager for the store credentials
				(ebpauXXXX@bp.com)
			</li>
			<li> Contact Store Comms & cc. bp Home delivery email. </li>
			<li>
				If out of business hours please use the Uber eats tablet, whilst
				notifying the Store manager of the issues faced with the BP
				tablet.
			</li>
		</ul>
	</span>
);

const text11 = (
	<span>
		<strong>
			<u>Record what’s happening:</u>
		</strong>
		<br />
		<ul>
			<li>
				Note down the error message details, date and time that it
				occurred.
			</li>
			<li>
				Was the tablet in use when the message appeared? If so, what
				actions were being performed?
			</li>
		</ul>
		<strong>
			<u>Check the Connection: </u>
		</strong>
		<br />
		<ul>
			<li>
				Check error message instructions, potential prompt to log in
				again
			</li>
			<li> Check the restaurant is online in Order In </li>
			<li>
				If Order In is no longer able to log in, please use the Home
				Delivery until the issue can be resolved.
			</li>
		</ul>
	</span>
);

const text12 = (
	<span>
		If you experience any issues relating to: <br />
		<ul>
			<li> Retail HP Tablet </li>
			<li> Wi Fi </li>
			<li> Internet Connectivity </li>
			<li> Printer </li>
			<li> Power </li>
		</ul>
		Retail IT Help Desk:
		<ul>
			<li>Phone: 1300 881 867 – High priority incidents only</li>
			<li>
				Portal:{' '}
				<a href='https://dnallconnectlive2.dieboldnixdorf.com/dn-web/' className='green-underline-link'>
					{' '}
					https://dnallconnectlive2.dieboldnixdorf.com/dn-web/
				</a>
			</li>
			<li>
				Email:{' '}
				<a
					href='mailto:bp.incidents-au-eso@dieboldnixdorf.com'
					className='green-underline-link'
				>
					bp.incidents-au-eso@dieboldnixdorf.com
				</a>{' '}
			</li>
		</ul>
	</span>
);

const text13 = (
	<span>
		If you experience any issues relating to:
		<br />
		<ul>
			<li> Order Fulfillment </li>
			<li> Order Cancellations </li>
			<li> Permanent item removals </li>
		</ul>
		Please contact the Home Delivery Partner Support Channels: <br />
		<strong>Uber Eats:</strong>
		<br />
		1800 839 157 <br />
		<a href='mailto:restaurants@uber.com' className='green-underline-link'>
			restaurants@uber.com
		</a>
		<br />
		<strong>MenuLog:</strong>
		<br />
		1300 974 172 <br />
		<a
			href='mailto:enquiries@menulog.com.au'
			className='green-underline-link'
		>
			enquiries@menulog.com.au
		</a>
		<br />
		<strong>Deliveroo: </strong>
		<br />
		1800 766 766 <br />
		<a
			href='mailto:restaurants@deliveroo.com.au'
			className='green-underline-link'
		>
			restaurants@deliveroo.com.au
		</a>
	</span>
);

const text14 = (
	<span>
		If you experience any issues relating to: <br />
		<ul>
			<li> Menu or menu item enquiry </li>
			<li> Updating store hours </li>
			<li> Permanent store closures </li>
		</ul>
		Please contact the bp Home Delivery Team on
		<a href='mailto:HomeDelive01@bp.com' className='green-underline-link'>
			&nbsp;HomeDelive01@bp.com
		</a>
	</span>
);

const category1 = 'Order Support';
const category2 = 'Managing Orders';
const category3 = 'Technical Issues';
const category4 = 'Support Summary';

export const data = [
	{
		id: 1,
		show: false,
		header: 'What if we forgot an item for an order?',
		data: text1,
		category: category1,
	},
	{
		id: 2,
		show: false,
		header: 'What if we gave the delivery person the wrong order?',
		data: text2,
		category: category1,
	},
	{
		id: 3,
		show: false,
		header: 'What happens when an order comes in?',
		data: text3,
		category: category2,
	},
	{
		id: 4,
		show: false,
		header: 'How do I pause incoming orders?',
		data: text4,
		category: category2,
	},
	{
		id: 5,
		show: false,
		header: 'How do I cancel an order?',
		data: text5,
		category: category2,
	},
	{
		id: 6,
		show: false,
		header: 'How do I edit an order?',
		data: text6,
		category: category2,
	},
	{
		id: 7,
		show: false,
		header: 'Resuming Orders & going Online',
		data: text7,
		category: category3,
	},
	{
		id: 8,
		show: false,
		header: 'What do I do when my tablet loses power?',
		data: text8,
		category: category3,
	},
	{
		id: 9,
		show: false,
		header: "I'm unable to process orders through the Order In App, but I can hear",
		data: text9,
		category: category3,
	},
	{
		id: 10,
		show: false,
		header: "I've restarted the tablet and now Order In is asking for store credentials",
		data: text10,
		category: category3,
	},
	{
		id: 11,
		show: false,
		header: 'Order In is displaying an error message',
		data: text11,
		category: category3,
	},
	{
		id: 12,
		show: false,
		header: 'Who do I contact for IT issues?',
		data: text12,
		category: category4,
	},
	{
		id: 13,
		show: false,
		header: 'Who do I contact if I am having Live Order Issues?',
		data: text13,
		category: category4,
	},
	{
		id: 14,
		show: false,
		header: 'Who do I contact for menu support?',
		data: text14,
		category: category4,
	},
];
