import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import contact from '../../images/contact.png';
import UberEatsSVG from '../../images/UberEatsSVG.svg';
import MenuLogSVG from '../../images/MenuLogSVG.svg';
import DoordashSVG from '../../images/Doordash.svg';
import STVOrderHelpDropdown from '../aggregators/SVTOrderHelpDropdown';
import {formatAMPM} from '../aggregators/SVTJustCancelledDetailsComponent'
export class SVTDriverStatusComponent extends Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {}

	showOrderList() {
		if (this.props?.path != null) {
			this.props.history.push('/order-history');
		} else {
			this.props.history.push('/order-list');
		}
	}

	formatDateToTimeMinutes(newDate) {
		const dateDate = new Date(newDate);
		const hours = dateDate?.getHours().toString().padStart(2, '0');
		const minutes = dateDate?.getMinutes().toString().padStart(2, '0');
		const createdTime = hours + ':' + minutes;
		return this.amOrPm(createdTime);
	}
	amOrPm(time = '') {
		const val = time !== undefined ? time.split(':')[0] : time;
		if (val >= 12) {
			return time + `PM`;
		} else if (val < 12) {
			return time + `AM`;
		} else {
			return time;
		}
	}
	render() {
		const imageObj = {
			"Uber Eats": UberEatsSVG,
			"Menulog": MenuLogSVG,
			"Doordash": DoordashSVG
		}
		return (
			<>
				<div className='d-flex order-title-margin'>
				<div className='order-header svt-bold-text pt-2 col-md-10  col-lg-10 col-xl-10 col-sm-10  col-xxl-10'>
						{this.props?.path != null ? (
							<span
								className='order-status-green-color cursor-pointer'
								onClick={this.showOrderList.bind(this)}
								data-test='orderHistory'
							>
								{'Order History :'}
							</span>
						) : (
							<span
								className='order-status-green-color cursor-pointer'
								onClick={this.showOrderList.bind(this)}
							>
								{'Orders :'}
							</span>
						)}
						&nbsp;&nbsp;&nbsp;
						<span className='order-id-font-style'>
							#
							{this.props?.driverStatus?.partner_id
								?.toUpperCase()}
						</span>
					</div>
					{this.props?.type === 'orderDetailHeader' ? (
						<div className='col-md col-sm-2 col order-help-alignment d-flex justify-content-end' data-test="OrderHelpAlignment">
							<STVOrderHelpDropdown
								btnClass={'success-border-btn me-2'}
								order={this.props?.driverStatus}
								path={'dummy'}
								partnerName={this.props?.driverStatus?.partner_name}
								orderType={this.props?.orderType}
								afterAcceptance={true}
							/>
						</div>
					) : null}
				</div>
				<div className='d-flex order-title-margin-driver'>
					<div>
						<img
							className='img-responsive order-image'
							alt=''
							src={imageObj[this.props?.driverStatus?.partner_name]}
						/>
					</div>
					<div className='order-title'>
						<span>
							#{this.props?.driverStatus?.partner_id
								?.toUpperCase()}
							&nbsp;-&nbsp;
							{this.props?.driverStatus?.customer_name}
						</span>
					</div>

					{/* Driver info section */}
					{/* {this.props?.driverStatus?.partner_status === "Driver On the way" ||
          this.props?.driverStatus?.partner_status === "Driver Arrived" ? (
            <div className="d-flex driver-details">
              <div className="driver-details-magine">
                <div className="float-end font-darkgray-color">
                  Driver Details
                </div>
                <div>{this.props?.driverStatus?.partner_status}</div>
              </div>
              <img
                className="img-responsive order-image"
                alt=""
                src={profilePicture}
              />
            </div>
          ) : null} */}
				</div>

				{/* Green Status bar */}
				{this.props?.driverStatus?.partner_status !== 'Cancelled' ? (
					<div className='order-header-bar order-header-text d-flex white-text' data-test="CancelledOrderHeader">
						{this.props?.driverStatus?.partner_status === 'Driver On the way' &&
						this.props?.driverStatus?.partner_name === 'Uber Eats' ? (
							<div>ETA: {this.props?.driverStatus?.eta_pickup}&nbsp;mins</div>
						) : null}
						<div className='order-contact text-capitalize'>
							{this.props?.driverStatus?.partner_status === 'Created'
								? 'Accepted'
								: this.props?.driverStatus?.partner_status}
						</div>
					</div>
				) : null}

				{/* Status bar for Cancelled */}
				{this.props?.driverStatus?.partner_status === 'Cancelled' ? (
					// <div className="cancel-order-header">
					//   <div className="order-header-text">
					//     Cancelled by {this.props?.driverStatus.cancelled_by} at &nbsp;
					//     {this.props?.driverStatus?.time
					//       ? this.props?.driverStatus?.time
					//       : ""}
					//   </div>

					this.props?.driverStatus?.cancelled_reason ? (
						<>
							<div className='cancel-order-header' data-test="cancelledOrderedReason">
								<div className='order-header-text'>
									Cancelled by {this.props?.driverStatus.cancelled_by} at &nbsp;
									{formatAMPM(new Date(this.props?.driverStatus?.cancelled_at ?? '2022-02-18T00:56:01.446804'))}
								</div>
								<div className='mt-1'>
									<span className='driver-status-reason'>Reason</span>
									:&nbsp;&nbsp;
									{this.props?.driverStatus?.cancelled_reason}
								</div>
							</div>
						</>
					) : (
						<div
							className='cancel-order-header'
							style={{ height: '57px' }}
						>
							<div className='order-header-text'>
								Cancelled by {this.props?.driverStatus.cancelled_by} at &nbsp;
								{formatAMPM(new Date(this.props?.driverStatus?.cancelled_at ?? '2022-02-18T00:56:01.446804'))}
							</div>
						</div>
					)
				) : // </div>
				null}

				{/* Instruction section */}
				<div className='order-instructions d-flex'>
					<div className='order-instruction-text svt-bold-text'>
						{'Special Instructions:'}
					</div>
					<div className='pl-4'>
						{this.props?.driverStatus?.delivery_instructions}
					</div>
				</div>
			</>
		);
	}
}
export function mapState(state) {
	const { orderManagementRelated } = state;
	return orderManagementRelated;
}
const actionCreators = {};

export default compose(
	withRouter,
	connect(mapState, actionCreators),
)(SVTDriverStatusComponent);
